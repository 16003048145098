.bedrag-ineens-table-row-summary {
    padding: 1rem 0;
    align-items: center;
    min-height: 5rem;

    &__row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__amount {
        @include OpenSansBold;
        @include ms-respond(font-size,2);
        margin-left: auto;
        color: $color-blue-dark;
    }

    &__label {
        color: $color-blue-dark;
        font-weight: 600;
    }

    &__details {
        visibility: visible;
        color: $color-blue-dark;
    }

    &__button {
        min-width: max-content;
        color: #2b358a;
        text-decoration: underline;
        appearance: none;
        background: transparent;
        border: 0;
        padding: 0;
    }

    &__sentence {
        @include ms-respond(font-size,-1);
        margin: 0;
        text-align: right;
        width: 100%;
    }
}
