/* stylelint-disable block-no-empty */

.form {
    fieldset {
        margin: 0 0 1em;
        border: 0;
        padding: 0;
    }
}

.form__label {
    @include ms-respond(font-size,1);
    display: block;
    margin-bottom: 1em;
}

.form__field {
    display: inline-block;
    padding: .5em .75em;

    background: $color-white;
    position: relative;

    input:invalid {
        border-color: $color-primary;
    }

    &.form__radio,
    &.form__checkbox {
        padding-left: 0;

        label {
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            pointer-events: none;
            z-index: 1;
        }

        label::before {
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            border: 2px solid $color-blue-dark;
            display: block;
            border-radius: 1.2rem;
            margin-right: .5rem;
            position: relative;
            top: 0;
            flex-grow: 0;
            flex-shrink: 0;

            @media (min-width: $mq-m) {
                top: .2rem;
            }
        }

        label::after {
            content: '';
            width: .7rem;
            height: .7rem;
            display: block;
            border-radius: .7rem;
            position: absolute;
            top: .25rem;
            left: .25rem;
            background-color: $color-blue-dark;
            opacity: 0;
            flex-grow: 0;
            flex-shrink: 0;

            @media (min-width: $mq-m) {
                top: .45rem;
            }
        }
    }

    &.form__number {
        display: flex;
        flex-wrap: nowrap;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        position: relative;
        padding: 0;
    }

    .form__prefix {
        color: $color-blue-dark;
        position: absolute;
        top: .65rem;
        left: .7rem;
        z-index: 2;

        @media (min-width: $mq-m) {
            top: .55rem;
        }
    }

    [type=number] {
        padding: .5rem .8rem .5rem 2rem;
        line-height: 2;
        border: 1px solid $color-coal-60;
        border-radius: $radius-5;
        position: relative;
        z-index: 1;
        min-height: 3rem;
        min-width: 7rem;

        &:hover {
            background-color: $color-grey-light-section;
            border: 1px solid $color-black;
        }

        &:focus {
            border: 1px solid $color-blue-dark;
            background-color: $color-white;

            &:not(.keyboardFocused) {
                outline-width: 0;
            }
        }

        &:disabled {
            background-color: $color-grey-light-section;
            border: 1px solid $color-coal-80;
            color: $color-coal-80;
            cursor: not-allowed;
        }
    }

    [type=radio],
    [type=checkbox] {

        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        cursor: pointer;

        &:hover {
            + label {
                &::before {
                    border-color: $color-coal-80;
                    box-shadow: inset 0 0 0 1px $color-coal-80;
                }
            }

            &:checked + label {
                color: $color-blue-dark;

                &::before {
                    border-color: $color-blue-dark;
                    box-shadow: none;
                }
            }
        }

        &:checked + label {
            color: $color-blue-dark;

            &::before {
                border-color: $color-blue-dark;
            }

            &::after {
                opacity: 1;
            }

            &:hover {
                &::before {
                    border-color: $color-blue-dark;
                }
            }

        }
    }
}

.modal-form {
    &__form-actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem 0 0;
    }

    &__form-submit {
        position: relative;
        margin-left: .5rem;

        input {
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;

            &:hover ~ label {
                background-color: $color-blue-dark-80;
                border-color: $color-blue-dark-80;
            }
        }

        label {
            position: relative;
            z-index: 1;
        }
    }
}
