.flex-summary {
    padding: 1rem 0;
    align-items: center;
    min-height: 7rem;

    &__row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__amount {
        @include OpenSansBold;
        @include ms-respond(font-size,2);
        margin-left: auto;

        [aria-hidden='false'] & {
            color: $color-blue-dark;
        }
    }

    &__age {
        [aria-hidden='false'] & {
            color: $color-blue-dark;
            font-weight: 700;
        }
    }

    &__details {
        opacity: 0;
        visibility: hidden;

        [aria-hidden='false'] & {
            opacity: 1;
            visibility: visible;
            color: $color-blue-dark;
        }
    }

    &__button {
        min-width: max-content;
        color: #2b358a;
        text-decoration: underline;
        appearance: none;
        background: transparent;
        border: 0;
        padding: 0;
    }

    &__sentence {
        @include ms-respond(font-size,-1);
        margin: 0;
        text-align: right;
        width: 100%;
    }
}
