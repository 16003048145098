.search-categories {
    @media (min-width: $mq-l) {
        display: flex;
        flex-wrap: wrap;

    }

    h3 {
        width: 100%;
        max-width: 100%;
    }

    &--content-box {

        .search-categories__section-inner {
            background-color: $color-white;
            box-shadow: $box-shadow-regular;
            border-radius: $radius-5;
            margin-bottom: 1rem;
            padding: 1rem;
            width: 100%;

            > .search-categories__question {
                padding: 0;

                svg {
                    margin-left: -.5rem;

                    @media (min-width: $mq-m) {
                        margin-left: -.8rem;
                    }
                }

                .chevron {
                    margin-right: .25rem;
                }
            }

            @media (min-width: $mq-m) {
                padding: 1rem 2rem;
            }
        }

        h2 {
            margin-top: 0;
            font-size: 18px;
        }

        h4 {
            font-size: 18px;
            margin: 0;
            padding: 0;
            color: $color-black;

            &.sidebar-title {
                display: none;

                @media (min-width: $mq-m) {
                    display: block;
                }
            }
        }

        .button--secondary {
            @media (min-width: $mq-m) {
                display: none;
            }
        }

        .search-categories__section-inner.search-categories__sidebar-inner {
            @media (min-width: $mq-m) {
                padding: 1.4rem 2rem 1.5rem;
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    &__questions-item {
        padding-left: .5rem;
        border-top: 1px solid $color-coal-60;
        display: flex;
        flex-direction: column;
    }

    &__question {
        @include OpenSansRegular;
        font-size: 14px;
        display: flex;
        flex-wrap: nowrap;
        padding: .8rem 0;
        width: 100%;
        color: $color-black;
        text-decoration: none;
        align-items: center;
        appearance: none;
        background: transparent;
        border: 0;
        text-align: left;
        cursor: pointer;

        .chevron {
            transform-origin: center center;
        }

        span {
            pointer-events: none;
        }

        @media (min-width: $mq-m) {
            font-size: 16px;
        }

        &[aria-expanded='true'] {
            font-weight: 700;
            color: $color-blue-dark;

            path {
                fill: $color-blue-dark;
            }
        }

        &[aria-expanded='false'] {
            &:hover {
                .chevron {
                    transform: $chevron-transform-down;
                }
            }
        }
    }

    &__answer {
        padding-left: 1.7rem;

        p:first-of-type {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 1.5rem;
        }

        a {
            color: $color-blue-dark;

            &:hover {
                color: $color-black;
            }
        }

        li {
            @include ms-respond(font-size,1);
            @include OpenSansRegular;
            margin-bottom: .5rem;
        }

        ol {
            padding: 0 1.2rem;
            margin-bottom: 1rem;
        }

        ul:not(.provider-address__list) {
            padding: 0 1.2rem;
            margin-bottom: 1rem;

            li:not(.provider-address__list-item) {
                list-style-type: disc;
            }
        }

        &[aria-hidden='false'] {
            color: $color-blue-dark;
        }
    }

    &__questions-list {
        margin: 0;
        padding: 0;

        .search-categories__questions-item {
            &:first-of-type {
                margin-top: 1rem;
            }
        }

        .chevron {
            margin: 0 .5rem 0 -.5rem;
            transform-origin: 50%;
            flex-shrink: 0;
        }
    }

    &__sidebar-item,
    &__questions-item {
        list-style-type: none;
    }

    &__sidebar-header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .button--transparent {
            display: none;
            margin-left: auto;

            span {
                min-width: min-content;
                white-space: nowrap;
            }

            [data-filtered='true'] & {
                display: flex;
                min-width: min-content;
            }

            [data-filtered='false'] & {
                display: none;
            }
        }

        .button {

            .chevron {
                top: .1rem;
                transform: rotate(90deg);
            }

            &[aria-expanded='true'] {
                .chevron {
                    transform: rotate(270deg);
                }
            }
        }

        @media (min-width: $mq-m) {
            align-items: center;
        }
    }

    &__section {
        max-height: max-content;
        display: flex;
        flex-direction: column;
        transition: max-height 1s $default-transition-easing;
        height: auto;

        &[aria-hidden='true'] {
            max-height: 0;
            overflow: hidden;

        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $mq-l) {
            width: calc(40% - 1rem);
        }
    }

    &__questions-col {
        @media (min-width: $mq-l) {
            width: 60%;
            margin-left: auto;
        }
    }

    &__sidebar-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        max-height: max-content;
        transition: max-height 1s $default-transition-easing;
        overflow: hidden;

        &[aria-hidden='false'] {
            height: auto;
        }

        &[aria-hidden='true'] {
            max-height: 0;
        }
    }

    &__sidebar-item {
        margin: 0 .5rem .5rem 0;
        display: flex;
        min-width: min-content;
        flex-shrink: 0;
        flex-grow: 0;

        &[disabled] {
            pointer-events: none;
        }

        &:first-of-type {
            margin-top: 1rem;
        }

        .tag {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            flex-shrink: 0;
            flex-grow: 0;
            width: max-content;
            min-width: 1.8rem;
            justify-content: center;
        }

        svg {
            display: none;
        }

        &.active {

            svg {
                display: block;
                margin-right: .25rem;
            }
        }
    }
}
