.text-section {
    position: relative;

    .mpo-logo {
        width: 5.5rem;
        margin-right: 1rem;
        float: left;
        position: relative;
        top: .5rem;
        margin-bottom: .5rem;

        ~ p {
            float: none;
        }

        @media (min-width: $mq-m) {
            width: 7rem;
        }
    }

    .page--about & {
        padding-bottom: 3rem;

        @media (min-width: $mq-m) {
            padding-bottom: 5rem;
        }
    }

    &[data-section-type='medium'] {
        .text-section__inner {
            padding: 1rem;

            h3:first-of-type,
            p:first-of-type {
                margin-top: 0;
                margin-bottom: .5rem;
            }

            p:last-of-type {
                margin-bottom: 0;
            }

            @media (min-width: $mq-m) {
                padding: 0 1.5rem 1.5rem;
            }
        }
    }

    &[data-section-type='medium'][data-section-id='2'] {
        .text-section__inner {
            padding: 0 1rem 1rem;

            @media (min-width: $mq-m) {
                padding: 0 1.5rem 1.5rem;
            }
        }
    }

    &__inner {
        ul,
        ol {
            margin: 0;
            padding: 0;
        }

        ol li {
            @include numberedList;
        }

        ul li {
            @include bulletList;
        }
    }

    &[data-section-type='medium'][data-section-id='2'][data-section] {
        .layout main & {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $mq-sm) {
                max-width: 100%;
            }

            @media (min-width: $mq-m) {
                max-width: 100%;
            }
        }

        .text-section__inner {
            @media (min-width: $mq-sm) {
                margin: 0 auto;
                max-width: 36rem;
            }

            @media (min-width: $mq-m) {
                max-width: 44rem;
            }
        }
    }

    blockquote {
        @include UbuntuRegular;
        @include ms-respond(font-size,6);
        color: $color-blue-dark;
        line-height: 1.2;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        text-align: left;
        margin: 2rem 0;

        p {
            @include UbuntuRegular;
            @include ms-respond(font-size,6);
            margin: 0;
            max-width: 100%;
            display: inline;
        }

        &::before {
            content: '‘';
        }

        &::after {
            content: '’';
        }

        ~ h2 {
            margin-top: 0;
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,8);
            text-align: center;
            position: relative;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            padding: 1rem;
            width: 100vw;
            max-width: 52rem;
        }

        @media (min-width: $mq-l) {
            max-width: 54rem;
        }
    }

    h2 {
        @include ms-respond(font-size,5);
        color: $color-blue-dark;
        clear: both;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,6);
        }

        .page--about & {
            @include ms-respond(font-size,1);
            color: $color-black;
            margin-top: 3rem;
            margin-bottom: .5rem;

            &:first-of-type {
                margin-top: 0;
            }

            ~ p {
                margin-top: 0;
            }

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,1);
            }
        }
    }

    ol {
        padding-left: 1rem;
    }

    li {
        @include ms-respond(font-size,1);
        @include OpenSansRegular;
        margin-bottom: .5rem;
    }

    ul {
        padding-left: 0;

        li {
            @include bulletList;
        }
    }

    &[data-section-template='article'] {

        h2 {
            @include ms-respond(font-size,2);
            color: $color-black;
            margin-top: 3rem;
            margin-bottom: .5rem;

            ~ p {
                margin-top: 0;
            }

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,2);
            }
        }

        p,
        li,
        a {
            @include ms-respond(font-size,2);

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,1);
            }
        }

        blockquote {
            ~ h2 {
                margin-top: 1rem;
            }

            p {
                @include UbuntuRegular;
                @include ms-respond(font-size,6);
                margin: 0;
                max-width: 100%;
                display: inline;

                @media (min-width: $mq-m) {
                    @include ms-respond(font-size,8);
                }
            }
        }

    }

    footer {
        @include OpenSansRegular;
        @include ms-respond(font-size,2);
        padding: 2rem 0 0;
        margin-top: 2rem;
        border-top: 1px solid $color-grey-light;
        color: $color-coal-80;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
        }
    }

    [data-first='false'] {

        h2 {
            padding-top: 1.5rem;
            margin-top: 2rem;
            border-top: 1px solid $color-grey-light;

            @media (min-width: $mq-m) {
                margin-top: 3rem;
            }
        }

        .text-section__section-video {
            padding-top: 1rem;
        }
    }
}
