/**
* Webfonts
*/
@font-face {
    font-family: 'Open Sans';
    src:
        local('Open Sans Bold'),
        local('OpenSans-Bold'),
        url('/static/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('/static/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url('/static/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('/static/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src:
        local('Ubuntu Bold'),
        local('Ubuntu-Bold'),
        url('/static/fonts/Ubuntu-Bold.woff2') format('woff2'),
        url('/static/fonts/Ubuntu-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src:
        local('Ubuntu Regular'),
        local('Ubuntu-Regular'),
        url('/static/fonts/Ubuntu-Regular.woff2') format('woff2'),
        url('/static/fonts/Ubuntu-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/**
 * Modular scale
 */
$modularscale: (
    base: 11px,
    ratio: $major-second,
    $mq-xs: (
        base: 12.5px,
        ratio: $major-second
    ),
    $mq-s: (
        base: 13px,
        ratio: $major-second
    ),
    $mq-m: (
        base: 13px,
        ratio: $major-second
    ),
    $mq-l: (
        base: 14px,
        ratio: $major-second
    ),
    $mq-xl: (
        base: 15px,
        ratio: $major-second
    )
);

/**
 * Font stack
 */
$font-stack: /* stylelint-disable-line scss/dollar-variable-colon-space-after */
    "Ubuntu",
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen-Sans,
    Cantarell,
    'Helvetica Neue',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';

@mixin OpenSansRegular {
    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
    font-weight: 400;
}

@mixin OpenSansBody {
    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
    font-weight: 400;
    @include ms-respond(font-size,1);
}

@mixin OpenSansBodyBold {
    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
    font-weight: 700;
    @include ms-respond(font-size,1);
}

@mixin OpenSansBold {
    font-family: "Open Sans", "Segoe UI", Tahoma, sans-serif;
    font-weight: 700;
}

@mixin UbuntuRegular {
    font-family: $font-stack;
    font-weight: 400;
}

@mixin UbuntuBold {
    font-family: $font-stack;
    font-weight: 700;
}

body {
    @include ms-respond(font-size,0);
    font-family: $font-stack;
    line-height: 1.6;
    letter-spacing: .03rem;
    color: $color-black;
}

/**
 * Headers
 */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
    @include ms-respond(font-size,1);
    max-width: $max-text-width;
}
h1,
.h1 {
    @include ms-respond(font-size,15);
    line-height: 1.15;
}
h2,
.h2 {
    @include ms-respond(font-size,2);
}

p,
.p {
    @include ms-respond(font-size,1);
    @include OpenSansRegular;

    a {
        color: $color-black;

        &:hover {
            color: $color-blue-dark;
        }
    }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    &.color--blue {
        color: $color-blue-dark;
    }

    &.font--regular {
        font-weight: 400;
    }
}

li > p:first-child,
li > h2:first-child,
li > h3:first-child {
    margin-top: 0;
    display: inline;
}

.bold {
    font-weight: 700;
}
