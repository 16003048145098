.tag,
.control-tag {
    @include button;
    border: 1px solid $color-coal-outline;
    color: $color-black;
    background-color: $color-white;
    border-radius: $radius-5;
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: .25rem .6rem;
    text-decoration: none;
    min-height: 2.1rem;

    span {
        pointer-events: none;
    }

    svg {
        width: 1rem;
        height: 1rem;
    }

    path {
        fill: $color-black;
    }

    &--icon-right {
        svg {
            margin-left: .25rem;
        }
    }

    &--icon-left {
        svg {
            margin-right: .25rem;
        }
    }

    [aria-hidden="true"] &,
    [disabled] &,
    &[disabled],
    &[disabled]:hover {
        background: $color-grey-light;
        border: 1px solid $color-text-disabled;
        color: $color-coal-80;
        cursor: not-allowed;
        pointer-events: none;
    }

    &:hover {
        box-shadow: inset 0 0 0 1px  $color-coal-outline;
        border: 1px solid $color-coal-outline;
        background-color: $color-grey-light-section;
    }

    &:disabled,
    &[disabled] {
        color: $color-coal-80;
        background-color: $color-grey-light;
        border: 1px solid $color-coal-outline;
        cursor: not-allowed;

        path {
            fill: $color-coal-80;
        }

        &:hover {
            border: 1px solid $color-coal-outline;
            box-shadow: none;
        }
    }

    &[data-active='true'],
    &[aria-pressed='true'],
    .active & {
        background-color: $color-blue-pale;
        border-color: $color-blue-dark;
        color: $color-blue-dark;
        cursor: pointer;

        path {
            fill: $color-blue-dark;
        }

        &:hover {
            background-color: $color-blue-pale;
            box-shadow: none;
            border-color: $color-blue-dark;
        }
    }

}
