.navigation-list {
    transition: transform $transition-time-long $default-transition-easing;
    display: flex;
    flex-grow: 1;
    position: relative;
    z-index: 2;

    // [data-status='scrolled'] & {
    //     @media (min-width: $mq-header) {
    //         transform: translate3d(-1rem, 0, 0);
    //     }
    // }

    &[data-level='1'] {
        position: relative;
    }

    &.menu-back {
        justify-content: flex-end;
        padding: 0 1rem 0 0;
    }

    &.menu-profile {
        [data-level='1'] {
            &.navigation-list {
                @media (min-width: $mq-m) {
                    padding: 0 1rem 0 0;
                }
            }

            > .navigation-list__link {
                justify-content: flex-end;

                span {
                    display: none;
                }

                @media (min-width: $mq-m) {
                    span {
                        display: block;
                    }
                }
            }
        }

        [data-level='2'] {
            justify-content: left;
            left: -100%;
            padding: 0;

            &.navigation-list {
                top: 4rem;
                padding: 0;
            }

            @media (min-width: $mq-m) {
                left: auto;

                &.navigation-list {
                    top: 0;
                    padding: 4rem 1rem 1rem 1rem;
                }

                &::before {
                    left: 0;
                    width: 100%;
                }
            }

            .navigation-list__link {
                flex-grow: 1;
            }
        }

        li {
            justify-content: right;
        }
    }

    &[data-level='2'] {
        position: absolute;
        padding: 4rem 0 1rem 0;
        flex-direction: column;
        z-index: -1;

        // background
        &::before {
            content: '';
            width: 200vw;
            height: 100%;
            top: 0;
            left: -100vw;
            display: block;
            background-color: $color-white;
            position: absolute;
            z-index: -1;
            pointer-events: none;

            @media (min-width: $mq-m) {
                height: calc(100% - 3.6rem);
                top: 3.6rem;
            }
        }

        // backdrop
        &::after {
            content: '';
            width: 200vw;
            height: 100vh;
            top: 7.3rem;
            left: -100vw;
            display: block;
            background-color: $color-black;
            position: fixed;
            z-index: -2;
            pointer-events: none;
            transition: opacity $transition-time-long $default-transition-easing;
            opacity: .7;
        }
    }

    &[data-level='3'] {
        position: relative;
        flex-direction: column;
        padding: 0;
        max-height: calc(100vh - 16rem);
        overflow-y: auto;

        > li {
            flex-direction: column;
            padding: 0 2.2rem;

            > .navigation-list__link {
                color: $color-blue-dark;
                font-weight: 700;
                font-size: 1.5rem;

                &:hover {
                    background-color: transparent;
                    text-decoration: none;
                    border-color: transparent;
                    cursor: text;
                }
            }
        }

        @media (min-width: $mq-l) {
            flex-direction: row;

            .navigation-list-3-0 {
                .navigation-list-4-1 {
                    padding-top: 0;
                }
            }

            .navigation-list-3-1 {
                .item__level_3:not(:first-child) {
                    padding-top: 1.5rem;
                }
            }
        }
    }

    &[data-level='4'] {
        position: relative;
        flex-direction: column;
        width: auto;
        padding: 1rem 0 0 0;
    }

    .icon {
        width: 1.5rem;
        fill: $color-blue-dark;
        margin-right: 1rem;

        &svg {
            width: 1rem;
            margin: 0 .25rem;
        }
    }

    ul {
        display: none;

        &[data-level='4'],
        &[data-sub-open='true'] {
            display: flex !important;
            visibility: visible;
            opacity: 1;

            > li {
                > .navigation-list__link {
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    li {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin: 0;
        backface-visibility: hidden;
        border-bottom: 1px solid $color-grey-border-light;
        // width: 100%;
        width: auto;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-direction: row;
        }

        &[data-active='true'] {
            > .navigation-list__link {
                font-weight: 700;
                color: $color-blue-dark;
            }
        }
    }

    &__link {
        @include ms-respond(font-size,1);
        color: $color-black;
        text-decoration: none;
        width: 100%;
        padding: 1rem;
        border: 1px solid $color-white;
        text-wrap: nowrap;

        &.navigation-list--active {
            font-weight: 700;
        }

        // &:hover {
        //     text-decoration: underline;
        // }

        &:hover {
            background-color: $color-grey-light-row;
            color: $color-blue-dark;
            text-decoration: none;
            border: 1px solid $color-grey-light-row;
        }

        @media (min-width: $mq-m) {
            width: auto;
            border-radius: 1.5rem;
            padding: .5rem .7rem;

            // &:hover {
            //     background-color: $color-grey-light-row;
            //     color: $color-blue-dark;
            //     text-decoration: none;
            //     border: 1px solid $color-grey-light-row;
            // }
        }

    }

    .menu-button,
    &__link {
        cursor: pointer;
        text-decoration: none;
        align-self: flex-start;
        position: relative;
        height: 4rem;
        display: flex;
        align-items: center;
        opacity: 0;
        left: auto;
        transition:
            transform $transition-time-regular $default-transition-easing,
            opacity $transition-time-regular $default-transition-easing;

        @media (min-width: $mq-m) {
            height: 2.65rem;
            padding: 0 1.1rem;
        }
    }

    .item__level_1 > .menu-button,
    .item__level_1 > .navigation-list__link {
        position: relative;
        border: none;
        opacity: 1;
        left: auto;
        transform: none;
        transition: none;

        // @media (min-width: $mq-m) {
        //     opacity: 1;
        //     left: auto;
        //     transform: none;
        //     transition: none;
        // }
    }

    .menu-button {
        display: none;
        // @include button;
        // align-self: center;
        // padding-right: 1rem;

        // .icon {
        //     width: 1.5rem;
        //     height: 1.5rem;
        // }

        // @media (min-width: $mq-m) {
        //     display: none;
        // }
    }

    .menu__hamburger {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 6;
        transform: scale(.6);

        &__inner {
            position: relative;
            display: block;
            width: 1.8rem;
            height: calc(1.2rem + 1px);
            transition: transform $transition-time-regular $default-transition-easing;
        }

        &__inner::before,
        &__inner::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: $color-black;
            display: block;
            transform-origin: center;
        }

        &__inner::before {
            transform-origin: top center;
        }

        &__inner::after {
            bottom: 0;
            transform-origin: bottom center;
        }

        &__line {
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: $color-black;
            display: block;
            transition: opacity $transition-time-regular $default-transition-easing;
        }
    }

    > ul {
        display: flex;
        flex-direction: column;
        max-width: 48rem;
        padding: 0;
        margin: 0;

        @media (min-width: $mq-m) {
            padding: 0 0 0 1rem;
            margin: 1rem 0;
        }

        button {
            display: none;
        }

        li {
            border: none;
        }
    }
}
