.chart {
    padding-top: 100%;
    position: relative;
    width: calc(100% - 2rem);
    margin: 0 auto;

    svg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        opacity: 0;
        transition: opacity $transition-time-long $default-transition-easing;
        overflow: visible;

        &.loaded {
            opacity: 1;
        }
    }

    path {
        cursor: pointer;
    }

    .legends {
        @include ms-respond(font-size,-1);
        pointer-events: none;
        text {
            background-color: $color-black;
        }
    }

    .legend {
        opacity: 0;
        transition: opacity $transition-time-regular $default-transition-easing;
        transition-delay: $default-transition-delay;

        &[data-hover='true'] {
            opacity: 1;
        }

    }

    tspan {
        fill: white;
        pointer-events: none;
        transition: fill-opacity $transition-time-regular $default-transition-easing;
        transition-delay: $default-transition-delay;

        &.subtitle {
            fill: $color-black;
            @include ms-respond(font-size,-1);
        }

        &.pill-text,
        &.title,
        &.currency {
            fill: $color-blue-dark;
        }

        &.currency {
            @include ms-respond(font-size,1);
        }

        &.title {
            @include ms-respond(font-size,7);

            &[data-size='large'] {
                @include ms-respond(font-size,6);
            }
        }

        &.pill-text {
            @include ms-respond(font-size,-2);
        }
    }

    .pill {
        fill: #e0ecf3
    }

    [data-size='small'] {
        tspan {
            fill-opacity: 0;
        }
    }

    [data-hide-legends='true'] {
        tspan {
            fill-opacity: 0;
        }

        [data-size='small'] {
            &.hover {
                tspan {
                    fill-opacity: 1;
                }
            }
        }
    }
}
