.agree-form {
    position: relative;
    margin: 1rem;
    padding: 2rem 1rem;
    background-color: $color-white;
    border-radius: $radius-5;
    box-shadow: $box-shadow-regular;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        background-color: $color-blue-dark;
        left: 0;
        top: 0;
        width: 100%;
        height: .5rem;
        z-index: 1;
    }

    @media (min-width: $mq-m) {
        max-width: 633px;
        padding: 2rem 1.5rem;
    }

    h2 {
        color: $color-blue-dark;
        margin-top: 0;
    }

    &__header {
        padding-bottom: 1rem;
    }

    &__introduction {
        svg {
            width: .8rem;
            height: .8rem;
            transform: rotate(90deg);
            position: relative;
            top: .1rem;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }

        a {
            margin: 1rem auto 1rem;
            flex-shrink: 0;
            max-width: none;
            min-width: 5rem;

            @media (min-width: $mq-m) {
                margin: 0;
            }
        }

        button {
            margin: 0 auto;

            @media (min-width: $mq-m) {
                margin-right: 0;
            }
        }
    }
}
