.downloads {
    position: relative;

    &__header {

        h2 {
            [data-modal-open='true'] &,
            .content-box & {
                display: none;
            }
        }

    }

    &__content {
        position: relative;
        z-index: 2;
        max-height: 100vh;
        overflow: hidden;
        transition:
            opacity $transition-time-regular $default-transition-easing,
            max-height $transition-time-regular $default-transition-easing;

        [data-downloading='true'] & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 0;
            opacity: 0;
            pointer-events: none;
            z-index: 1;
        }
    }

    &__introduction {
        p {
            margin-top: 0;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        list-style-type: none;
        margin-right: .5rem;

        .button--secondary {
            max-width: none;
        }
    }

    &__lists {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: .8rem;
        flex-wrap: nowrap;

        h3 {
            text-transform: uppercase;
        }

        @supports (display: grid) {
            @media (min-width: $mq-s) {
                display: grid;
                grid-template-columns: max-content 1fr;
                grid-gap: .8rem;
                align-items: center;
            }
        }
    }

    &__wrapper {
        padding-top: .5rem;

    }

    &__status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        z-index: 1;
        max-height: 0;
        overflow: hidden;
        transition:
            opacity $transition-time-regular $default-transition-easing,
            max-height $transition-time-regular $default-transition-easing;

        [data-downloading='true'] & {
            pointer-events: all;
            position: relative;
            opacity: 1;
            z-index: 2;
            max-height: 100vh;
        }

        .illustrations--downloads {
            width: 100%;
            max-width: 15rem;
            margin: 0 auto 2rem;
            display: block;

            @media (min-width: $mq-m) {
                width: 20rem;
                max-width: 20rem;
                margin: 0 1rem 0 auto;
            }
        }

        h2 {
            @include ms-respond(font-size,4);
            margin: 0;
            color: $color-blue-dark;
            line-height: 1.4;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,6);
            }
        }

        .button--icon svg {
            width: 1.4rem;
            height: 1.4rem;
        }

        @media (min-width: $mq-m) {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &__status-right {
        max-width: 26rem;
        margin: 0 auto;
        @media (min-width: $mq-m) {
            max-width: calc(100% - 20rem);
            width: calc(100% - 20rem);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__status-text {
        margin-bottom: 2rem;

        @media (min-width: $mq-m) {
            margin-bottom: 1rem;
        }
    }
}
