.highlight-section {
    position: relative;

    &[data-color='pink'] {
        background-color: $color-red;
    }

    &[data-color='blue'] {
        background-color: $color-blue-light;
    }

    .layout__inner {
        padding-top: 3rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row-reverse nowrap;
            align-items: center;
            padding-top: 5rem;
            padding-bottom: 6rem;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 1.4;
        text-align: center;
        max-width: 18rem;
        margin: 0 auto;
        color: $color-white;

        [data-color='blue'] & {
            color: $color-blue-dark;
        }

        @media (min-width: $mq-m) {
            font-size: 56px;
            line-height: 1.2;
            text-align: left;
            max-width: 20rem;
            margin: 0 0 1rem 0;
        }
    }

    img {
        width: 55%;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-bottom: .5rem;

        @media (min-width: $mq-m) {
            width: 84%;
            max-width: 26rem;
            margin: 0;
        }
    }

    &__first {
        img {
            margin-bottom: 2rem;

            @media (min-width: $mq-m) {
                margin-bottom: 0;
                margin-left: auto;
            }
        }
    }

    &__last {
        img {
            margin-top: 2rem;

            @media (min-width: $mq-m) {
                margin-top: 0;
                margin-right: auto;
            }
        }
    }

    &__first,
    &__last {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (min-width: $mq-m) {
            width: 50%;
        }
    }

    &__text {
        text-align: center;

        p {
            @include UbuntuRegular;
            font-size: 18.66px;
            margin: 0 0 1rem;
            color: $color-white;
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,2);
            text-align: left;
            margin: 0;
        }
    }

    .button--primary {
        justify-content: center;
        margin: 1rem auto;

        @media (min-width: $mq-m) {
            min-width: auto;
            max-width: auto;
            width: auto;
            font-size: 14px;
            margin: 1rem 0 0;
        }

        @media (min-width: $mq-l) {
            font-size: 16px;
        }
    }
}
