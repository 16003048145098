.banner {
    margin: 0 0 2em;
    overflow: hidden;

    &__wrapper {
        position: relative;
        display: flex;

        @media (min-width: $mq-m) {
            min-height: min-content;
        }
    }

    &__img {
        display: block;
        object-fit: cover;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
        max-height: 550px;
    }

    .layout--home & {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        max-width: unset;
        width: 100vw;
        height: 100%;

    }

    &__mask {
        &[data-type='mask-right'] {
            position: absolute;
            width: 100vw;
            height: 152vh;
            left: -32vw;
            top: -52vh;
            overflow: hidden;
            border-radius: 100%;
        }

        &[data-type='mask-full'] {
            position: absolute;
            width: 150vw;
            height: calc(100% + 25vh);
            left: -25vw;
            top: -25vh;
            overflow: hidden;
            // border-radius: 100%;
            box-shadow: $box-shadow-7;

            @media (min-width: $mq-m) {
                width: 150vw;
                left: -25vw;
                height: calc(89% + 25vh);
            }
        }
    }

    &__container {
        &[data-type='mask-right'] {
            position: absolute;
            width: calc(100% - 32vw);
            height: calc(100% - 52vh);
            top: 52vh;
            left: 32vw;
        }

        &[data-type='mask-full'] {
            position: absolute;
            width: 100vw;
            top: 25vh;
            left: 25vw;
            height: calc(100% - 25vh);

            @media (min-width: $mq-m) {
                height: calc(100% - 25vh);
            }
        }
    }
}
