.header-intro {
    width: 100%;
    padding-top: 3rem;
    position: relative;
    z-index: 2;
    background-color: $color-white;

    .layout__inner {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }
    }

    h1 {
        @include ms-respond(font-size,12);
        color: $color-blue-dark;
        display: flex;
        flex-direction: column;
        margin: 0;

        .page--about & {
            @include ms-respond(font-size,8);

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,10);
            }
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,14);
        }
    }

    p {
        @include OpenSansBold;
        @include ms-respond(font-size,3);
        flex-basis: 100%;
        padding-right: 0;
        color: $color-red;
        margin-bottom: 0;

        a {
            color: $color-red;
            margin-bottom: 1rem;

            &:hover {
                color: $color-black;
            }
        }

        [data-text-color='blue'] & {
            color: $color-blue-dark;
        }

        [data-text-color='black'] & {
            color: $color-black;
        }

        @media (min-width: $mq-m) {
            max-width: 30rem;

            [data-page-type='warme'] & {
                max-width: 100%;
            }
        }
    }

    &[data-text-color='blue'] {
        p,
        a {
            color: $color-blue-dark;
        }

        a:hover {
            color: $color-black;
        }
    }

    &[data-text-color='black'] {
        p,
        a {
            color: $color-black;
        }

        a:hover {
            color: $color-blue-dark;
        }
    }

    &[data-type='illustration'] {

        .header-intro__right {
            display: flex;
            flex-direction: column;
            padding: 2rem 0 0;

            img {
                max-width: 22rem;
                width: 80%;
                margin: 0 auto;

                @media (min-width: $mq-m) {
                    margin: 0 0 0 auto;
                    width: 90%;
                }
            }

            @media (min-width: $mq-m) {
                padding: 0;
            }
        }

    }

    &[data-page-type='warme'] {

        .layout__inner {
            align-items: flex-start;

            @media (min-width: $mq-l) {
                align-items: center;
            }
        }

        h1 {
            @include ms-respond(font-size,10);
            @media (min-width: $mq-m) {
                @include ms-respond(font-size,12);
            }

            .page--about & {
                @include ms-respond(font-size,5);

                @media (min-width: $mq-xs) {
                    @include ms-respond(font-size,6);
                }

                @media (min-width: $mq-m) {
                    @include ms-respond(font-size,8);
                }

                @media (min-width: $mq-l) {
                    @include ms-respond(font-size,10);
                }
            }
        }

        @media (min-width: $mq-m) {
            padding-top: 4rem;
            margin-bottom: 1rem;

            p {
                max-width: 100%;
            }
        }
    }

    &[data-type='simple'] {
        h1 {
            @include ms-respond(font-size,6);
            width: 100%;
            text-align: center;
            max-width: 100%;
            margin: 1rem 0;

            @media (min-width: $mq-xs) {
                @include ms-respond(font-size,8);
            }

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,10);
                margin: 2rem 0;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,4);
            flex-flow: row nowrap;
        }
    }

    &__left {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;

        @media (min-width: $mq-m) {
            width: 60%;

            [data-type='simple'] & {
                width: 100%;
            }
        }

    }
    &__right {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 2rem;

        @media (min-width: $mq-m) {
            width: 40%;
            padding: 0;
        }
    }
}
