.bedrag-ineens-table-row {
    &__card-content {
        position: relative;
        z-index: 1;
        margin: -.5rem -1px 0 1px;
        padding: 2rem 0 0;
        background-color: $color-grey-light-section;
        border-bottom-left-radius: $radius-5;
        border-bottom-right-radius: $radius-5;
        border: 2px solid transparent;

        &[aria-hidden='false'] {
            background-color: $color-blue-pale;
        }
    }

    &__item {
        list-style-type: none;
        padding: 0 1rem;

        .bedrag-ineens-table-row-summary {
            border-bottom: 1px solid $color-coal-outline;
        }

        &:last-of-type {
            .bedrag-ineens-table-row-summary {
                border-bottom: none;
            }
        }
    }

}
