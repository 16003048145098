.language-selector {
    position: relative;
    margin-right: .5rem;

    [data-menu-open='true'] & {
        opacity: 0;
        pointer-events: none;
    }

    [data-menu-open='true'] .mobile-nav__item & {
        opacity: 1;
        pointer-events: all;
    }

    @media (min-width: $mq-header) {
        margin-right: 0;
    }

    &__button {
        margin-left: .25rem;
        padding: 0;

        [data-active='true'] & {
            box-shadow: $box-shadow-6;
        }

        @media (min-width: $mq-header) {
            margin-left: .5rem;
        }
    }

    &__button-inner {
        display: flex;
        padding: .505rem .95rem;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__short-text {
        @media (min-width: $mq-xxxxl) {
            display: none;
        }
    }

    &__long-text {
        display: none;

        @media (min-width: $mq-xxxxl) {
            display: block;
        }
    }

    &__dropdown {
        position: absolute;
        top: calc(100% + 1rem);
        left: auto;
        right: 0;
        min-width: 17rem;
        margin: 0;
        padding: 1rem;
        background-color: $color-white;
        border-radius: $radius-5;
        box-shadow: $box-shadow-6;
        border: 1px solid $color-night-blue-outline;

        &[aria-hidden='true'] {
            display: none;
        }
    }

    &__language {
        list-style-type: none;

        &:first-of-type {
            margin-bottom: .5rem;
        }
    }

    &__link {
        @include UbuntuBold;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: $color-blue-dark;
        border: 1px solid $color-night-blue-outline;
        border-radius: 1.5rem;
        padding: .505rem .95rem;
        text-decoration: none;

        &::after {
            content: "";
            display: inline-block;
            vertical-align: text-top;
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
            background: $color-white;
            border: 2px solid $color-grey-dark;
            flex-shrink: 0;
            border-radius: 4px;
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__target {
        &:hover {
            background-color: $color-grey-light-row;
            border-color: $color-night-blue-outline;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline;
        }

        &:focus {
            outline: 3px solid $color-blue-light;
        }

        &:active {
            background-color: $color-grey-light-row;
            border-color: $color-grey-light;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline, 0 0 0 2px  $color-grey-light;
        }
    }

    &__current {
        background-color: $color-blue-dark;
        color: $color-white;

        &::after {
            width: .15rem;
            height: .15rem;
            transform: rotate(45deg);
            border-radius: 0;
            border: none;
            margin-right: 1rem;
            box-shadow:
                .1rem 0 0 $color-white,
                .2rem 0 0 $color-white,
                .3rem 0 0 $color-white,
                .4rem 0 0 $color-white,
                .4rem -.1rem 0 $color-white,
                .4rem -.2rem 0 $color-white,
                .4rem -.3rem 0 $color-white,
                .4rem -.4rem 0 $color-white,
                .4rem -.5rem 0 $color-white,
                .4rem -.6rem 0 $color-white,
                .4rem -.7rem 0 $color-white,
                .4rem -.8rem 0 $color-white;
        }
    }

    &__flag {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        margin-right: .25rem;

        li & {
            margin-right: .5rem;
        }
    }

    &__chevron {
        width: 1.5rem;
        height: 1.5rem;
        transform: rotate(90deg);
        margin-right: -.5rem;
    }

    &[data-active='true'] {
        .language-selector__chevron {
            transform: rotate(-90deg);
        }
    }
}
