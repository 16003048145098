.cta-card {
    position: relative;
    background-color: $color-white;

    &__list {
        margin: 0;
        padding: 0;
    }

    &__list-item {
        list-style-type: none;
        padding-right: 1rem;
    }

    &[data-section-type='medium'] {
        .cta-card__inner {
            padding: 1rem;

            @media (min-width: $mq-m) {
                padding: 1.5rem;
            }
        }
    }

}
