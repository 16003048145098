/**
 * There are different types of notifications
 **/

.notification {
    border-radius: $radius-5;
    padding: .8rem .5rem .8rem;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;

    h2 {
        @include ms-respond(font-size,1);
        margin: 0;
        padding-right: 2rem;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,0);
        }
    }

    p {
        main & {
            max-width: 100%;
            @include ms-respond(font-size,-1);
            font-weight: normal;      
        }

        margin: 0;

    }

    &__description {
        @include ms-respond(font-size,-1);
        margin: 0;
    }

    &__icon {
        margin-right: .5rem;
        line-height: 1.4;

        svg {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    &__container {
        display: flex;
        flex-wrap: nowrap;
    }

    &__content {
        flex-grow: 1;
    }

    &__close {
        position: absolute;
        right: 1rem;
        left: auto;
        z-index: 2;
        top: 1rem;
        cursor: pointer;

        svg {
            width: 1.2rem;
            height: 1.2rem;
        }

        path {
            fill: $color-black;
        }
    }

    &--hidden {
        display: none;
    }

    &--warning {
        border: 1px solid $color-warning;
        background-color: $color-warning-light;
        color: $color-warning-text;

        h2,
        p,
        a {
            color: $color-warning-text;
        }

        .notification__icon path {
            fill: $color-warning;
        }
    }

    &--failure,
    &--error {
        border: 1px solid $color-failure;
        background-color: $color-failure-light;
        color: $color-failure-text;

        h2,
        p,
        a {
            color: $color-failure-text;
        }

        .notification__icon path {
            fill: $color-failure;
        }
    }

    &--success {
        border: 1px solid $color-success;
        background-color: $color-success-light;
        color: $color-success-text;

        h2,
        p,
        a {
            color: $color-success-text;
        }

        .notification__icon path {
            fill: $color-success;
        }
    }

    &--info {
        border: 1px solid $color-info;
        background-color: $color-info-light;
        color: $color-info-text;

        h2,
        p,
        a {
            color: $color-info-text;
        }

        .notification__icon path {
            fill: $color-info;
        }
    }
}
