.toptaken {
    .columns__list {
        flex-wrap: wrap;
        justify-content: center;

        .columns__list-item {
            flex-basis: calc(33.3333% - 20px);
            margin: 10px;
            padding: .7rem;

            &[data-length='1'],
            &[data-length='2'],
            &[data-length='4'] {
                flex-basis: calc(50% - 20px);
            }

            .toptaken__item__img {
                max-width: 100%;
            }

            .toptaken__item__title {
                margin: revert;
            }

            .toptaken__item__form {
                padding-top: 1rem;
            }

            .toptaken__item__content {
                p,
                span {
                    margin: 0;
                    @include ms-respond(font-size,1);
                }

            }

            .toptaken__item__link {
                border: none;
                background: none;
                cursor: pointer;
                align-self: flex-end;

                svg {
                    height: 24px;
                    transform: translateY(.4rem);
                }
            }

            h2 {
                text-align: left;
                margin: 0 auto;
                @include ms-respond(font-size,2);
            }
        }
        .content-box--white {
            padding: 1rem;
        }

        .toptaken__item {
            display: flex;
            flex-direction: column;
        }

        .toptaken__item__wrapper {
            height: 150px;
            overflow: hidden;
        }

        .toptaken__item__form {
            flex-grow: 1;
            display: flex;
        }

        .toptaken__item__link {
            margin-left: -1rem;
        }
    }
    .column__title {
        text-align: center;
        @include ms-respond(font-size,4);
    }
}
