.content-box {
    &--white {
        background-color: $color-white;
        border-radius: $radius-5;
        box-shadow: $box-shadow-regular;
        padding: 1rem;

        h2 {
            @include ms-respond(font-size, 2);

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 1);
            }
        }

        header {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 0;
        }

        h1 {
            @include ms-respond(font-size,4);
            @include OpenSansBold;
            color: $color-blue-dark;
            line-height: 1.5;
            margin-top: .5rem;
            padding-right: 1rem;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,2);
            }
        }

        @media (min-width: $mq-m) {
            padding: 2rem;
        }
    }

    &--small {
        padding: 1rem;

        h3 {
            margin-bottom: 0;
        }

        @media (min-width: $mq-m) {
            p {
                @include ms-respond(font-size, -1);
            }
        }
    }

    &--eidas {

        header {
            @media (min-width: $mq-m) {
                margin-bottom: 2rem;
            }

            svg {
                width: 4rem;
                height: 5rem;
                flex-grow: 0;
                flex-shrink: 0;

                @media (min-width: $mq-m) {
                    width: 5rem;
                    height: 5rem;
                }
            }
        }

        ol {
            @include ms-respond(font-size,1);
            line-height: 1.7;
            list-style-position: inside;
            padding-left: 0;
        }
    }
}
