.bedrag-ineens-table {
    padding: 2rem 0 0;

    &__columns {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        scrollbar-color: $color-transparent $color-transparent;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-moz-scrollbar {
            display: none;
        }
    }

    &__inner.layout__inner {
        padding: 0;

        @media (min-width: $mq-m) {
            padding: 1rem;
        }
    }

    &__column {
        scroll-snap-align: center;
        margin: 0 .5rem;
        width: calc(100vw - 2rem);
        flex-shrink: 0;

        @media (min-width: $mq-m) {
            width: calc(50% - 1rem);
        }

        @media (min-width: $mq-m) {
            cursor: pointer;
        }

        &:first-of-type {
            margin-left: 1.5rem;
        }

        &:last-of-type {
            padding-right: 1rem;
            width: calc(100vw - 2rem);

            @media (min-width: $mq-m) {
                width: calc(50% - 1rem);
            }
        }

        &:hover {
            .bedrag-ineens-table-row__card-content {
                &[aria-hidden='true'] {
                    border: 2px solid $color-coal-outline;
                }
            }

            .bedrag-ineens-table__header button {
                &[aria-selected='true'] {
                    border: 1px solid $color-coal-outline;
                    padding: 1rem .5rem;
                }

                &[aria-selected='false'] {
                    border: 2px solid $color-coal-outline;
                    padding: calc(1rem - 1px) calc(.5rem - 2px);

                    background-color: $color-grey-light-section;
                }
            }
        }
    }

    &__header {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;

        button {
            border: 1px solid $color-coal-outline;
            margin: 1px;
            background-color: $color-white;
            color: $color-black;
            border-radius: $radius-5;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 1rem .5rem;
            height: 5.2rem;
            justify-content: center;

            svg {
                width: 1.2rem;
                height: 1.2rem;
                display: none;
                margin-left: .5rem;
            }

            path {
                fill: $color-white;
            }

            @media (min-width: $mq-m) {
                cursor: pointer;
            }

            &[aria-selected='true'] {
                border: 1px solid $color-blue-dark;
                background-color: $color-blue-dark;
                color: $color-white;
                font-weight: 700;

                svg {
                    display: block;
                }

                ~ svg {
                    opacity: 1;
                }
            }
        }
    }

    &__button-wrapper {
        @include ms-respond(font-size, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__header-subtitle {
        @include ms-respond(font-size, 0);
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__notifications {
        padding: 1rem 1rem 0;

        @media (min-width: $mq-m) {
            padding: 1rem 1.5rem 0;
        }
    }

    &__notification {
        @media (min-width: $mq-m) {
            &[aria-hidden='true'] {
                display: none;
            }
        }
    }

    &__warning-icon {
        margin-left: .5rem;
        line-height: 1.4;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            transform: translateY(.2rem);

            path {
                fill: $color-info;
            }
        }
    }

    &__netto {
        @include ms-respond(font-size, 0);
        display: block;
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
        font-weight: 400;

        [aria-selected='false'] & {
            display: none;
        }
    }
}
