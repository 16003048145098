.welcome {
    width: 100%;
    padding-top: 3rem;
    position: relative;
    z-index: 2;
    background-color: $color-grey-light-section;

    .layout__inner {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }
    }

    h1 {
        @include ms-respond(font-size,7);
        color: $color-blue-dark;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        @media (min-width: $mq-m) {
            @include ms-respond(font-size,11);
        }

        span:first-child {
            margin-right: .3em;
        }

        span:last-child {
            flex-grow: 1;
        }
    }

    p {
        @include ms-respond(font-size,2);
        flex-basis: 100%;
        padding-right: 0;
        margin-bottom: 0;

        a {
            color: $color-red;
            margin-bottom: 1rem;

            &:hover {
                color: $color-black;
            }
        }

        [data-text-color='blue'] & {
            color: $color-blue-dark;
        }

        [data-text-color='black'] & {
            color: $color-black;
        }

        @media (min-width: $mq-m) {
            max-width: 40rem;
            [data-page-type='warme'] & {
                max-width: 100%;
            }
        }
    }

    &[data-text-color='blue'] {
        p,
        a {
            color: $color-blue-dark;
        }

        a:hover {
            color: $color-black;
        }
    }

    &[data-text-color='black'] {
        p,
        a {
            color: $color-black;
        }

        a:hover {
            color: $color-blue-dark;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,4);
            flex-flow: row nowrap;
        }
    }

    &__left {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: $mq-m) {
            width: 60%;

            [data-type='simple'] & {
                width: 100%;
            }
        }

    }
    &__right {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 2rem;

        @media (min-width: $mq-m) {
            width: 40%;
            padding: 0;
        }
    }
}
