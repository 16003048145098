.tabbed-section {
    position: relative;

    .financial-overview {
        margin-top: 2rem;

        &__inner {
            &.layout__inner {
                padding: 0 0 2rem;
            }

            @media (min-width: $mq-m) {
                &.layout__inner {
                    padding: 0 1rem;
                }
            }
        }

        @media (min-width: $mq-m) {
            margin-top: 0;
            padding: 3rem 0 4rem 0;
        }

    }

    &__inner {
        padding: 1rem;

        @media (min-width: $mq-m) {
            padding: 2rem;
        }

        @media (min-width: $mq-l) {
            padding: 3rem;
        }

        &[data-section-type='medium-header'] {
            padding: 0;

            .tabbed-section__tabs-content {
                display: flex;
                flex-direction: column;
            }
        }

        .down {
            width: 2rem;
            height: 2rem;
            transform: rotate(90deg);
            margin: 0 auto;
            opacity: 0;
        }
    }

    &[data-section] {
        padding-bottom: 5rem;
    }

    h2 {
        @include ms-respond(font-size,6);
        line-height: 1.3;
        color: $color-blue-dark;
        max-width: 80vw;
        margin: 1rem 0 .5rem;
    }

    &__introduction {
        padding-bottom: 1rem;

        p {
            margin-top: 0;
        }

        [data-section-type='medium-header'] & {
            padding: 0 1rem;

            @media (min-width: $mq-m) {
                padding: 0 1.5rem 1rem;
            }
        }
    }

    &__tabs-list {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;

        [data-length="1"] &,
        [data-length="2"] & {
            justify-content: flex-start;

            .tabbed-section__tab {
                margin-right: 1rem;
            }
        }

        [data-section-type='medium-header'] & {
            padding: 0 1rem;

            @media (min-width: $mq-m) {
                padding: 0 1.5rem;
            }
        }
    }

    &__tab {
        list-style-type: none;
        width: calc(33.3% - .5rem);
        display: flex;
        flex-direction: column;
    }

    [data-length='2'] {
        .tabbed-section__tab {
            width: calc(50% - .5rem);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__tab-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-white;
        border-radius: $radius-5;
        border: 1px solid $color-coal-outline;
        text-decoration: none;
        padding: .5rem;
        justify-content: center;
        text-align: center;
        min-height: calc(100% - 2rem);

        @media (min-width: $mq-m) {
            padding: 1rem;
        }

        span {
            color: $color-black;
        }

        svg {
            width: 2rem;
            height: 2rem;
            margin-bottom: .25rem;

            @media (min-width: $mq-m) {
                width: 3rem;
                height: 3rem;
            }
        }

        path {
            fill: $color-black;
        }

        &:hover {
            background-color: $color-grey-light-row;
            box-shadow: inset 0 0 0 1px $color-coal-outline;
        }

        &[aria-selected='true'] {
            background-color: $color-blue-pale;
            border-color: $color-blue-dark;

            path {
                fill: $color-blue-dark;
            }

            span {
                color: $color-blue-dark;
            }
            ~ .down {
                opacity: 1;
            }

            &:hover {
                box-shadow: none;
            }
        }
    }

    [data-content][aria-hidden='true'] {
        display: none;
        visibility: hidden;
    }

}
