@mixin visualy-hide-content {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

@mixin chevron {
    width: 1.7rem;
    height: 1.7rem;
    position: relative;
    z-index: 2;

    path {
        fill: $color-black;
    }

    // Default direction for chevron in tabs
    [role='tab'] & {
        transform: rotate(90deg);
    }

    [data-is-open='true'] & {
        transform: rotate(-90deg);

        path {
            fill: $color-blue-dark;
        }
    }

    [aria-expanded='true'] & {
        transform: rotate(-90deg);

        path {
            fill: $color-blue-dark;
        }
    }

    [data-is-open='false'] & {
        transform: rotate(90deg);
    }

    [aria-expanded='false'] & {
        transform: rotate(90deg);
    }

    .color-white &,
    .color-white[aria-expanded='true'] {
        path {
            fill: $color-white;
        }
    }
}

@mixin nthTransition($count, $threshold) {
    @for $i from 1 through $count {
        &:nth-child(#{$i}) > .list-item,
        &:nth-child(#{$i}) > .menu-link,
        &:nth-child(#{$i}) > .navigation-list__link {
            &:hover {
                transition-delay: 0s;
            }
            transition-delay: #{$i * .1 + $threshold}s;
        }
    }
}

@mixin nthTransitionReverse($count) {
    @for $i from $count * -1 through -1 {
        &:nth-child(#{$i}) > .list-item,
        &:nth-child(#{abs($i)}) > .menu-link,
        &:nth-child(#{abs($i)}) > .navigation-list__link {
            transition-delay: #{$i * .1}s;
        }
    }
}

@mixin sectionTransition {
    left: 0;
    top: 0;
    transition:
        opacity $transition-time-long $default-transition-time $default-transition-easing,
        transform $default-transition-time $default-transition-easing;

    &[hidden] {
        position: absolute;
        width: 100%;
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
        pointer-events: none;
        visibility: hidden;
    }
}

@mixin visuallyhidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    border: 0;

    &:focus {
        clip: auto;
        width: min-content;
        clip-path: none;
        height: auto;
        position: relative;
        overflow: visible;
        display: block;
        white-space: nowrap;
        margin: 0;
    }
}

@mixin visible {
    border: 0;
    clip: none;
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: auto;
}

@mixin bulletList {
    @include OpenSansRegular;
    @include ms-respond(font-size,1);
    list-style-position: outside;
    margin-bottom: .5rem;
    margin-left: 1.2rem;
    list-style-type: none;

    &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: .5rem;
        padding: 0;
        background-color: $color-black;
        position: relative;
        top: -.15rem;
        margin-right: -5px;
        left: -1.2rem;
    }

    a {
        color: $color-black;

        &:hover {
            color: $color-blue-dark;
        }
    }
}

@mixin numberedList {
    @include OpenSansRegular;
    @include ms-respond(font-size,1);
    list-style-position: outside;
    margin-left: 1.2rem;
    margin-bottom: .5rem;

    a {
        color: $color-black;

        &:hover {
            color: $color-blue-dark;
        }
    }
}

@mixin infoIcon {
    content: 'i';
    width: 1.5em;
    height: 1.5em;
    margin: 0 1rem 0 0;
    border-radius: 50%;
    line-height: 1.4;
    font-size: $font-size-s;
    font-weight: normal;
    color: $color-white;
    background-color: $color-secondary;
    text-align: center;
}

@mixin crossIcon {
    content: '×';
    width: 1em;
    height: 1em;
    margin: 0 .5em 0 0;
    line-height: 1;
    font-weight: 300;
    color: $color-failure;
}
