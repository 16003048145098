[data-modal-content] {
    display: none;

    .content-box & {
        display: block;
    }
}

// Body Overlay
body {

    &::before {
        content: '';
        width: 100vw;
        height: $height-100vh;
        top: 0;
        display: block;
        opacity: 0;
        background-color: $color-black;
        position: fixed;
        z-index: 6;
        pointer-events: none;
        transition: opacity $transition-time-long $default-transition-easing;

    }

    &[data-modal-open='true']::before {
        opacity: .7;
        pointer-events: all;
    }

    &[data-modal-type='centered']::before {
        z-index: 3;

        @media (min-width: $mq-m) {
            z-index: 4;
        }

    }
}

.modal {
    position: fixed;
    top: 0;
    width: calc(100vw - 1rem);
    height: $height-100vh;
    z-index: 7;
    padding: 0;
    transition:
        transform $transition-time-long $default-transition-easing,
        opacity $transition-time-long $default-transition-easing;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;

    [data-modal-type='side'] & {
        left: auto;
        right: -100vw;

        &[data-open='true'] {
            transform: translate3d(-100vw, 0, 0);
            box-shadow: $box-shadow-5;

            @media (min-width: $mq-m) {
                transform: translate3d(-50vw, 0, 0);
                opacity: 1;
            }
        }
    }

    @media (min-width: $mq-m) {
        display: flex;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;

        [data-modal-type='side'] & {
            right: -50vw;
            width: 50vw;
            max-width: 35.5rem;
        }
    }

    &__onboarding {
        display: none;
    }

    &__header {
        display: flex;
        flex-flow: row-reverse nowrap;
        align-items: center;
        padding: 1.5rem 1.5rem 2rem 1.5rem;

        h2 {
            @include ms-respond(font-size,4);
            margin: 0;
            color: $color-blue-dark;

            [data-downloading='true'] & {
                display: none;
            }
        }

        button {
            @include button;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: -.5rem -.5rem auto auto;

            @media (min-width: $mq-m) {
                margin: -1rem -.5rem auto auto;
            }
        }

        svg {
            width: 1.7rem;
            height: 1.7rem;

            path {
                fill: $color-blue-dark;
            }
        }

        @media (min-width: $mq-m) {
            padding: 3rem 3rem 2rem 3rem;
        }
    }

    &__content {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(#{$height-100vh} - 7.5rem);
        padding: 0 1.5rem;

        [data-modal-overflow='true'] & {
            overflow-y: visible;
        }

        @media (min-width: $mq-m) {
            padding: 0 3rem;
            height: calc(#{$height-100vh} - 11rem);
        }
    }

    .home-animation {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: $mq-m) {
            margin-top: -2rem;
            margin-bottom: 2rem;
        }

        .video {
            width: calc(100% - 4rem);
            max-width: 733px;
            margin: 0 auto;

            @media (min-width: $mq-m) {
                width: 60%;
                padding: 0;
            }
        }
    }

    [data-modal-content] {
        display: block;
    }

    &[data-open='true'] {
        pointer-events: all;
        transform: translate3d(-100vw, 0, 0);
        box-shadow: $box-shadow-5;
        visibility: visible;
        opacity: 1;

        @media (min-width: $mq-m) {
            transform: translate3d(-50vw, 0, 0);
        }
    }

    [data-modal-type='full'] & {
        max-width: 100vw;
        width: 100vw;
        right: auto;
        top: -3rem;
        opacity: 0;
        pointer-events: none;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10rem 1rem 0;

        @media (min-width: $mq-m) {
            transform: translate3d(0, 3rem, 0);
            justify-content: center;
            align-items: center;
            padding: 0;
        }

        &[data-open='true'] {
            pointer-events: all;
            transform: translate3d(0, 3rem, 0);
            opacity: 1;
            box-shadow: none;

            @media (min-width: $mq-m) {
                transform: translate3d(0, 3rem, 0);
            }
        }

        .modal__wrapper {
            max-height: calc(#{$height-100vh} - 14rem);
            width: 100%;
            max-width: 53rem;
            height: auto;

            @media (min-width: $mq-m) {
                padding: 0 1rem;
            }
        }

        .modal__header {
            padding: 0;

            h2 {
                @media (min-width: $mq-m) {
                    @include ms-respond(font-size,6);
                    margin-right: auto;
                    padding-bottom: .8rem;
                }
            }
        }

        .modal__content {
            height: auto;
            padding: 0;
            overflow: visible;
        }

        .modal__close-button {
            position: absolute;
            top: 2rem;
            left: auto;
            right: 1rem;
            z-index: 5;

            @media (min-width: $mq-m) {
                right: 2rem;
            }
        }

    }

    [data-modal-overflow="true"][data-modal-type='centered'] & {
        .modal__header,
        .modal__content {
            background-color: $color-white;
        }
    }

    [data-modal-type='centered'] & {

        max-width: 100vw;
        width: 100vw;
        right: auto;
        top: -3rem;
        opacity: 0;
        pointer-events: none;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0 1rem;
        z-index: 4;

        @media (min-width: $mq-m) {
            width: auto;
            max-width: 60rem;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            justify-content: center;
            align-items: center;
            padding: 0;
            border-radius: $radius-5;
            height: auto;
            max-height: auto;
            min-height: auto;
        }

        &.focus-visible[data-focus-visible-added] {
            border: none;
            outline: 0;
        }

        .modal__wrapper {
            width: 100%;
        }

        .modal__header {
            background-color: $color-white;
            padding: 1.5rem;

            button {
                margin: 0 -.5rem 0 auto;
            }

            svg path {
                fill: $color-black;
            }

            @media (min-width: $mq-m) {
                background-color: $color-grey-light-section;
            }
        }

        .modal__content {
            padding: 0;
            height: calc(calc(var(--vh, 1vh) * 100) - 5rem);
            background-color: $color-grey-light-section;

            @media (min-width: $mq-m) {
                height: min-content;
            }
        }

        &[data-open='false'],
        [data-modal-open='false'] & {

            @media (min-width: $mq-m) {
                width: calc(100vw - 2rem);
                max-width: 60rem;
                left: 50%;
                height: min-content;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                opacity: 0;
                pointer-events: none;
            }
        }

        &[data-open='true'] {
            pointer-events: all;
            transform: translate3d(0, 3rem, 0);
            opacity: 1;
            box-shadow: none;

            @media (min-width: $mq-m) {
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }
}

[data-ajax-link] {
    color: $color-black;

    &:hover {
        color: $color-blue-dark;
    }

    font {
        pointer-events: none;
    }

    &.button--primary {
        color: $color-white;
    }

    &[disabled='disabled'] {
        cursor: not-allowed;
        color: $color-text-disabled;
    }
}

[data-modal-trigger] {
    font {
        pointer-events: none;
    }
}
