.login-card {
    max-width: 655px;
    z-index: 2;

    border-radius: $radius-5;
    width: calc(100% - 1rem);
    margin: 0 auto;

    &-home {
        display: inline-flex;
        flex-direction: column;
        position: relative;
        margin: 12rem auto 5rem;
        background-color: $color-white;
        box-shadow: $box-shadow-6;

        @media (min-width: $mq-m) {
            margin: 11rem auto 5rem 0;
            top: 0;
            left: 3rem;
            position: relative;
            background-color: transparent;
            box-shadow: none;
        }

        @media (min-width: $mq-xxl) {
            left: 11rem;
            background-color: transparent;
            box-shadow: none;
        }
    }

    h1 {
        @include ms-respond(font-size,4);
        line-height: 1.3;
        color: $color-blue-dark;
        margin: 1rem 0 0;

        @media (min-width: $mq-xxs) {
            @include ms-respond(font-size,4);
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,6);
        }
    }

    &__text {
        p {
            @include OpenSansRegular;
            @include ms-respond(font-size,1);
            margin: 1rem 0 1.5rem;
            display: block;
        }

        p.login-card__animation-text {
            font-weight: bold;
            margin: 0;

            + .button {
                margin: 1rem 0;
            }
        }
    }

    &__container {
        padding: 1.5em;
    }

    &__title {
        margin-top: 0;
        color: $color-black;
        line-height: 1;
        margin-bottom: 1em;
    }

    &__actions {
        margin: .5rem -.5rem;
        @media (min-width: $mq-m) {
            display: flex;
            justify-content: space-evenly;
            margin: -.5rem;
        }

        .digid,
        .eidas-cta {
            flex-grow: 1;
        }
    }

    &__actions.columns {
        @media (min-width: $mq-m) {
            flex-direction: column;
        }

        .action {
            flex-grow: 1;
        }

        .eidas-cta {
            display: flex;
        }
    }

    .digid__logo::before,
    .eidas-cta__link::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: block;
        z-index: 1;
    }

    .action {
        background-color: $color-white;
        border: 1px solid $color-blue-dark-thin;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 1rem;
        border-radius: .8rem;
        position: relative;
        margin: .5rem;

        svg {
            height: 3.5rem;
            width: 3.5rem;
            margin-right: 1rem;
            flex-shrink: 0;
        }

        &:hover {
            background-color: $color-grey-light-row;
            box-shadow: inset 0 0 0 1px  $color-blue-dark;
        }

        &:active {
            background-color: $color-white;
            box-shadow: inset 0 0 0 1px  $color-blue-dark;
            border: 1px solid $color-grey-light;
        }
    }

    .cta--disabled .action {
        background-color: $color-grey-light;
        border: 1px solid $color-coal-80;
        cursor: not-allowed;

        a {
            color: $color-coal-80;
        }
    }

    a {
        @include ms-respond(font-size,2);
        display: flex;
        font-weight: 400;
        text-decoration: none;
        color: $color-blue-dark;
        align-items: center;
        text-align: left;

        &:hover {
            color: $color-black;
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
        }
    }

    .button--disabled {
        .action {
            background: $color-grey-light;
            border: 1px solid $color-coal-80;

            a {
                cursor: not-allowed;
                color: $color-coal-80;
                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }
}
