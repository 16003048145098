.form-autocomplete {
    position: relative;
    padding: 0;

    @media (min-width: $mq-m) {
        max-width: 58rem;
        margin: 0 auto;
        flex-grow: 1;
        width: 100%;
    }

    &__input-container {
        padding: 1rem .7rem;
        background-color: $color-white;
        border-radius: $radius-5;
        display: flex;
        flex-wrap: nowrap;

        [data-search-active='true'] & {
            box-shadow: $box-shadow-9;
        }

        .faq-search--page & {
            border: 1px solid $color-coal-80;
        }

        .faq-search--section [data-is-focused] & {
            border: 1px solid $color-blue-dark;

        }

        .page--contact .faq-search--section & {
            box-shadow: $box-shadow-9;
            border: none;
        }
    }

    &__input {
        @include ms-respond(font-size,2);
        flex-grow: 1;
        outline: 0;
        border: 0 none;
        padding: 0;
        background: transparent;
        width: 100%;

        &::-ms-clear {
            display: none;
        }
    }

    &__close {
        display: none;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        path {
            fill: $color-black;
        }
    }

    &__submit {
        margin-left: .25rem;

        svg {
            width: 1.1rem;
            height: 1.1rem;
        }

        path {
            fill: $color-black;
        }
    }

    &__options {
        position: absolute;
        z-index: 4;
        top: 100%;
        overflow: auto;
        width: 100%;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        background-color: $color-white;
        border-bottom-left-radius: $radius-5;
        border-bottom-right-radius: $radius-5;
        box-shadow: $box-shadow-8;
    }

    &__links {
        align-items: center;
        display: block;
        height: 3rem;
        line-height: 3rem;
        color: $color-black-thin;
        text-decoration: none;
        border-bottom: 1px solid $color-grey-light;
        padding: 0 .7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        span {
            font-weight: 700;
            color: $color-blue-dark;
            height: 3rem;
            line-height: 3rem;
            pointer-events: none;
        }

        &:hover {
            background-color: $color-grey-light-row;
            color: $color-blue-dark;
        }
    }

    &__options-empty {
        display: block;
        padding: 0 .7rem;
        color: $color-black;
        height: 3rem;
        line-height: 3rem;

        &::before {
            margin-right: .5rem;
        }
    }

    &__options-error {
        display: block;
        padding: 1rem;
        background-color: $color-white;
        color: $color-black;

        &::before {
            transform: rotate(180deg);
            margin-right: .5rem;
        }
    }

    &__cta {
        height: 3rem;
        line-height: 3rem;
        padding: 0 .7rem;

        a {
            color: $color-blue-dark;
            text-decoration: none;
            font-weight: 700;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            &:hover {
                color: $color-black;
            }
        }

        svg {
            width: .7rem;
            height: .7rem;
            margin-left: .25rem;
        }

        path {
            fill: $color-blue-dark;
        }

        [data-empty='true'] &,
        [data-has-content] & {
            display: none;
        }
    }

    &__empty {

        [data-empty='true'] & {
            display: flex;
        }

        [data-error='true'][data-empty='true'] & {
            display: none;
        }
    }

    &__error {

        [data-error='true'] & {
            display: flex;
        }
    }

    &__empty,
    &__error {
        display: none;
        flex-wrap: nowrap;
        align-items: center;
        padding: .5rem .8rem;

        .icon {
            width: 1rem;
            height: 1rem;
            margin-right: .5rem;
        }

        path {
            fill: $color-black;
        }
    }

    &__cta-preview {
        display: none;
        flex-wrap: nowrap;
        align-items: center;
        padding: .5rem .8rem;

        [data-has-content] & {
            display: flex;
        }

        a {
            text-decoration: none;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            font-weight: 700;
        }

        .icon {
            width: .8rem;
            height: .8rem;
            margin-left: .5rem;
        }

        path {
            fill: $color-blue-dark;
        }

        [data-empty='true'] &,
        [data-error='true'] & {
            display: none;
        }
    }

    &[data-has-content] {

        .form-autocomplete__close {
            display: flex;
        }
    }

    &[data-is-open] {
        .form-autocomplete__options {
            max-height: 20rem;
            opacity: 1;
            visibility: visible;
        }

        .form-autocomplete__input-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: $box-shadow-8;

            .layout__outer--faq & {
                border-color: $color-blue-dark;
            }
        }

        @media (min-width: $mq-m) {
            position: relative;
            width: 100%;
            height: auto;
            z-index: 1;
        }
    }
}
