.video-card {
    position: relative;
    background-color: $color-white;

    &[data-section-type='medium'] {
        .video-card__inner {
            padding: 1rem;

            @media (min-width: $mq-m) {
                padding: 1.5rem;
            }
        }
    }

    &__video {
        display: flex;
        flex-direction: column;
    }

    &__text {
        padding-bottom: 1rem;
    }
}
