.scenarios-visual {
    position: relative;
    padding: 0;

    @media (min-width: $mq-m) {
        padding: 3rem 0;
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__content {
        background-color: $color-white;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        box-shadow: $box-shadow-regular;
        margin-top: -1px;
        border-radius: $radius-5;
        overflow: hidden;

        .notification {
            margin-top: 1rem;
            margin-bottom: 0;
        }

        @media (min-width: $mq-m) {
            max-width: $max-width-medium;
            margin: 0 auto;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: .5rem 1rem;
        }

        &::before {
            content: '';
            border-top: 3px solid $color-blue-dark;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            @media (min-width: $mq-m) {
                border-top: 8px solid $color-blue-dark;
            }
        }
    }

    &__content-inner {
        position: relative;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }

        h2 {
            @include ms-respond(font-size,2);
            color: $color-blue-dark;
            margin: .3rem 0 1.1rem;

            @media (min-width: $mq-m) {
                margin: 1rem 0;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 1rem;
        max-width: 26rem;
        margin: 0 auto;

        @media (min-width: $mq-m) {
            max-width: 100%;
            padding: 3rem .5rem 2rem;
        }
    }

    &__description {
        padding: 0 0 1rem;

        @media (min-width: $mq-m) {
            padding: 1rem;
        }
    }

    &__description,
    &__wrapper {
        position: relative;
        width: 100%;

        @media (min-width: $mq-m) {
            width: 50%;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &__description-list {
        margin: 0;
        padding: 0;
    }

    &__description-item {
        list-style-type: none;
        position: relative;

        &:last-child {
            border-bottom: 1px solid $color-coal-60;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            left: -.5rem;
            top: 3px;
            width: calc(100% + 1rem);
            height: calc(100% - 6px);
            border-radius: .5rem;
            opacity: 0;
            background-color: $color-grey-primary;
            transition: opacity $transition-time-regular $default-transition-easing;
        }

        &[data-active='true'],
        &[data-hover='true'] {
            &::before {
                opacity: 1;
            }
        }

        &[data-active='true'][data-hover='false'] {
            &::before {
                opacity: 0;
            }
        }
    }

    &__description-amount {
        margin-left: auto;

        @media (min-width: $mq-m) {
            @include visuallyhidden;
        }
    }

    &__description-heading {
        @include OpenSansRegular;
        @include ms-respond(font-size,1);
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 1rem 0;
        border-top: 1px solid $color-coal-60;
        color: $color-black;
        position: relative;
        z-index: 2;

        [data-active='true'] & {
            border-top: 1px solid $color-coal-80;
            color: $color-blue-dark;
        }

        svg {
            width: 1.3rem;
            height: 1.3rem;
            margin-left: -.4rem;
            margin-right: .3rem;

            path {
                fill: $color-black;
            }

            [data-active='true'] & {
                transform: rotate(90deg);
                path {
                    fill: $color-blue-dark;
                }
            }
        }
    }

    &__text-wrapper {
        padding: 0 .5rem 1rem 1.2rem;
        margin-top: -.3rem;
    }

    &__description-text {
        @include OpenSansRegular;
        @include ms-respond(font-size,1);
        position: relative;
        z-index: 2;
        max-height: 0;
        overflow: hidden;
        transition: max-height $transition-time-regular $default-transition-easing;

        [data-active='true'] & {
            max-height: 100vh;
        }
    }

    &__visual {
        flex-shrink: 0;
        width: calc(100% - 9rem);
        margin: 0 -1rem -1.5rem;

        @media (min-width: $mq-xs) {
            width: calc(100% - 12rem);
        }

        @media (min-width: $mq-l) {
            margin: 0 auto;
            width: calc(100% - 19rem);
        }

        .mobile {

            @media (min-width: $mq-l) {
                display: none;
            }
        }

        .desktop {
            display: none;

            @media (min-width: $mq-l) {
                display: block;
            }
        }
    }

    &__svg {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 1rem;

        path {
            fill: $color-blue-light;
            transition:
                fill $transition-time-regular $default-transition-easing,
                opacity $transition-time-regular $default-transition-easing;

            &[data-active='true'] {
                fill: $color-blue-dark;
            }

            &[data-active='false'][data-hover='true'] {
                opacity: .8;
                fill: $color-blue-dark-80;
            }
        }

        @media (min-width: $mq-l) {
            margin-top: -1rem;
        }
    }

    &__labels-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__label {
        @include OpenSansRegular;
        cursor: pointer;
        font-size: 12px;
        border-radius: 100%;
        border: 2px dotted $color-blue-arrow;
        width: calc(6rem + 2px);
        height: calc(6rem + 2px);
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: .8rem;
        line-height: 1.2;
        transition:
            background-color $transition-time-regular .1s $default-transition-easing,
            transform $transition-time-regular .1s $default-transition-easing,
            color $transition-time-regular .1s $default-transition-easing,
            border $transition-time-regular .1s $default-transition-easing;

        @media (min-width: $mq-xs) {
            width: calc(7.5rem + 2px);
            height: calc(7.5rem + 2px);
            font-size: 13px;
            line-height: 1.4;
        }

        @media (min-width: $mq-m) {
            font-size: 14px;
        }

        @media (min-width: $mq-l) {
            width: calc(8rem + 2px);
            height: calc(8rem + 2px);
            padding: 1rem;
        }

        span {
            display: block;
        }

        &[data-scenario='expected'] {
            margin: 0 auto;
        }

        &[data-scenario='none'] {
            border: 1px solid $color-blue-arrow;
            margin: 1.5rem auto 0;

            .scenarios-visual__label-title span {
                display: none;

                &.mobile {
                    display: block;
                }
            }

            @media (min-width: $mq-l) {
                margin: -.2rem auto 0;

                .scenarios-visual__label-title span {
                    display: block;
                }
            }
        }

        &[data-scenario='optimistic'] {
            margin-top: -4rem;
            margin-right: -.5rem;
            @media (min-width: $mq-l) {
                margin-right: 1rem;
                margin-top: -4rem;
            }
        }

        &[data-scenario='pessimistic'] {
            margin-top: -4rem;
            margin-left: -.5rem;
            @media (min-width: $mq-l) {
                margin-left: 1rem;
                margin-top: -4rem;
            }
        }

        &[data-active='true'] {
            background-color: $color-blue-dark;
            border: 1px solid $color-blue-dark;
            color: $color-white;
        }

        &[data-active='false'][data-hover='true'] {
            background-color: $color-grey-light-row;
            color: $color-black;

            @media (min-width: $mq-m) {
                transform: scale3d(1.05, 1.05, 1.05);
            }
        }
    }

    &__label-amount {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-top: .2rem;
    }

    &__label-title {
        max-width: 100%;
    }

    &__currency {
        font-size: 11px;
        margin-right: .2rem;
        position: relative;
        top: .2rem;

        @media (min-width: $mq-xs) {
            font-size: 12px;
        }
    }

    &__amount {
        font-weight: 700;
        font-size: 16px;

        @media (min-width: $mq-xs) {
            font-size: 17px;
        }

        @media (min-width: $mq-l) {
            font-size: 18px;
        }
    }

    &__netto {
        display: block;
    }
}
