.user-context {

    $spacing: .6em;

    padding: 0 0 .3em;
    @media (min-width: $mq-m) {
        padding-bottom: .5em;
    }

    &__group {
        margin: 0;
        padding: 0;
        border: none;

        &[disabled] .user-context__row {
            opacity: .5;
        }

        &--last,
        & + & {
            margin-top: 2em;
        }
    }

    &__row {
        display: flex;
        align-items: flex-start;
        margin: 0;

        &.form__radio:first-of-type {
            margin-top: .5rem;
        }
    }

    &__inline {
        display: inline-block;
        margin-right: $spacing;

        & ~ & {
            margin-left: $spacing;
        }
    }

    &__title {
        @include ms-respond(font-size,1);
        @include OpenSansRegular;
        margin: 0;
        color: $color-blue-dark;

        &-addition {
            font-weight: normal;
        }
    }

    &__input {
        display: inline-block;
        flex-shrink: 0;
        width: 1em;
        height: 1em;
        margin: .3em $spacing 0 0;

        &:focus-visible ~ label {
            outline: 2px solid $color-coal-60;
        }

        @media (min-width: $mq-m) {
            margin-top: .25em;
        }
    }

    .form__field {
        label {
            @include ms-respond(font-size,1);
        }
    }

    &__input--income {
        @include ms-respond(font-size,1);
        box-sizing: border-box;
        margin: -.3em 0 0;
        padding: .5em .3em .5em .8em;
        border: 1px solid $color-grey-dark;
        border-radius: $default-radius-small;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .form__field [type='number'] {
        min-width: 10rem;
    }

    &__label--income {
        display: block;
        margin: 1rem 0;
        font-size: 1rem;

        aside & {
            margin: 0 0 1rem;
        }
    }
}
