.login-animation {
    padding: 1rem 1rem 4rem 1rem;

    &__container {
        @media (min-width: $mq-m) {
            display: flex;
            justify-content: center;
            align-items: center;

            .button {
                margin-left: 1rem;
                flex-grow: 0;
                flex-shrink: 0;
                height: 2.65rem;
            }
        }
    }
}
