.button {
    height: fit-content;

    &[disabled],
    &[disabled='disabled'] {
        cursor: not-allowed;
        background-color: $color-grey-light;
        color: $color-text-disabled;
        border-color: $color-grey-light;

        path {
            fill: $color-text-disabled;
        }
    }

    span {
        pointer-events: none;
    }

    &--primary {
        @include ms-respond(font-size,0);
        cursor: pointer;
        background-color: $color-blue-dark;
        color: $color-white;
        border: 1px solid $color-blue-dark;
        border-radius: 1.5rem;
        padding: .47rem .95rem;
        display: inline-flex;
        flex-wrap: nowrap;
        text-decoration: none;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        font-weight: 700;

        .book {
            width: 1.4rem;
            height: 1.4rem;
        }

        @media (min-width: $mq-xs) {
            font-size: 16px;
        }

        &.button--icon {
            path {
                fill: $color-white;
            }
        }

        &:hover {
            background-color: $color-blue-dark-80;
            border-color: $color-blue-dark-80;
        }

        &:focus {
            outline: 3px solid $color-blue-light;
        }

        &:active {
            background-color: $color-blue-dark;
            border-color: $color-grey-light;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline, 0 0 0 2px  $color-grey-light;
        }

        &[disabled],
        &[disabled='disabled'] {
            color: $color-text-disabled;

            path {
                fill: $color-text-disabled;
            }

            &:hover {
                background-color: $color-grey-light;
                border-color: $color-grey-light;
                color: $color-text-disabled;
            }
        }

    }

    &--secondary {
        @include ms-respond(font-size,0);
        cursor: pointer;
        background-color: $color-white;
        color: $color-blue-dark;
        border: 1px solid $color-night-blue-outline;
        border-radius: 1.5rem;
        padding: .505rem .95rem;
        display: inline-flex;
        flex-wrap: nowrap;
        text-decoration: none;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        font-weight: 700;

        @media (min-width: $mq-xs) {
            font-size: 16px;
        }

        &.button--icon {
            path {
                fill: $color-blue-dark;
            }
        }

        &:hover {
            background-color: $color-grey-light-row;
            border-color: $color-night-blue-outline;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline;
        }

        &:focus {
            outline: 3px solid $color-blue-light;
        }

        &:active {
            background-color: $color-grey-light-row;
            border-color: $color-grey-light;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline, 0 0 0 2px  $color-grey-light;
        }

        &[disabled],
        &[disabled='disabled'] {
            border-color: $color-coal-outline;
            color: $color-coal-80;
            background-color: $color-grey-light;
            cursor: not-allowed;

            path {
                fill: $color-coal-80;
            }

            &:hover {
                box-shadow: none;
                border-color: $color-coal-outline;
                color: $color-coal-80;
                background-color: $color-grey-light;
            }
        }
    }

    &--diapositive {
        @include ms-respond(font-size,0);
        cursor: pointer;
        background-color: transparent;
        color: $color-white;
        border: 1px solid $color-white;
        border-radius: 1.5rem;
        padding: .47rem .95rem;
        display: inline-flex;
        flex-wrap: nowrap;
        text-decoration: none;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        font-weight: 700;

        @media (min-width: $mq-xs) {
            font-size: 16px;
        }

        &.button--icon {
            path {
                fill: $color-white;
            }
        }

        &:hover {
            background-color: $color-blue-dark;
            border-color: $color-grey-light;
        }

        &:focus {
            outline: 3px solid $color-blue-light;
        }

        &[disabled],
        &[disabled='disabled'] {
            path {
                fill: $color-text-disabled;
            }

            &:hover {
                background-color: $color-grey-light;
                border-color: $color-blue-dark-80;
            }
        }
    }

    &--simple {
        @include ms-respond(font-size,0);
        cursor: pointer;
        background-color: transparent;
        color: $color-blue-dark;
        border: 3px solid transparent;
        border-radius: 1.5rem;
        padding: .47rem .95rem;
        display: inline-flex;
        flex-wrap: nowrap;
        text-decoration: none;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        font-weight: 700;

        @media (min-width: $mq-xs) {
            font-size: 16px;
        }

        &:hover {
            background-color: $color-grey-light;
            border-color: $color-grey-light;
        }

        &:active {
            background-color: $color-white;
            border-color: $color-grey-light;
            box-shadow: inset 0 0 0 1px  $color-night-blue-outline;
        }

        &:focus {
            border: 3px solid $color-grey-light;
        }

        &[disabled],
        &[disabled='disabled'] {
            path {
                fill: $color-text-disabled;
            }

            &:hover {
                background-color: $color-grey-light;
                border-color: $color-grey-light;
            }
        }

    }

    &--transparent {
        @include ms-respond(font-size,0);
        cursor: pointer;
        background-color: transparent;
        color: $color-black;
        border: none;
        border-radius: 1.5rem;
        padding: 0;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: min-content;
        max-width: max-content;
        align-items: center;
        text-decoration: underline;

        svg {
            display: none;
        }

        path {
            fill: $color-blue-dark;
        }

        @media (min-width: $mq-xs) {
            font-size: 16px;
        }

        &:hover {
            color: $color-blue-dark;

            svg {
                display: inline-block;
            }
        }

        &:focus-visible {
            outline: 3px solid $color-blue-light;
        }

        &[disabled],
        &[disabled='disabled'] {
            path {
                fill: $color-text-disabled;
            }

            &:hover {
                background-color: $color-grey-light;
                border-color: $color-grey-light;
            }
        }

    }

    &--icon {
        align-items: center;
        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    &--icon-right {
        svg {
            margin-left: .5rem;
        }
    }

    &--icon-left {
        svg {
            margin-right: .5rem;
        }
    }

    &--icon-only {
        @include button;
        background-color: transparent;
        border-radius: 2rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        svg {
            margin: auto;
            pointer-events: none;
        }

        &:hover {
            background-color: $color-grey-light-row;
        }
    }
}
