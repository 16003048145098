*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    &[data-locked] {
        overflow: hidden;
        -webkit-overflow-scrolling: auto;

        body {
            overflow: hidden;
            -webkit-overflow-scrolling: auto;
        }
    }
}

html,
body {
    height: 100%;
}

body {
    background: $color-grey-ui;

    @media (max-width: $mq-m) {
        &[data-menu-open='true'] {
            overflow: hidden;
        }
    }

    &[data-search-active='true'] {
        min-height: $height-100vh;
    }
}

.layout {
    main {
        position: relative;
        z-index: 2;
        padding: 6rem 0 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: opacity $transition-time-long $default-transition-easing;
        min-height: calc(#{$height-100vh} - 5rem);

        @media (min-width: $mq-m) {
            padding: 5.5rem 0 0;
        }

        @media (min-width: $mq-xxl) {
            padding: 6.5rem 0 0;
        }

        &.layout__inner--xs {
            width: 100%;
            max-width: 37rem;
            padding: 8.5rem .5rem 3rem;
            @media (min-width: $mq-m) {
                padding: 12.5rem 1rem 5rem;
            }
        }

        &.layout__inner--s {
            width: 100%;
            max-width: 50rem;
            padding: 8.5rem .5rem 3rem;
            @media (min-width: $mq-m) {
                padding: 12.5rem 1rem 5rem;
            }
        }

        .layout__inner--m,
        [data-section-type="medium"],
        [data-section-type="medium-header"] .section-header {
            width: 100%;
            margin: 0 auto;

            @media (min-width: $mq-sm) {
                max-width: 36rem;
            }

            @media (min-width: $mq-m) {
                max-width: 44rem;
            }
        }

        [data-modal-type='full'] & {
            opacity: 0;
        }
    }

    &__outer {
        display: flex;
        flex-direction: column;
        min-height: $height-100vh;

        &--content-page,
        &--warme-page,
        &--about-page {
            background-color: $color-white;
        }

        &--logout-page {
            background-color: $color-white;

            main {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        [data-modal-type='full'] & {
            pointer-events: none;
            position: relative;
            z-index: 7;
            background-color: transparent;
        }
    }

    &__inner {
        position: relative;
        max-width: calc(#{$mq-xxl} - 4rem);
        margin: 0 auto;
        width: 100%;
        padding: 0 1rem;

        @media (min-width: $mq-m) {
            padding: 0 2rem;

            section & {
                padding: 0 3.5rem;
            }
        }

        @media (min-width: $mq-l) {
            section & {
                padding: 0 4rem;
            }
        }

        @media (min-width: $mq-xxl) {
            padding: 0;

            section & {
                padding: 0 1rem;
                max-width: $max-width-medium;
            }
        }
    }

    &--home {
        main.layout__inner {
            width: 100%;
            padding: 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            min-height: calc($height-100vh - 10rem);

            @media (min-width: $mq-m) and (min-height: $mq-home-height) {
                height: calc($height-100vh - 6rem);
            }
        }
        .layout__outer {
            padding: 0;
        }

        .banner__img {
            left: 80%;
        }

        .banner__container {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: linear-gradient(to right, #eaeff3 40%, #eaeff300 80%); /* Adjust the color stops as needed */
            }
        }
    }

    &--article-page {
        background-color: $color-white;
    }

    &--pensioenuitvoerders {
        .page--inner {
            padding: 0 0 3rem;

            @media (min-width: $mq-m) {
                padding: 0 0 5rem;
            }
        }
    }
}
