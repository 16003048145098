.columns {
    width: 100%;
    padding: 1rem;

    .layout--home & {
        @media (min-width: $mq-m) {
            padding: 0;
            width: 95vw;
            margin: 0 auto 2rem;
        }

        @media (min-width: $mq-xxl) {
            width: 82vw;
        }
    }

    h2 {
        @include ms-respond(font-size, 3);
        color: $color-blue-dark;
        text-align: center;
        margin: 0.5rem auto;

        @media (min-width: $mq-xxl) {
            @include ms-respond(font-size,3);
            margin: 0 auto 1rem;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto 1.5rem auto;
        padding: 0;
        width: 100%;
        max-width: $mq-xl;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
            margin: 0 auto;
            padding-bottom: 2rem;
        }
    }

    &__list-item {
        @include ms-respond(font-size,1);
        margin: 0;
        color: $color-black-thin;
        list-style-type: none;
        margin-bottom: 1rem;

        [data-length='4'] & {

            @media (min-width: $mq-m) {
                width: calc(25% - 1rem);
            }
        }

        [data-length='3'] & {

            @media (min-width: $mq-m) {
                width: calc(33.3% - 1rem);
            }
        }

        [data-length='2'] & {

            @media (min-width: $mq-m) {
                width: calc(50% - 1rem);
            }
        }

        @media (min-width: $mq-xxs) {
            @include ms-respond(font-size,3);
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
            text-align: left;
            list-style-type: none;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;

        svg {
            width: 6rem;
            height: 6rem;
            margin: 0 auto;
        }
    }

    &__text {
        text-align: center;
    }
}
