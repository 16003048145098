.status-message {

    $status-message-spacing: 1.5em;

    &__title {
        box-sizing: border-box;
        margin-bottom: $status-message-spacing;
        padding: .45em 2em .55em .75em;
        border-radius: $default-radius;
        color: $color-white;
        background: $color-primary;
    }

    &__cta {
        margin-top: $status-message-spacing;
    }

}
