.carousel {
    position: relative;
    z-index: 1;
    background-color: $color-white;
    padding-bottom: 3rem;

    &[data-background-color='grey'] {
        background-color: $color-grey-light-section;
    }

    &:not([data-section]) {
        padding-top: 3rem;

        .modal & {
            padding-top: 0;
        }

        .layout--article-page & {
            padding-top: 1rem;
        }
    }

    &[data-layout='full'] {
        &[data-color='pink'] {
            background-color: $color-red;
        }

        &[data-color='blue'] {
            background-color: $color-blue-light;
        }
    }

    h2 {
        @include ms-respond(font-size,6);
        line-height: 1.3;
        color: $color-blue-dark;
        text-align: center;
        max-width: 80vw;
        margin: 1rem auto;
    }

    &__inner {
        padding-bottom: 4rem;

        [data-type='slideshow'] & {
            padding-bottom: 0;
        }

        [data-layout='full'] & {
            padding-bottom: 2rem;
        }
    }

    &__window {
        position: relative;

        &::before,
        &::after {
            content: '';
            background-image: $vertical-right-black-strong;
            display: block;
            position: absolute;
            z-index: 2;
            left: 0;
            height: 100%;
            top: 0;
            width: 3rem;
            overflow: hidden;
            opacity: 0;
            transition: opacity $default-transition-time $default-transition-easing;

            @supports (mix-blend-mode: multiply) {
                mix-blend-mode: multiply;
            }

            [data-type='slideshow'] & {
                width: 1rem;
            }

            [data-type='slideshow'][data-color='white'] & {
                top: .5rem;
                height: calc(100% - 1rem);
            }
        }

        &::before {
            transform: rotate(180deg);
        }

        &::after {
            left: auto;
            right: 0;
        }

        [data-infinite='false'][data-start='false'] & {
            &::before {
                opacity: .5;

                @supports (mix-blend-mode: multiply) {
                    opacity: 1;

                    [data-type='slideshow'] & {
                        opacity: .5;
                    }
                }
            }
        }

        [data-infinite='false'][data-end='false'] & {
            &::after {
                opacity: .5;

                @supports (mix-blend-mode: multiply) {
                    opacity: 1;
                }
            }
        }

        [data-infinite='false'][data-start='false'][data-type='slideshow']:not([data-layout='full']) & {
            &::before {
                opacity: .3;

                @supports (mix-blend-mode: multiply) {
                    opacity: .5;
                }
            }
            @media (min-width: $mq-m) {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        [data-infinite='false'][data-end='false'][data-type='slideshow']:not([data-layout='full']) & {
            &::after {
                opacity: .3;

                @supports (mix-blend-mode: multiply) {
                    opacity: .5;
                }
            }
            @media (min-width: $mq-m) {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        [data-infinite='false'][data-type='slideshow'][data-layout='full'] & {
            &::before,
            &::after {
                display: none;
            }
        }

        @media (min-width: $mq-m) {
            &::before,
            &::after {
                content: '';
                height: 22.5rem;
                top: 1.75rem;
            }

            [data-infinite='false'][data-start='false'] & {
                &::before {
                    opacity: 0;

                    @supports (mix-blend-mode: multiply) {
                        opacity: 0;
                    }
                }
            }

            [data-infinite='false'][data-end='false'] & {
                &::after {
                    opacity: 0;

                    @supports (mix-blend-mode: multiply) {
                        opacity: 0;
                    }
                }
            }
        }

        [data-infinite='true'] & {
            &::before,
            &::after {
                opacity: .5;

                @supports (mix-blend-mode: multiply) {
                    opacity: 1;
                }
            }
        }

        [data-type='slideshow'] & {
            @media (min-width: $mq-m) {
                padding: 0 3rem;
                max-width: calc(1116px + 6rem);
                margin: 0 auto;
            }
        }

        [data-layout='full'][data-type='slideshow'] & {
            @media (min-width: $mq-m) {
                padding: 0 3rem;
                max-width: 100%;
                margin: 0;

                [data-modal-type='centered'] .modal & {
                    max-width: 100%;
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    &__slides {
        padding: 0 0 0 1rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        max-width: 100vw;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scrollbar-color: $color-transparent $color-transparent;
        margin: 2rem 0;
        position: relative;
        z-index: 1;
        align-items: center;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-moz-scrollbar {
            display: none;
        }

        scroll-snap-type: x mandatory;

        [data-scrolling='true'] & {
            scroll-snap-type: none;
        }

        [data-infinite='true'] & {
            &[data-hover='false'] {
                scroll-snap-type: none;
            }
        }

        [data-type='slideshow'] & {
            justify-content: flex-start;
            padding: 0;

            @media (min-width: $mq-m) {
                min-height: auto;
            }
        }

        [data-type='slideshow'][data-color='white'] & {
            padding: .5rem 0;
        }

        [data-type='slideshow'][data-layout='full'] & {
            margin-top: 0;

            h2 {
                @include ms-respond(font-size,8);
            }

            p {
                @include UbuntuRegular;
                font-size: 18.66px;
                text-align: center;
                margin-top: 0;
            }

            @media (min-width: $mq-m) {
                max-width: 1116px;
                margin: 0 auto;

                [data-modal-type='centered'] .modal & {
                    max-width: 50rem;
                    width: 100%;
                }

                p {
                    @include ms-respond(font-size,6);
                    text-align: left;
                }

                h2 {
                    @include ms-respond(font-size,11);
                    text-align: left;
                    margin: 1rem 0;

                    [data-modal-type='centered'] .modal & {
                        text-align: center;
                    }
                }
            }
        }

        [data-color='pink'][data-layout='full'] &,
        [data-color='blue'][data-layout='full'] & {
            h2 {
                color: $color-white;
            }
        }

        @media (min-width: $mq-m) {
            min-height: 26rem;
        }
    }

    &__tags,
    &__control-tags {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        span {
            white-space: nowrap;
        }
    }

    &__tag,
    &__control-tag {
        list-style-type: none;
        margin: 0 .25rem .5rem;
    }

    &__illustration {
        max-width: 16rem;
        width: 70%;
        margin: 0 auto;

        img {
            display: block;
            height: auto;
        }

        @media (min-width: $mq-m) {
            max-width: 35%;
            width: 35%;
            margin: 0 0 0 auto;
        }
    }

    &__picture {
        overflow: hidden;

        [data-type='carousel'] & {
            border-radius: $radius-5;
            &::after {
                content: '';
                background-image: $horizontal-bottom-black-strong;
                display: block;
                position: absolute;
                z-index: 2;
                top: auto;
                bottom: 0;
                height: 12rem;
                width: 100%;
                opacity: 1;
                border-radius: $radius-5;
                overflow: hidden;
            }
        }

        [data-type='slideshow'] & {
            border-radius: 100%;
            width: 150%;
            margin-left: -25%;
            position: relative;
            padding-top: 100%;
            margin-top: -26%;

            img {
                width: 75%;
                height: auto;
                display: block;
                left: 15%;
                top: auto;
                bottom: 0;
                position: absolute;
            }

            @media (min-width: $mq-m) {
                padding-top: 70%;

                img {
                    top: 27%;
                    width: 68%;
                    left: 16%;
                }
            }

            @media (min-width: $mq-l) {
                padding-top: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                width: 37%;
                margin-left: 0;
                height: 135%;
                margin-top: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);

                img {
                    width: auto;
                    left: 50%;
                    height: 77%;
                    top: 49%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }
    }

    .slides__copy {
        display: none;
    }

    &__slide-inner {

        [data-type='slideshow'] & {
            overflow: hidden;
            border-radius: $radius-5;

            @media (min-width: $mq-l) {
                box-shadow: $box-shadow-9;
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
            }
        }

        [data-type='slideshow'][data-layout='full'] & {
            border-radius: 0;

            @media (min-width: $mq-m) {
                box-shadow: none;
                display: flex;
                flex-flow: row-reverse nowrap;
                align-items: center;
                padding: 0 2rem;

                [data-modal-type='centered'] .modal & {
                    flex-direction: column;
                }
            }

            @media (min-width: $mq-l) {
                padding: 0;
            }
        }

        [data-color='blue-dark'] & {
            background-color: $color-blue-dark;
        }

        [data-color='white'] & {
            background-color: $color-white;
            box-shadow: $box-shadow-9;
        }
    }

    &__slide {
        list-style-type: none;
        position: relative;
        z-index: 1;
        padding: 0;
        width: 60vw;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 .5rem;
        max-width: 15rem;

        &[data-type='prive'] {
            [data-active-tag='work'] & {
                max-width: 0;
            }
        }

        [data-type='slideshow'] & {
            margin: 0;
            width: calc(100% - 2rem);
            min-width: calc(100% - 2rem);
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 0 .5rem;
            background-color: $color-white;
            min-height: 100%;
            height: 100%;

            &:first-child {
                padding: 0 .5rem 0 1.5rem;
                width: calc(100% - 1rem);
                min-width: calc(100% - 1rem);
            }

            @media (min-width: $mq-m) {
                padding: 1rem;
                width: 100%;
                min-width: 100%;
                max-width: 100%;

                &:first-child {
                    padding: 1rem;
                    width: 100%;
                    min-width: 100%;
                }

                &:last-child {
                    padding: 1rem;
                }
            }

            @media (min-width: $mq-l) {
                flex-flow: row nowrap;
            }
        }

        [data-type='slideshow'][data-color='white'] & {
            overflow: visible;
        }

        [data-type='slideshow'][data-background-color='grey'] & {
            background-color: $color-grey-light-section;
        }

        [data-layout='full'][data-color='pink'] & {
            background-color: $color-red;
        }

        [data-layout='full'][data-color='blue'] & {
            background-color: $color-blue-light;
        }

        [data-type='slideshow'][data-layout='full'] & {
            padding: 0;
            width: 100%;
            min-width: 100%;

            &:first-child {
                padding: 0;
                width: 100%;
                min-width: 100%;
            }
        }

        [data-type='slideshow'][data-direction='reverse'] & {
            @media (min-width: $mq-l) {
                .carousel__slide-inner {
                    flex-flow: row-reverse nowrap;
                }

                .carousel__picture {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 100%;
                    border-bottom-left-radius: 100%;
                }
            }
        }

        [data-hover='true'] & {
            transition: max-width $default-transition-time $default-transition-easing;

            .carousel__picture {
                transition: box-shadow $default-transition-time $default-transition-easing;
            }

        }

        @media (min-width: $mq-m) {
            [data-type='carousel'] & {
                &:hover {
                    max-width: 16rem;

                    .carousel__picture {
                        box-shadow: $box-shadow-carousel;
                    }
                }
            }
        }

        &:last-child {
            [data-infinite='false'][data-type='carousel'] & {
                width: calc(60vw + 1rem);
                max-width: 16rem;
                padding-right: 1rem;

                .carousel__picture::after {
                    width: calc(100% - 1rem);
                }

                &:hover {
                    max-width: 17rem;
                }
            }
        }
        @media (min-width: $mq-m) {
            [data-infinite='false'][data-type='carousel'] & {
                &:first-child {
                    &::before {
                        content: '';
                        background-image: $vertical-right-black-strong;
                        display: block;
                        position: absolute;
                        z-index: 2;
                        top: auto;
                        bottom: 0;
                        left: 0;
                        height: 100%;
                        width: 8rem;
                        opacity: 1;
                        border-radius: $radius-5;
                        overflow: hidden;
                        transform: rotate(180deg);
                    }
                }

                &:last-child {
                    &::before {
                        content: '';
                        background-image: $vertical-right-black-strong;
                        display: block;
                        position: absolute;
                        z-index: 2;
                        top: auto;
                        bottom: 0;
                        left: auto;
                        right: 1rem;
                        height: 100%;
                        width: 8rem;
                        opacity: 1;
                        border-radius: $radius-5;
                        overflow: hidden;
                    }
                    .carousel__picture::after {
                        width: calc(100% - 1rem);
                    }
                }
                &:last-child:first-child {
                    &::before {
                        display: none;
                    }
                    .carousel__picture::after {
                        width: 100%;
                    }
                }
            }
        }

        img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__content {
        padding: 1rem;

        h3 {
            @include ms-respond(font-size,3);
            margin-top: 0;

            [data-textcolor='white'] & {
                color: $color-white;
            }

            [data-textcolor='blue-dark'] & {
                color: $color-blue-dark;
            }

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,6);
                line-height: 1.4;
                margin-bottom: 1rem;
            }
        }

        p {
            [data-textcolor='white'] & {
                color: $color-white;
            }

            [data-textcolor='blue-dark'] & {
                color: $color-blue-dark;
            }
        }

        a:not(.button) {
            [data-textcolor='white'] & {
                color: $color-white;

                &:hover {
                    color: $color-grey-light;
                }
            }

            [data-textcolor='blue-dark'] & {
                color: $color-blue-dark;

                &:hover {
                    color: $color-black;
                }
            }
        }

        .button {
            margin: .5rem 0;
        }

        [data-type='slideshow'] & {
            @media (min-width: $mq-m) {
                padding: 2rem;
            }

            @media (min-width: $mq-l) {
                padding: 2rem 2.5rem 2rem;
            }
        }

        [data-type='slideshow'][data-layout='full'] & {
            display: flex;
            flex-direction: column;

            .button {
                margin: .5rem auto;
            }

            @media (min-width: $mq-m) {
                margin-right: auto;
                padding-left: .2rem;
                max-width: 65%;
                display: flex;
                flex-direction: row nowrap;

                h2,
                .carousel__text {
                    width: 100%;
                    flex-grow: 1;
                }

                .button {
                    margin: .5rem 0;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;

        [data-type='slideshow'] & {
            flex-direction: column;
        }
    }

    &__slide-link {
        @include ms-respond(font-size,2);
        line-height: 1.5;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 1rem;
        color: $color-white;
        font-weight: 700;
        text-decoration: none;

        span {
            display: block;
            max-width: 13rem;
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
        }
    }

    &__control {
        @include button;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        z-index: 3;
        display: none;
        left: 0;
        background-color: $color-white;
        border-radius: 3rem;
        box-shadow: $box-shadow-regular;
        width: 3rem;
        height: 3rem;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: $color-grey-light-row;

            path {
                fill: $color-blue-dark;
            }
        }

        &:active {
            color: $color-coal-60;
            border: 2px solid $color-grey-light;
            background-color: $color-grey-light-row;
        }

        svg {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
        }

        path {
            fill: $color-black;

            [data-layout='full'][data-color='pink'] &,
            [data-layout='full'][data-color='blue'] & {
                fill: $color-white;
            }
        }

        &[data-active='true'] {
            @media (min-width: $mq-m) {
                display: flex;
            }
        }

        &[data-direction='previous'] {
            left: 2rem;

            svg {
                transform: rotate(180deg);
            }

            [data-type='slideshow'] & {
                left: 0;
                box-shadow: none;
                background-color: transparent;
            }

            [data-type='slideshow'][data-start='true'] & {
                display: none;
            }

            [data-layout='full'] & {
                @media (min-width: $mq-m) {
                    left: 1rem;
                }
            }
        }

        &[data-direction='next'] {
            left: auto;
            right: 2rem;

            [data-type='slideshow'] & {
                right: 0;
                box-shadow: none;
                background-color: transparent;
            }

            [data-type='slideshow'][data-end='true'] & {
                display: none;
            }

            [data-layout='full'] & {
                @media (min-width: $mq-m) {
                    right: 1rem;
                }
            }
        }

        [data-type='slideshow'] & {
            display: none;

            @media (min-width: $mq-m) {
                display: flex;
            }
        }
    }

    &__bullet-link {
        width: 1rem;
        height: 1rem;
        display: block;
        position: relative;
        border-radius: 1rem;
        border: 1px solid $color-blue-dark;
        margin: 0 .3rem;
        box-sizing: content-box;
        background-color: $color-blue-dark;
        overflow: hidden;
        mask-image: radial-gradient($color-white, $color-black);

        span {
            pointer-events: none;
        }

        &[data-visible='false'] {
            display: none;
        }

        &[data-active='true'] {
            color: $color-black;
            pointer-events: none;
        }

        &:hover {
            .carousel__pie {
                background-color: $color-purple-pale;
            }
        }

        [data-layout='full'][data-color='pink'] &,
        [data-layout='full'][data-color='blue'] & {
            border: 1px solid $color-white;
            background-color: $color-white;
        }
    }

    &__pie {
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        position: relative;
        border-radius: 1.5rem;
        box-sizing: border-box;
        background-color: $color-white;
        top: -.25rem;
        left: -.25rem;

        [data-layout='full'][data-color='pink'] & {
            background-color: $color-red;
        }

        [data-layout='full'][data-color='blue'] & {
            background-color: $color-blue-light;
        }
    }

    &__bullets {
        width: 100%;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        justify-content: center;
    }

    &__bullet {
        list-style-type: none;
    }

    &__pie-filler,
    &__pie-spinner {
        display: block;
        width: 50%;
        height: 100%;
        transform-origin: 100% 50%;
        position: absolute;
        background: $color-blue-dark;

        [data-layout='full'][data-color='pink'] &,
        [data-layout='full'][data-color='blue'] & {
            background: $color-white;
        }
    }

    &__pie-spinner {
        border-radius: 100% 0 0 100% / 50% 0 0 50%;
        z-index: 200;
        border-right: none;

        [data-active='true'] & {
            animation: rota 4.9s .1s linear forwards;
            animation-play-state: paused;

            [data-modal-type='centered'] & {
                animation: rota 8s .1s linear forwards;
                animation-play-state: paused;
            }
        }
    }

    &[data-status='play'] [data-active='true'],
    &[data-status='resume'] [data-active='true'] {
        .carousel__pie-spinner,
        .carousel__pie-filler,
        .carousel__pie-mask {
            animation-play-state: running;
        }
    }

    [data-modal-type='centered'] & {
        &[data-status='play'] [data-active='true'],
        &[data-status='resume'] [data-active='true'] {
            .carousel__pie-spinner,
            .carousel__pie-filler,
            .carousel__pie-mask {
                animation-play-state: running;
            }
        }
    }

    &__pie-filler {
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        left: 50%;
        opacity: 0;
        z-index: 100;
        border-left: none;

        [data-active='true'] & {
            animation: opa 4.9s .1s steps(1, end) forwards reverse;
            animation-play-state: paused;

            [data-modal-type='centered'] & {
                animation: opa 8s .1s steps(1, end) forwards reverse;
                animation-play-state: paused;
            }
        }
    }

    &__pie-mask {
        display: block;
        width: 50%;
        height: 100%;
        position: absolute;
        background-color: $color-white;
        opacity: 1;
        z-index: 300;

        [data-layout='full'][data-color='pink'] & {
            background-color: $color-red;
        }

        [data-layout='full'][data-color='blue'] & {
            background-color: $color-blue-light;
        }

        [data-active='true'] & {
            animation: opa 4.9s .1s steps(1, end) forwards;
            animation-play-state: paused;

            [data-modal-type='centered'] & {
                animation: opa 8s .1s steps(1, end) forwards;
                animation-play-state: paused;
            }
        }
    }

    [data-modal-type='centered'] .modal & {
        background-color: $color-grey-light-section;
        padding-bottom: 0;

        .carousel__inner {
            padding-bottom: 0;
        }

        .carousel__slides {
            align-items: flex-start;
            padding-bottom: 1rem;
            margin: 0;

            @media (min-width: $mq-m) {
                margin: 0 auto;
            }
        }

        .carousel__control {
            display: block;

            &[data-active='false'] {
                display: none;
            }

            &[data-direction='previous'] {
                left: -.5rem;
            }

            &[data-direction='next'] {
                right: .5rem;
            }

        }

        .carousel__modal-cta {
            display: none;
        }

        .carousel__content {
            padding: 0 1rem;
            margin-left: auto;

            @media (min-width: $mq-m) {
                max-width: 100%;
                padding: 0 1rem;
            }
        }

        .carousel__window {
            z-index: 1;
        }

        .carousel__bullet-link {
            border: 1px solid $color-coal-80;
            background-color: $color-coal-outline;
            color: $color-coal-80;
        }

        .carousel__pie-filler,
        .carousel__pie-spinner {
            background-color: $color-coal-outline;
        }

        .carousel__footer {
            z-index: 2;

            @media (min-width: $mq-m) {
                padding: 0 0 2rem;
            }
        }

        h2 {
            @include ms-respond(font-size,4);
            color: $color-black;
        }

        li {
            list-style-type: none;
        }

        .carousel__slide {
            padding: 2rem 2rem 0;
            background-color: $color-grey-light-section;

            @media (min-width: $mq-s) {
                padding: 2rem;
            }

            @media (min-width: $mq-m) {
                padding: 0;
            }

            p,
            li {
                @include ms-respond(font-size,1);
                margin: 0 0 .5rem;
                width: 100%;
                text-align: center;
            }

            ol,
            ul {
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }

        &[data-end='true'] {
            .carousel__modal-cta {
                display: flex;
                margin: 1rem auto 0;
            }

            .carousel__bullets {
                opacity: 0;
                pointer-events: none;
                display: none;
            }

            [data-direction='next'] {
                display: none;
            }
        }

        .carousel__graphic {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 5rem;

            @media (min-width: $mq-s) {
                min-height: min-content;
                margin: 0 auto 2rem;
            }
        }

        .confettis {
            width: 80%;
            max-width: 20rem;
            margin: 0 auto;

            @media (min-width: $mq-m) {
                width: 100%;
                max-width: 100%;
                min-width: 14rem;
            }
        }

        .menu {
            width: 5rem;
            display: block;
            margin: 0 auto;

            @media (min-width: $mq-m) {
                width: 28rem;
                max-width: 28rem;
            }
        }

        .table {
            width: 42%;
            max-width: 12rem;
            margin: -1rem auto;

            @media (min-width: $mq-m) {
                width: 18rem;
                max-width: 18rem;
            }
        }

        .help {
            width: 78%;
            max-width: 18rem;
            margin: 0 auto;

            @media (min-width: $mq-m) {
                width: auto;
                height: auto;
                margin: 0 auto -1rem;
            }
        }

        [data-desktop] {
            display: none;

            @media (min-width: $mq-m) {
                display: block;
            }
        }

        [data-mobile] {
            @media (min-width: $mq-m) {
                display: none;
            }
        }
    }
}

@keyframes rota {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes opa {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
