form {
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        + label {
            @include ms-respond(font-size,1);
            position: relative;
            cursor: pointer;
            padding: 0;
            height: 30px;
            display: flex;
            align-items: flex-start;
        }

        + label::before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            position: relative;
            top: .1rem;
            width: 1.5rem;
            height: 1.5rem;
            background: $color-white;
            border: 2px solid $color-blue-dark;
            flex-shrink: 0;
            border-radius: 4px;
        }

        &:hover + label::before {
            border: 3px solid darken($color-blue-dark, 10);
        }

        &:focus + label::before {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
        }

        &:checked + label::before {
            border: 2px solid $color-blue-dark;
            background: $color-blue-dark;
        }

        &:focus:checked + label::before {
            border: 2px solid $color-blue-dark;
        }

        &:disabled + label {
            color: $color-grey-dark;
            cursor: auto;
        }

        &:disabled + label::before {
            box-shadow: none;
            background: $color-grey-light;
        }

        &:checked + label::after {
            content: "";
            position: absolute;
            left: .25rem;
            top: .85rem;
            background: $color-white;
            width: .15rem;
            height: .15rem;
            transform: rotate(45deg);
            box-shadow:
                .1rem 0 0 $color-white,
                .2rem 0 0 $color-white,
                .3rem 0 0 $color-white,
                .3rem -.1rem 0 $color-white,
                .3rem -.2rem 0 $color-white,
                .3rem -.3rem 0 $color-white,
                .3rem -.4rem 0 $color-white,
                .3rem -.5rem 0 $color-white,
                .3rem -.6rem 0 $color-white,
                .3rem -.7rem 0 $color-white,
                .3rem -.8rem 0 $color-white;
        }
    }
}
