.faq-search {
    background-color: $color-blue-light;
    padding-bottom: 1rem;
    margin-bottom: -1rem;

    &--section,
    &--page {
        position: relative;
        z-index: 2;
        background-color: $color-white;
        padding-top: 3rem;

        @media (min-width: $mq-m) {
            padding-top: 0;
        }
    }

    [data-modal-type='full'][data-modal-open="true"] & {
        opacity: 0;
    }

    &[data-section] {
        position: relative;
        background-color: $color-blue-pale;

        .layout__inner h2 {
            color: $color-blue-dark;
            margin-bottom: 0;
        }
    }

    @media (min-width: $mq-m) {
        padding: 3rem 0;
        margin-bottom: 0;

        &[data-section] {
            padding: 3rem 0;
        }
    }

    @media (min-width: $mq-l) {

        &[data-section] {
            padding: 5rem 0;
        }
    }

    &__introduction {
        margin: .5rem 0 2rem;

        .page--contact & {
            text-align: center;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        align-items: center;
        justify-content: center;
    }

    &__close-modal {
        @include button;
        padding: .5rem;
        margin-left: auto;
        margin-top: -.5rem;
        margin-right: -.5rem;
        display: none;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        path {
            fill: $color-white;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    &__cta {
        margin: 1.5rem auto;
        &.button--primary {
            min-width: auto;
            max-width: auto;
            width: auto;
            align-self: flex-start;
            position: relative;
        }

        &.focus-visible:focus,
        [data-focus-visible-added],
        input.keyboardFocused {
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid $color-white;
                border-radius: 30px;
            }
        }
    }

    h1 {
        @include ms-respond(font-size,7);
        color: $color-blue-dark;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,11);
        }
    }

    h2 {
        @include ms-respond(font-size,5);
        color: $color-white;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,8);
        }
    }

    &--aside {
        [data-search-active='true'] & {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: $height-100vh;
            z-index: 10;

            @media (min-width: $mq-m) {
                position: relative;
                width: 100%;
                height: auto;
                z-index: 2;
            }

            h2 {
                display: none;

                @media (min-width: $mq-m) {
                    display: block;
                }
            }

            .faq-search__form {
                padding-top: 1rem;

                @media (min-width: $mq-m) {
                    padding-top: 0;
                }
            }

            .faq-search__close-modal {
                display: block;

                @media (min-width: $mq-m) {
                    display: none;
                }
            }
        }
    }
}

[data-search-active='true'] main {
    z-index: 5;

    @media (min-width: $mq-m) {
        z-index: 4;
    }
}
