.steps {
    display: flex;
    flex-direction: column;
    padding: 0 .5rem;
    margin-bottom: 4rem;
    justify-content: center;

    @media (min-width: $mq-m) {
        flex-flow: row nowrap;
        padding-top: 3rem;
    }

    &__intro {
        display: none;
        padding: 0;

        h1 {
            @include ms-respond(font-size,4);
            line-height: 1.5;
            color: $color-blue-dark;
            text-align: center;
            margin: 1rem 0 0;

            @media (min-width: $mq-m) {
                text-align: left;
                @include ms-respond(font-size,8);
                max-width: 22rem;
            }
        }
    }

    &__svg-list {
        position: relative;
        display: none;
        padding: 0;

        @media (min-width: $mq-m) {
            display: block;
            min-width: 24rem;
        }
    }

    &__svg-item {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        list-style-type: none;
        padding-top: 120%;

        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &[data-active-step='true'] {
            opacity: 1;
            position: relative;
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: $radius-5;
        box-shadow: $box-shadow-regular;
        margin-top: 2rem;
        overflow: hidden;
    }

    &__indicator-container {
        display: block;
        height: .5rem;
        position: relative;
        background-color: $color-blue-light;
    }

    &__indicator {
        display: block;
        position: relative;
        left: -100%;
        height: .5rem;
        width: calc(100% - 1rem);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: $color-blue-dark;
        transform-origin: left center;
        transition: transform $transition-time-regular $transition-time-regular $default-transition-easing;

        [data-initialized='true'] & {
            transform: translate3d(50%, 0, 0);
        }

        [data-current-step='1'] & {
            transform: translate3d(100%, 0, 0);
        }

        [data-current-step='2'] & {
            transform: translate3d(66.6%, 0, 0);
        }
    }

    &__list {
        margin: 0;
        padding: 1rem;

        @media (min-width: $mq-m) {
            padding: 1rem 1.5rem;
        }
    }

    &__list-item {
        list-style-type: none;

        h1,
        h2 {
            @include ms-respond(font-size,5);
            color: $color-blue-dark;
            margin: .2rem 0 1rem;
            line-height: 1.4;

            @media (min-width: $mq-m) {
                margin: 1rem 0 .5rem;
            }
        }
    }

    &__header {
        padding-bottom: 1rem;

        p {
            margin-top: 0;
        }
    }

    &__content {
        @include sectionTransition;

        .notification {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }

    &__missing {
        padding-top: 1rem;
        h3 {
            @include OpenSansBold;
            color: $color-black;
            margin-bottom: .5rem;
        }

        p {
            @include ms-respond(font-size,0);
            margin: 0;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 1rem;

        &[hidden] {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            pointer-events: none;
        }

        .button {
            width: 80%;
            min-width: 80%;
            max-width: 80%;
            justify-content: center;
            margin: 0 auto;
        }

        [data-action='back'],
        .button--cancel {
            margin-bottom: 1rem;
        }

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
            align-items: center;
            padding: 2rem 0 0;

            .button {
                width: auto;
                min-width: 5rem;
                max-width: auto;
                margin-right: 0;
                padding: .5rem 1rem;

                &.button--secondary {
                    margin-left: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__form-actions {
        position: relative;
        margin: 0 auto;
        width: 80%;
        min-width: 80%;
        max-width: 80%;

        .button--primary {
            width: 100%;
            flex-grow: 1;
            min-width: 100%;
            max-width: 100%;
            justify-content: center;
        }

        @media (min-width: $mq-m) {
            width: auto;
            min-width: auto;
            max-width: auto;
            margin-right: 0;
        }

    }

    &__submit-button {
        @include button;
        position: absolute;
        z-index: 2;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover ~ label {
            background-color: $color-blue-dark-80;
            border-color: $color-blue-dark-80;
        }

        &:focus-visible ~ label {
            outline: 2px solid $color-blue-light;
            border-color: $color-blue-dark-80;
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &[disabled] ~ label {
            color: $color-text-disabled;
            background-color: $color-grey-light;
            border-color: $color-grey-light;

            path {
                fill: $color-text-disabled;
            }
        }
    }
}
