.nieuws {
    position: relative;

    &__inner[data-type='overview'] {
        background-color: $color-grey-ui;
    }

    &__title {
        @include ms-respond(font-size,6);
        color: $color-blue-dark;
        text-align: center;
        display: block;
        margin: 0 auto 2rem auto;
    }

    &__card {
        position: relative;
        list-style-type: none;
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        max-width: 44rem;
        padding: 0;
        margin: auto;

        &:hover {
            outline: 2px solid $hover-default-color;
        }

        .nieuws__card__link:focus {
            text-decoration: underline;
        }

        &:focus-within {
            outline: $focus-default-outline;
        }

        &:focus-within .nieuws__card__link:focus {
            text-decoration: none;
            outline: none;
        }

        &__link {
            text-decoration: none;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        &__banner {
            width: 100%;
            height: 100%;
            max-height: 300px;

            img {
                width: 100%;
                height: 100%;
                border-radius: $radius-5 $radius-5 0 0;
            }
        }

        &__content {
            margin: 1rem;
            min-height: 5rem;
            flex-basis: 34%;
            order: 1;

            h2 {
                margin: 0;
            }
        }

        &__card + &__card {
            margin-top: 1rem;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        .nieuws__card + .nieuws__card {
            margin-top: 1rem;
        }

        @supports (display: grid) {
            @media (min-width: $mq-m) {
                display: grid;
                align-items: center;
                grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
                grid-gap: 1rem;
                .nieuws__card + .nieuws__card {
                    margin-top: 0;
                }
            }
        }
    }

    &:not([data-section='']) {
        .nieuws__inner {
            padding: 2rem 0;

            @media (min-width: $mq-m) {
                padding: 4rem 0;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
    }
}
