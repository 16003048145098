.login-card-popup {
    &__container {
        display: flex;
        justify-content: center;
        padding: 1rem 1.5rem;
    }

    &__content {
        padding: 1rem 1.5rem;
        margin-bottom: 2rem;
    }
}
