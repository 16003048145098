.search-button {
    button {
        @include button;
        width: 2rem;
        height: 2rem;
        padding: 0;
        position: relative;
        margin-right: 1rem;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        top: .25rem;
        position: relative;

        use {
            fill: $color-black;
        }
    }
}
