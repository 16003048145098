.sticky-menu {
    position: fixed;
    left: auto;
    right: 1rem;
    bottom: 1rem;
    z-index: 4;
    padding: 0;
    background-color: $color-white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    box-shadow: $box-shadow-6;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition:
        opacity $transition-time-long $default-transition-easing,
        transform $transition-time-long $default-transition-easing;

    [data-scrolled='true'] {
        transform: translate3d(0, -5rem, 0);
    }

    [data-modal-type='centered'] & {
        display: none;
    }

    @supports (position: sticky) {
        right: auto;
        bottom: 0;
        position: sticky;

        &[data-bottom='true'] {
            transform: none;
        }

        [data-scrolled='bottom'] & {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            height: 0;
            transform: translate3d(0, 1rem, 0);

            @media (min-width: $mq-m) {
                opacity: 1;
                visibility: visible;
                height: auto;
                pointer-events: all;
                transform: none;
            }
        }
    }

    @media (min-width: $mq-m) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: transparent;
        margin-top: -3.5rem;
        width: auto;
        max-width: max-content;
        padding: 0 1rem 1rem 0;
        margin-left: auto;
        box-shadow: none;
    }

    [data-modal-type='full'] & {
        opacity: 0;
        visibility: hidden;
    }

    &__header {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 1rem;

        [data-active='true'] & {
            padding: .9rem 1rem;
        }

        @media (min-width: $mq-m) {
            padding: 0;
            flex-direction: column;

            [data-active='true'] & {
                padding: 0;
                border-bottom: none;
            }
        }
    }

    &__button {
        @include button;
        background-color: $color-white;
        color: $color-blue-dark;
        border: 1px solid $color-blue-dark-80;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 1.5rem;
        padding: 0 .95rem;
        position: relative;
        z-index: 3;
        cursor: pointer;
        font-weight: 700;
        margin-right: .25rem;
        height: 2.8rem;
        line-height: 1.3;

        .logged-out & {
            flex-grow: 1;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .3rem;
        }

        path {
            fill: $color-blue-dark;
        }

        span {
            @include ms-respond(font-size,0);
            pointer-events: none;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size,1);
            }

        }

        &[aria-expanded='true'] {
            @include ms-respond(font-size,2);
            padding: 0;
            border: none;

            .logged-out & {
                justify-content: flex-start;
            }

            svg {
                display: none;
            }

            span {
                @include ms-respond(font-size,1);
            }
        }

        &.focus-visible:focus,
        [data-focus-visible-added],
        input.keyboardFocused {
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid $color-white;
                border-radius: 30px;
            }
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
            padding: .5rem;
            background-color: $color-black;
            color: $color-white;
            border: none;
            margin-left: 0;
            margin-right: 1rem;
            height: auto;

            path {
                fill: $color-white;
            }

            &:hover {
                background-color: $color-coal-80;
            }

            span {
                @media (max-width: $mq-l) {
                    @include visuallyhidden;
                }
            }

            svg {
                margin-right: 0;
            }

            &[aria-expanded='true'] {
                @include ms-respond(font-size,1);
                padding: .5rem;

                svg {
                    display: block;
                }
            }
        }

        @media (min-width: $mq-l) {
            padding: .5rem .95rem;
            margin-right: 0;

            span {
                @include visible;
            }

            svg {
                margin-right: .3rem;
            }

            &[aria-expanded='true'] {
                padding: .5rem .95rem;
            }
        }
    }

    &__download-button {
        @include button;
        @include ms-respond(font-size,0);
        background-color: $color-blue-dark;
        color: $color-white;
        border: 1px solid $color-blue-dark;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        border-radius: 1.5rem;
        padding: 0 .95rem;
        position: relative;
        z-index: 3;
        width: 50%;
        flex-grow: 1;
        cursor: pointer;
        font-weight: 700;
        margin-left: .25rem;
        text-decoration: none;
        height: 2.8rem;
        line-height: 1.3;
        max-width: 16rem;

        span {
            flex-grow: 0;
            pointer-events: none;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .3rem;
        }

        path {
            fill: $color-white;
        }

        &[disabled],
        &[disabled='disabled'] {
            cursor: not-allowed;

            path {
                fill: $color-text-disabled;
            }

            &:hover {
                background-color: $color-grey-light;
                border-color: $color-grey-light;
            }
        }

        [data-active='true'] & {
            display: none;
        }

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,1);
            padding: .5rem .95rem;
            display: none;

            [data-active='true'] & {
                display: none;
            }
        }

    }

    &__close {
        @include button;
        display: none;
        margin-right: -.5rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        path {
            fill: $color-blue-dark;
        }

        [data-active='true'] & {
            display: block;
            margin-left: auto;
        }

        @media (min-width: $mq-m) {
            [data-active='true'] & {
                display: none;
            }
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        position: relative;
        opacity: 0;
        visibility: hidden;
        top: 0;

        > svg {
            display: none;
        }

        [data-active='true'] & {
            max-height: 7.5rem;
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, -.5rem, 0);
            border-top: 1px solid $color-grey-light;
            transition:
                opacity $transition-time-regular $transition-time-regular $default-transition-easing,
                max-height $transition-time-regular $default-transition-easing,
                transform $transition-time-regular $transition-time-regular $default-transition-easing;

            @media (min-width: $mq-m) {
                border-top: none;
            }
        }

        [data-active='false'] & {
            pointer-events: none;
        }

        @media (min-width: $mq-m) {
            max-height: 7.5rem;
            position: absolute;
            right: 1rem;
            top: -6.2rem;
            margin: 0;
            padding: 1.5rem 2rem 2rem;
            white-space: nowrap;
            transition:
                opacity $transition-time-regular $default-transition-easing,
                transform $transition-time-regular $default-transition-easing;

        }

        @media (min-width: $mq-l) {
            right: .7rem;
            top: -6.7rem;
        }
    }

    &__background {
        position: absolute;
        left: .5rem;
        top: -1rem;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        z-index: 1;

        @supports (position: sticky) {
            top: .5rem;
        }

        &::before {
            content: '';
            position: relative;
            display: block;
            width: 100%;
            height: calc(100% - 1rem);
            background-color: $color-white;
            border-radius: 1rem;
            box-shadow: 0 0 6px rgba(0, 0, 0, .25);

            @supports (position: sticky) {
                box-shadow: none;
                overflow: hidden;
            }
        }

        svg {
            position: absolute;
            width: 1.5rem;
            bottom: -.5rem;
            left: auto;
            right: 1rem;
            z-index: 1;

            path {
                fill: $color-white;
            }

            @media (min-width: $mq-l) {
                right: 2rem;
            }
        }
    }

    &__list-text {
        @media (min-width: $mq-m) {
            margin-right: .5rem;
        }
    }

    &__list-item {
        list-style-type: none;
        position: relative;
        z-index: 2;

        a {
            @include ms-respond(font-size,1);
            font-weight: 700;
            color: $color-black;
            display: flex;
            flex-wrap: nowrap;
            min-width: 12.5rem;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none;
            padding: 1rem;
            border-bottom: 1px solid $color-grey-light;

            @media (min-width: $mq-m) {
                padding: 0;
                border-bottom: none;
                margin-bottom: .3rem;

                &[data-hide][aria-hidden='true'] {
                    display: flex;
                }
            }

            @media (min-width: $mq-l) {
                min-width: 13rem;
            }

        }

        svg {
            width: 1rem;
            height: 1rem;
            margin-left: auto;
        }

        path {
            fill: $color-black;
        }

    }
}
