.section-providers {
    position: relative;
    background-color: $color-white;
    padding: 3rem 1rem;

    @media (min-width: $mq-m) {
        padding: 5rem 0;
    }

    h2 {
        @include ms-respond(font-size,5);
        color: $color-blue-dark;
        text-align: center;
        margin: 0 0 .5rem;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,8);
            margin: 0 auto .5rem;
        }
    }

    &__list {
        margin: 0 auto;
        padding: 3rem 0 2rem;
        max-width: 33rem;
    }

    &__list-item {
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid $color-coal-outline;
    }

    &__button {
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "provider-puv-label provider-image"
            "provider-modal-label provider-image";
        width: 100%;
        margin: 0;
        padding: 0;
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
    }

    .section-providers__puv-label {
        grid-area: provider-puv-label;
        font-weight: bold;
    }

    .section-providers__modal-label {
        grid-area: provider-modal-label;
        text-decoration: underline;
    }

    .section-providers__puv-label,
    .section-providers__modal-label {
        justify-self: start;
    }

    .section-providers__puv-label,
    .section-providers__modal-label,
    .section-providers__logo {
        pointer-events: none;
        z-index: -1;
    }

    .section-providers__logo {
        grid-area: provider-image;
        justify-self: end;
        max-width: 6rem;
    }

    &__footer {
        a {
            margin-top: 1rem;
        }

        &--no-text {
            padding-top: 1rem;
        }
    }

    &__introduction,
    &__header,
    &__footer {
        text-align: center;
        margin: 0 auto;
    }

    &[data-type='page'] {
        padding: 1rem;
        margin-bottom: 2rem;
        box-shadow: $box-shadow-regular;
        border-radius: $radius-5;

        h2 {
            @include ms-respond(font-size,2);
            color: $color-black;
            text-align: left;
            margin: 0 0 1rem;
            border-bottom: 1px solid $color-black;
            width: 100%;
            max-width: 100%;
            padding-bottom: 1rem;
        }

        .section-providers__list {
            max-width: 100%;
            padding: 0;
        }

        .section-providers__list-item {
            padding-bottom: 1rem;
        }

        .section-providers__footer p {
            @include ms-respond(font-size,0);
            width: 100%;
            max-width: 100%;
            text-align: left;
        }

    }
}
