.offgrid-section {
    padding: 1rem 1rem 0;

    &__inner {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
            margin: 0 0 2rem 0;
        }

        @media (min-width: $mq-l) {
            margin: 0 0 2rem -2rem;
        }
    }

    img {
        width: 60%;
        max-width: 15rem;
        margin: 0 auto;

        @media (min-width: $mq-m) {
            margin-left: 0;
        }
    }

    h3 {
        margin-bottom: .5rem;
    }

    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &__text {
        @media (min-width: $mq-m) {
            max-width: 60%;
            padding-left: 1rem;
        }
    }
}
