.timeline-modal {

    &__content {
        @include OpenSansRegular;
        display: flex;
        flex-direction: column;

        > div {
            width: 100%;
        }
    }

    h3 {
        @include ms-respond(font-size,0);
        margin: 0;
        font-weight: 400;
    }

    &__content-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0;

        img {
            width: 5rem;
            height: auto;
            display: block;
            margin-right: 1rem;
        }

        h3 {
            font-weight: 700;
            margin: 1rem 0 0;
        }

        h4 {
            font-weight: normal;
            margin: 0;
        }
    }

    &__button {
        svg {
            width: 1.7rem;
            height: 1.7rem;
            margin-right: .3rem;
        }
    }

    &__address {
        margin-bottom: 1rem;
    }

    &__address-details {
        display: flex;
        flex-direction: column;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-left: -.3rem;
            margin-right: .2rem;

            &.link {
                width: .9rem;
                height: .9rem;
                margin-left: 0;
                margin-right: .5rem;
            }

            path {
                fill: $color-black;
            }
        }

        .icon-email,
        .icon-website {
            height: 17px;
        }

        a {
            display: flex;
            width: 100%;
            color: $color-black;
            align-items: center;

            &:hover {
                color: $color-blue-dark;
            }
        }
    }

    &__relations {
        margin-bottom: 0;

        h4 {
            margin-bottom: 0;
        }
    }

    &__expiring {
        margin-bottom: 1rem;
    }

    &__footer {
        margin-top: 1.5rem;
        border-top: 1px solid $color-grey-light;

        h4.first {
            font-weight: normal;
        }

        h4 {
            margin-bottom: .5rem;
        }

        p {
            @include ms-respond(font-size,0);
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: .5rem;
        }

        a {
            color: $color-black;

            &:hover {
                color: $color-blue-dark;
            }
        }
    }
}
