.section-scroll {
    margin-top: -19px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;

    &[data-move] {
        .down {
            animation: moveDown 1.2s .5s linear forwards;
        }
    }

    .down {
        width: 1.5rem;
        height: 1.5rem;
        transform: rotate(-90deg);
        transition: transform $transition-time-regular $default-transition-easing;

        path {
            fill: $color-black;
        }
    }

    h2 {
        @include ms-respond(font-size,0);
        width: 100%;
        min-width: 100%;
        margin: 0 auto 1rem;
        text-align: center;
        flex-grow: 1;
        flex-shrink: 0;
        font-weight: 400;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,0);
            margin: 0 auto .75rem;
        }
    }

    &[data-text-color='white'] {
        .down {
            path {
                fill: $color-white;
            }
        }

        h2 {
            color: $color-white;
        }
    }

    &[data-text-color='black'] {
        .down {
            path {
                fill: $color-black;
            }
        }

        h2 {
            color: $color-black;
        }
    }

    &[data-text-color='blue'] {
        .down {
            path {
                fill: $color-blue-dark;
            }
        }

        h2 {
            color: $color-blue-dark;
        }
    }

    .button--arrow-down {
        height: 1rem;
        display: block;
        margin: 0 auto;
        flex-grow: 0;
        flex-shrink: 0;

        &:hover {
            .down {
                animation: moveDown 1.2s .5s linear forwards;
            }
        }

    }

    &__content {
        position: relative;
        z-index: 2;
        top: -40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mask {
        width: 80px;
        height: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        position: absolute;
        top: -42px;
        left: calc(50% - 40px);
        box-sizing: content-box;

        path {
            fill: $color-grey-light-section;
            transform: translate(0, -2px);

            [data-color='blue'] & {
                fill: $color-blue-light;
            }

            [data-color='pink'] & {
                fill: $color-red;
            }

            [data-color='white'] & {
                fill: $color-white;
            }

            [data-color='blue-pale'] & {
                fill: $color-blue-pale;
            }
        }
    }

    &[data-color='blue'] {
        .mask {
            border-bottom: 1px solid $color-blue-light;

            path {
                fill: $color-blue-light;
            }
        }
    }

    &[data-color='pink'] {
        .mask {
            border-bottom: 1px solid $color-red;

            path {
                fill: $color-red;
            }
        }
    }

    &[data-color='white'] {
        .mask {
            border-bottom: 1px solid $color-white;

            path {
                fill: $color-white;
            }
        }
    }

    &[data-color='dark-blue'] {
        .mask {
            border-bottom: 1px solid $color-blue-dark;

            path {
                fill: $color-blue-dark;
            }
        }
    }

    &[data-color='blue-pale'] {
        .mask {
            border-bottom: 1px solid $color-blue-pale;

            path {
                fill: $color-blue-pale;
            }
        }
    }

    &__background {
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 20px;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            background-color: $color-grey-light-section;
            width: calc(50% - (78px / 2));
            height: 21px;
            display: block;
            flex-grow: 0;
            flex-shrink: 0;
            top: -2px;
            position: relative;

            [data-color='blue'] & {
                background-color: $color-blue-light;
            }

            [data-color='pink'] & {
                background-color: $color-red;
            }

            [data-color='white'] & {
                background-color: $color-white;
            }

            [data-color='dark-blue'] & {
                background-color: $color-blue-dark;
            }

            [data-color='blue-pale'] & {
                background-color: $color-blue-pale;
            }
        }

        &::after {
            margin-left: auto;
        }
    }

    &[data-is-first='true'] {
        &::before {
            content: '';
            position: absolute;
            z-index: 2;
            left: -25vw;
            width: 150vw;
            height: 10rem;
            top: -9.5rem;
            background-color: $color-white;
            border-radius: 100%;
            box-shadow: $box-shadow-vertical;

            [data-color='blue-pale'] & {
                background-color: $color-blue-pale;
            }

            @media (min-width: $mq-s) {
                height: 25rem;
                top: -24.5rem;
            }

            @media (min-width: $mq-m) {
                height: 40rem;
                top: -39.5rem;
            }

        }

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            height: 40rem;
            top: -40rem;
            width: 100%;
            background-color: $color-grey-light-section;

        }

        .section-scroll__background {
            background-color: $color-grey-light-section;

            svg {
                display: none;
            }
        }

        &[data-color='blue-pale'] {
            .section-scroll__background {
                background-color: $color-blue-pale;
            }

            &::after {
                background-color: $color-blue-pale;
            }

            svg {
                display: block;
            }
        }

        &[data-color='blue'] {
            .section-scroll__background {
                background-color: $color-blue-light;
            }

            &::after {
                background-color: $color-blue-light;
            }
        }

        &[data-color='pink'] {
            .section-scroll__background {
                background-color: $color-red;
            }

            &::after {
                background-color: $color-red;
            }
        }

        &[data-color='white'] {
            .section-scroll__background {
                background-color: $color-white;
            }

            &::after {
                background-color: $color-white;
            }
        }

        .button {
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -.5rem;
        }

    }
}

[data-section] {
    padding-top: 2rem;

    &[data-section-id='2'] {
        margin-top: 2rem;
        z-index: 1;
    }

    &:not([data-section-id='2']) {
        padding-bottom: 4rem;
    }
}
