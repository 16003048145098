.pu-list {
    margin: 0;
    padding: 0;

    &__item {
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: 1px solid $color-coal-thin;
        min-height: 4rem;
        padding: .5rem 0;

        @media (min-width: $mq-m) {
            min-height: 5rem;
        }

    }

    &__logo {
        display: none;

        img {
            width: 100%;
            height: auto;
        }

        @media (min-width: $mq-m) {
            display: block;
            width: 33.3%;
            padding: 0 2rem;
        }
    }

    &__pu {
        font-weight: 700;
        width: 65%;
        flex-grow: 1;
        flex-shrink: 0;

        @media (min-width: $mq-m) {
            width: 33.3%;
            flex-grow: 0;
        }
    }

    &__status {
        padding-left: 2.2rem;
        position: relative;
        width: 40%;
        flex-shrink: 1;

        svg {
            position: absolute;
            width: 1.3rem;
            height: 1.3rem;
            top: 50%;
            margin-top: -.7rem;
            left: .5rem;
        }

        &[data-loading]::before {
            content: '';
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 50%;
            margin-top: -.5rem;
            left: .5rem;
            border-radius: 50%;
            border: 2px solid $color-coal-60;
            border-top-color: $color-black;
            animation: spinner .6s linear infinite;
        }

        &[data-failure] {
            path {
                fill: $color-warning;
            }
        }

        &[data-success] {
            path {
                fill: $color-success;
            }
        }

        @media (min-width: $mq-m) {
            width: 33.3%;
        }
    }

    @keyframes spinner {
        to { transform: rotate(360deg); }
    }
}
