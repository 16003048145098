/**
 * mobile-nav styles without JavaScript enabled
 */
.mobile-nav {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    background-color: $color-grey-ui;
    transition: transform $transition-time-regular $default-transition-easing;

    [data-level='2'] &,
    [data-level='3'] & {
        transform: translate3d(-100vw, 0, 0);
    }

    &__list {
        list-style-type: none;
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 1rem;
    }

    &--active {
        font-weight: bold;
    }

    .search-button {
        button {
            border-top: $color-grey-border-light 1px solid;
            padding: 1rem;
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            height: auto;
        }
    }

    .logout-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .8rem;

        use {
            fill: $color-black;
        }
    }

    &__download {
        @include ms-respond(font-size,1);
        background-color: $color-blue-dark;
        color: $color-white;
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 1rem;
        font-weight: 700;
        height: 6.5rem;

        &[data-ajax-link] {
            color: $color-white;
        }

        svg {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: .5rem;
            pointer-events: none;
        }

        path {
            fill: $color-white;
        }

        span {
            pointer-events: none;
        }

        @media (min-width: $mq-m) {
            display: none;
        }
    }

    @media (min-width: $mq-header) {
        display: none;
    }

    .language-selector {
        @media (min-width: $mq-m) {
            display: none;
        }
    }

    &__link {
        @media (min-width: $mq-m) {
            color: $color-black;
            border: none;
            background-color: transparent;
            padding: 0;

            &:hover {
                background-color: transparent;
                border: none;
                box-shadow: none;
                text-decoration: underline;
            }
        }
    }
}

/**
 * mobile-nav styles with JavaScript enabled
 */
// body[data-initialized="true"] .mobile-nav {
// }
