.columns-section {
    position: relative;

    &[data-color='white'] {
        background-color: $color-white;
    }

    &__inner {
        [data-section] & {
            margin-bottom: 2rem;

            @media (min-width: $mq-m) {
                margin-bottom: 4rem;
            }
        }
    }

    h2 {
        @include ms-respond(font-size,6);
        line-height: 1.3;
        color: $color-blue-dark;
        text-align: center;
        max-width: 80vw;
        margin: 1rem auto;
    }

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
            justify-content: space-between;
            padding-top: 2rem;
        }
    }

    &__column {
        list-style-type: none;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            width: 31%;
        }
    }

    &__illustration {
        max-width: 10rem;
        width: 70%;
        margin: 0 auto;

        @media (min-width: $mq-m) {
            max-width: 11rem;
        }
    }

    &__column-content {
        padding-bottom: 2rem;
        text-align: center;

        h3 {
            font-size: 19px;
            line-height: 1.3;
            margin-bottom: 1rem;

            @media (min-width: $mq-m) {
                margin-top: 2rem;
            }
        }

        p:first-of-type {
            margin-top: 0;
        }
    }

    &__footer {
        padding-bottom: 1rem;
        text-align: center;

        p {
            margin: 0 auto;
            display: block;
        }
    }

    &:not([data-section='']) {
        .columns-section__inner {
            padding: 2rem 0;

            @media (min-width: $mq-m) {
                padding: 4rem 0;
            }
        }
    }

}
