.illustrations {
    &--step-1 {
        .cls-2 {
            fill: $color-yellow;
        }
        .cls-4 {
            fill: $color-blue-darker;
        }
        .cls-5,
        .cls-6,
        .cls-8 {
            fill: none;
            stroke-miterlimit: 10;
        }
        .cls-5 {
            stroke: $color-yellow;
            stroke-width: 11px;
        }
        .cls-6 {
            stroke: $color-blue-light;
            stroke-linecap: round;
            stroke-width: 13px;
        }
        .cls-7 {
            fill: $color-blue-light;
        }
        .cls-8 {
            stroke: $color-blue-darker;
            stroke-width: 30px;
        }
        .cls-9 {
            fill: $color-red;
        }
    }

    &--step-2 {
        .cls-1 {
            fill: $color-white;
        }
        .cls-2 {
            fill: $color-yellow;
        }
        .cls-3 {
            fill: $color-yellow-light;
        }
        .cls-4 {
            fill: $color-blue-darker;
        }
        .cls-5,
        .cls-6,
        .cls-8 {
            fill: none;
            stroke-miterlimit: 10;
        }
        .cls-5 {
            stroke: $color-yellow;
            stroke-width: 11px;
        }
        .cls-6 {
            stroke: $color-blue-light;
            stroke-linecap: round;
            stroke-width: 13px;
        }
        .cls-7 {
            fill: $color-blue-light;
        }
        .cls-8 {
            stroke: $color-blue-darker;
            stroke-width: 30px;
        }
        .cls-9 {
            fill: $color-red;
        }
    }

    &--downloads {

        .paper-top {
            position: relative;
            transform: translate3d(0, 2%, 0);
        }

        [data-downloading='true'] & {
            .paper-top {
                animation: .8s infinite alternate paper-slide;
            }
        }
    }
}
