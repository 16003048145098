.notification-section {
    padding: 1rem 1rem 0;

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        padding: 1rem;
        border-radius: $radius-5;
        margin-bottom: 2rem;

        [data-type='info'] & {
            border: 1px solid $color-black;
        }
    }

    svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 1rem;
        flex-shrink: 0;
    }

    path {
        fill: $color-black;
    }

    &__text {
        p:first-of-type {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &[data-type='info'] {
        .info-section__inner {
            border: 1px solid $color-black;
        }
    }

    &[data-section-type='medium'] {
        @media (min-width: $mq-m) {
            padding: 1rem 1.5rem 0;
        }
    }

    &[data-section-type='medium-inner'] {
        @media (min-width: $mq-m) {
            padding: 1rem 2.5rem 0;
        }
    }

}
