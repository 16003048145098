@keyframes paper-slide {
    0% {
        transform: translate3d(0, 2%, 0);
    }

    100% {
        transform: translate3d(0, -3%, 0);
    }
}

@keyframes moveDown {
    0% {
        transform: rotate(-90deg) translate3d(0, 0, 0);
    }

    25% {
        transform: rotate(-90deg) translate3d(4px, 0, 0);
    }

    50% {
        transform: rotate(-90deg) translate3d(0, 0, 0);
    }

    75% {
        transform: rotate(-90deg) translate3d(4px, 0, 0);
    }

    100% {
        transform: rotate(-90deg) translate3d(0, 0, 0);
    }
}
