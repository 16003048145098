.outside-grid-section {
    position: relative;
    width: 100%;
    max-width: calc(#{$max-width-medium} + 2rem);
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    flex-shrink: 0;

    @media (min-width: $mq-m) {
        flex-flow: row nowrap;
        align-items: flex-start;
        align-items: center;
        margin-top: -3rem;
        min-height: calc(100vh - 8rem);
    }

    @media (min-width: $mq-l) {
        margin-top: -4rem;
    }

    &__illustration {
        width: 60%;
        max-width: 15rem;
        flex-shrink: 0;
        padding: 2rem 0;

        @media (min-width: $mq-m) {
            width: 60%;
            max-width: 20rem;
            padding: 0;
        }
    }

    &__warning {
        margin-bottom: 0;
    }

    h1 {
        @include ms-respond(font-size,5);
        color: $color-blue-dark;
        margin: 0 0 .5rem;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,6);
        }
    }

    h2 {
        margin-top: 1.5rem;
    }

    &__content {
        p {
            margin-top: 0;
        }

        @media (min-width: $mq-m) {
            padding-left: 2rem;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;

        a:first-child {
            margin-bottom: .5rem;
        }

        @media (min-width: $mq-m) {
            padding: 1rem 0 0;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            a:first-child {
                margin-bottom: 0;
                margin-right: .5rem;
            }
        }
    }
}
