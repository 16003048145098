.content-page-intro {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .layout__outer--content-page & {
        margin-bottom: 0;

        @media (min-width: $mq-sm) {
            margin-bottom: 2rem;
        }
    }

    @media (min-width: $mq-sm) {
        margin-bottom: 2rem;
    }

    .layout__outer--warme-page & {
        background-color: $color-grey-light-section;
        display: flex;
        flex-direction: column;
    }

    .layout--article-page & {
        margin-bottom: 0;

        @media (min-width: $mq-sm) {
            margin-bottom: 2rem;
        }
    }

    &__inner {
        .layout__outer--warme-page & {
            display: flex;
            flex-direction: column;
            max-width: $max-width-medium;
            margin: 0 auto;
            padding: 5rem 1rem 1rem;

            @media (min-width: $mq-sm) {
                flex-flow: row nowrap;
                align-items: center;
            }

            @media (min-width: $mq-l) {
                padding: 5rem 0 1rem;
            }
        }
    }

    &__illustration {
        max-width: 10rem;
        width: 60%;
        margin: 0 auto 2rem;

        @media (min-width: $mq-sm) {
            margin: 0;
            max-width: 15rem;
        }

    }

    &__banner {
        position: relative;
        z-index: 1;
        display: flex;

        picture {
            flex-grow: 1;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            position: relative;
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        margin: -1rem 0 0;
        background-color: $color-white;
        padding: 1rem;
        border-radius: $radius-5;

        @media (min-width: $mq-sm) {
            margin: -8rem auto 0;
            padding: 1.5rem 1.5rem 1.5rem 3.5rem;
        }

        @media (min-width: $mq-m) {
            padding: 1.5rem 1.5rem 0;
            margin: -10.3rem auto 0;
        }

        .layout__outer--warme-page & {
            background-color: transparent;
            @media (min-width: $mq-sm) {
                margin: 0 auto;
            }

            @media (min-width: $mq-m) {
                margin: 0 auto;
            }
        }
    }

    h1 {
        @include ms-respond(font-size,8);
        line-height: 1.3;
        color: $color-blue-dark;
        margin: 1rem 0;

        @media (min-width: $mq-m) {
            @include ms-respond(font-size,11);
            line-height: 1.2;
        }
    }

    &__introduction {
        padding-bottom: .5rem;
        margin-bottom: 0;
        border-bottom: 1px solid $color-grey-light;

        p {
            font-size: 18px;
            line-height: 1.5;

            @media (min-width: $mq-m) {
                font-size: 20px;
            }
        }

        .layout__outer--warme-page & {
            margin-bottom: 0;
            border-bottom: none;

            p {
                margin-bottom: 0;
            }
        }
    }
}
