// Overrides financial overview styling
.financial-overview.financial-overview--overlijden {
    background-color: $color-white;

    .before-pension,
    .after-pension {
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;

        }
    }

    .after-pension {
        opacity: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;

    }

    &[data-content-flag="self"] {
        [data-self='true'] & {
            .after-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }

            .before-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }
        }

        [data-self='false'] & {
            .after-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }

            .before-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }
        }
    }

    &[data-content-flag="partner"] {
        [data-partner='true'] & {
            .after-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }
            .before-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }
        }

        [data-partner='false'] & {
            .after-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }
            .before-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }
        }
    }

    &[data-content-flag="partner-self"] {
        [data-partner-self='true'] & {
            .after-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }
            .before-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }
        }

        [data-partner-self='false'] & {
            .after-pension {
                opacity: 1;
                position: relative;
                pointer-events: all;
            }
            .before-pension {
                opacity: 0;
                position: absolute;
                width: 100%;
                pointer-events: none;
            }
        }
    }

    [data-period="month"] {
        display: none;
    }

    &[data-monthly='false'],
    &[data-monthly='true'] {
        [data-period="year"] {
            display: inline;
        }

        [data-monthly='true'] {
            [data-period="year"] {
                display: inline;
            }

            [data-period="month"] {
                display: none;
            }
        }
    }

    .login-card {
        margin: 0;
        top: 0;
        position: relative;
        right: auto;
        left: 0;
        box-shadow: none;
    }

    @media (min-width: $mq-m) {
        .financial-overview__content {
            min-height: min-content;
        }
    }
}

.has-js {
    [data-monthly='true'] .financial-overview--overlijden .timeline {
        [data-period='year'] {
            display: inline;
        }
    }
}
