.timeline {
    @include OpenSansRegular;
    padding: 0 1rem;
    position: relative;

    .timeline-provider {
        display: flex;
        flex-wrap: nowrap;
    }

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        [data-type='single'] & {
            padding: 1rem 1rem 1rem 0;
        }

        [data-collapsible] & {
            padding: 1rem 2.5rem 1rem 0;
        }

        h3 {
            @include ms-respond(font-size,2);
            text-align: left;
            align-items: center;
            display: flex;

            [data-length='1'] & {
                color: $color-blue-dark;
            }

            [data-collapsible] & {
                z-index: 2;
                padding: 0;
                left: 0;
                top: -1rem;
            }
        }
    }

    &__provider-label {
        @include button;
        text-align: left;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__label {
        display: none;
        visibility: hidden;

        @media (min-width: $mq-m) {
            display: flex;
            visibility: visible;
        }
    }

    &__short-label {
        display: flex;

        @media (min-width: $mq-m) {
            display: none;
            visibility: hidden;
        }
    }

    &__providers-details-list {
        margin: 0;
        padding: 0;
    }

    &__provider-details {
        padding: 1rem 1rem .5rem 2rem;
        margin-bottom: 0.2rem;
        display: flex;
        flex-wrap: nowrap;

        .timeline__provider-detail__logo {
            padding: 0 0 0 1rem;
            display: flex;
            align-items: center;

            @media (min-width: $mq-m) {
                padding: 0 1rem;
            }
        }

        img {
            width: 100%;
            max-width: 8rem;
            max-height: 100%;
            height: auto;
            margin: 0 auto;
            display: block;
        }

        &:last-child {
            border-bottom: none;
        }

        .icon {
            width: .7rem;
            height: .7rem;
            margin-right: .5rem;
        }

        a {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            color: $color-black;
            white-space: nowrap;

            &:hover {
                color: $color-blue-dark;
            }
        }

        &:hover::before {
            opacity: 1;
        }

        &[data-status="actief"] {
            background-color: $color-white;
            border-radius: .5rem;
            padding-top: 3rem;
        }
    }

    &__provider,
    &__provider-details {
        list-style-type: none;
    }

    &__providers-list {
        margin: 0;
        padding: 0;
        overflow: hidden;
        transition: opacity $transition-time-regular $default-transition-easing;

        [data-is-open='false'] & {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
        }

        .timeline__provider:first-of-type {
            border-top: none;
        }

        [data-length='1'] & {
            overflow: visible;
        }
    }

    &__provider-header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-top: $border-coal-60;
        padding: 0 1.5rem 0 0;
    }

    &__provider-header,
    &__provider-details {
        position: relative;
        z-index: 2;
        &::before {
            content: '';
            pointer-events: none;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            opacity: 0;
            background-color: $color-grey-light-row;
            transition: opacity $transition-time-regular $default-transition-easing;
        }

        &.hover::before {
            opacity: 1;
        }
    }

    &__provider {
        position: relative;
        border-top: $border-coal-60;
        padding: 0;

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            top: 3px;
            width: 100%;
            height: calc(100% - 6px);
            border-radius: .5rem;
            opacity: 0;
            transition: opacity $transition-time-regular $default-transition-easing;
        }

        .timeline__provider-header:first-of-type {
            border-top: none;
        }

        &.timeline__total-row {
            border-top: $border-blue-dark;
        }

        &.timeline__footer-row {
            border-top: none;

        }

    }

    &__content {
        position: relative;
        z-index: 2;
        padding: 0 .5rem;

        h4 {
            margin: 0;
            font-weight: 400;
        }
    }

    &__provider-name {
        @include button;
        @include OpenSansBody;
        text-align: left;
        padding: 1rem;
        margin-right: auto;
        z-index: 2;
        cursor: default;
        display: flex;
        align-items: center;

        &[aria-expanded='true'] {
            color: $color-blue-dark;
        }
    }

    .notification-info {
        width: 1rem;
        height: 1rem;
        margin-left: .5rem;

        path {
            fill: $color-info;
        }
    }

    &__provider-span {
        content: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__provider-price {
        display: flex;
        flex-direction: column;
        text-align: right;
        position: relative;
        z-index: 2;
        margin-left: auto;
        pointer-events: none;

        label {
            @include ms-respond(font-size,-1);
        }

        .timeline__header & {
            span {
                font-weight: 700;
            }
        }

        [data-is-open='true'] [data-is-open='false'] & {
            color: $color-black;
            font-weight: 400;
            display: flex;

            .chevron {
                transition: transform $transition-time-regular $default-transition-easing;
            }

            .timeline__provider-header:hover {
                .chevron {
                    path {
                        fill: $color-blue-dark;
                    }
                }
            }
        }

        [data-is-open='true'] [data-is-open='true'] &,
        [data-is-open='true'] .timeline__provider-header & {
            display: none;
        }

        [data-is-open='true'] [data-is-open='false'] .timeline__provider-header & {
            display: flex;
            color: $color-black;
            font-weight: 400;

            label {
                font-weight: 400;
            }
        }
    }

    &__provider-detail__header {
        button {
            border: none;
            margin: 0;
            padding: 0;
            background-color: transparent;
            text-align: left;
        }

        [data-type='gezamenlijk'] & {
            display: flex;
            align-items: center;
        }
    }

    &__provider-detail__status {

        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 2;

        span {
            background-color: #e0ecf3;
            padding: 0.3rem 1rem;
            border-radius: 1rem;
            color: $color-blue-dark;
        }
    }

    &__provider-detail__header,
    &__provider-detail__logo,
    &__provider-detail__price {
        position: relative;
        z-index: 2;
    }

    &__provider-detail__price {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        text-align: right;
        white-space: nowrap;

        label {
            @include ms-respond(font-size,-1);
        }
    }

    &__provider-total {
        position: relative;
        z-index: 2;
        padding: 1rem 1rem 1rem 0;
        text-align: right;
        display: flex;
        flex-direction: column;
        border: none;
        margin-left: auto;
        align-items: flex-end;
        background-color: transparent;

        label {
            @include ms-respond(font-size,-1);
        }
    }

    &__provider-total-price {
        font-weight: 700;
        color: $color-blue-dark;
    }

    &__provider-footer {
        position: relative;
        z-index: 2;
        padding: .4rem 0;

        span,
        label {
            display: inline;
        }
    }

    &__provider-footer-bold {
        font-weight: 700;
        color: $color-blue-dark;
    }

    &__provider-footer-inner {
        border: $border-blue-dark;
        background-color: $color-white;
        padding: 1rem;
        border-radius: .7rem;
        text-align: center;
        width: 100%;

        [type='nu'] & {
            border: $border-blue-light;
        }
    }

    &__provider-footer-second a {
        display: inline-flex;

        &[disabled='disabled'] {
            pointer-events: not-allowed;
            color: $color-coal-80;
        }
    }

    [data-collapsible] {

        [data-content] {
            overflow: hidden;
        }

        .timeline__provider-header {
            padding: .5rem 1rem .5rem .5rem;

            span {
                cursor: pointer;
            }

            .timeline__provider-name {
                cursor: pointer;
            }

            &:hover::before {
                opacity: 1;
            }

        }

        &.hover {
            .timeline__provider-header::before {
                opacity: 1;
            }
        }

        &[data-is-open='false'] {

            [data-content] {
                max-height: 0;
                visibility: hidden;
            }

            &:hover,
            .move {
                .chevron {
                    transform: $chevron-transform-down;

                    path {
                        fill: $color-blue-dark;
                    }
                }
            }
        }

        &[data-is-open='true'] {

            .timeline__provider-label {
                font-weight: 700;
                color: $color-blue-dark;
            }

            .timeline__provider-header:hover::before {
                opacity: 0;
            }
        }
    }

    [data-collapsible] & {

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            left: -.5rem;
            top: 3px;
            width: calc(100% - .5rem);
            height: calc(100% - 4px);
            border-radius: .5rem;
            opacity: 0;
            background-color: $color-grey-primary;
            transition: opacity $transition-time-regular $default-transition-easing;

            @media (min-width: $mq-m) {
                width: calc(100% + 1rem);
            }
        }

        .timeline__providers {
            position: relative;
            z-index: 2;
        }

        .timeline__header {
            padding: 1rem 1rem 1rem 0;
            cursor: pointer;
            position: relative;
            z-index: 5;

            button {
                &::after {
                    content: '';
                    position: absolute;
                    width: calc(100% + 3rem);
                    top: 0;
                    left: -3rem;
                    height: 100%;
                    display: block;
                }
            }

            &::after {
                content: '';
                display: none;
                pointer-events: none;
                position: absolute;
                z-index: 1;
                left: -.5rem;
                top: 3px;
                width: calc(100% - .5rem);
                height: calc(100% - 6px);
                border-radius: .5rem;
                opacity: 0;
                background-color: $color-grey-light-row;
                transition: opacity $transition-time-regular $default-transition-easing;

                @media (min-width: $mq-m) {
                    display: block;
                    width: calc(100% + 1rem);
                }
            }

            &:hover {
                .timeline__provider-label {
                    color: $color-blue-dark;
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    [data-collapsible][data-is-open='false'] & {

        [data-content] {
            pointer-events: none;
            max-height: 0;
            visibility: hidden;
        }

        .timeline__provider-label {
            font-weight: 400;
        }

        .timeline__header {
            border-bottom: $border-coal-60;

            > .chevron {
                position: relative;
            }

            &:hover,
            &.move {
                > .chevron {
                    transform: $chevron-transform-down;
                }
            }

            @media (min-width: $mq-m) {
                border-bottom: none;
            }
        }
    }

    [data-collapsible][data-is-open='true'] & {

        .timeline__provider-label {
            font-weight: 700;
            color: $color-blue-dark;

            &:hover::before {
                opacity: 0;
            }
        }

        .timeline__header {
            position: relative;
            z-index: 2;
            border-bottom: $border-coal-60;
            min-height: 5rem;
        }

        &::before {
            background-color: $color-grey-primary;
            opacity: 1;
        }

        &:hover {
            &::before {
                background-color: $color-grey-primary;
            }

            .timeline__provider-label {
                color: $color-blue-dark;
            }
        }
    }

    [data-is-open='true'] {

        .timeline__provider-header:hover::before {
            opacity: 0;
        }

        &::before {
            opacity: 1;
        }

    }
}

.has-js {

    [data-monthly='false'] .timeline {

        [data-period='month'] {
            display: none;
        }
    }

    [data-monthly='true'] .timeline {

        [data-period='year'] {
            display: none;
        }
    }
}
