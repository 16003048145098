.footer {
    background-color: $color-blue-light;
    position: relative;
    z-index: 5;

    @media (min-width: $mq-m) {
        z-index: 1;
    }

    &[data-background-color='blue'] {
        background-color: $color-blue-light;
    }

    &[data-background-color='grey'] {
        background-color: $color-grey-ui;
    }

    &[data-background-color='white'] {
        background-color: $color-white;
    }

    &__wrapper {

        background-color: $color-blue-dark;
        position: relative;
        margin-top: auto;
        z-index: 1;
        padding: 1.5rem 0;

        [data-sticky-visible='false'] & {
            border-top-left-radius: .8rem;
            border-top-right-radius: .8rem;
        }

        @media (min-width: $mq-m) {
            padding: 1.5rem 1rem;
            overflow: hidden;
            border-top-left-radius: .8rem;
            border-top-right-radius: .8rem;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        padding: .5rem 1rem;
    }

    [data-modal-type='full'][data-modal-open="true"] & {
        opacity: 0;
    }

    &__main {
        width: calc(100% - 5rem);
    }

    &__links {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            align-content: flex-start;
            max-height: 8rem;
            flex-wrap: wrap;
            margin-bottom: -1rem;

            .footer__item {
                width: 50%;
                max-width: 50%;
                margin-right: 1rem;
            }
        }

        @media (min-width: $mq-l) {
            flex-flow: row nowrap;

            .footer__item {
                width: auto;
                margin-right: 1rem;
                display: flex;
                justify-content: center;
                line-height: 1.5;
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: column;

        .footer__link {
            width: 1.5rem;
            height: 1.5rem;
            margin: auto 0 0 1rem;
            display: flex;
            justify-content: center;
        }
    }

    &__social-links {
        margin: auto 0 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;

        .footer__item {
            display: flex;
            flex-direction: column;
        }
    }

    &__link {
        margin-bottom: .8rem;
        display: block;

        @media (min-width: $mq-l) {
            margin-bottom: 0;
        }
    }

    &__item {
        @include OpenSansRegular;
        font-size: 14px;
        list-style-type: none;
        color: $color-white;

        &[data-type='linkedin'] {
            margin-left: auto;
        }

        @media (min-width: $mq-l) {
            @include ms-respond(font-size,0);
        }
    }

    a {
        color: $color-white;
        text-decoration: none;

        &:hover {
            color: $color-white;
            text-decoration: underline;
        }

        svg {
            width: 1.1rem;
            height: 1.1rem;

            &.facebook {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}
