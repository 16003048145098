.arrow-scenarios {

    &__wrapper {
        max-width: 29rem;
        margin: 1rem auto 3rem;
        padding: 0 1rem;

        @media (min-width: $mq-l) {
            max-width: 100%;
            margin: 1rem auto;
            padding: 0;
        }
    }

    &__visual {
        flex-shrink: 0;
        width: calc(100% - 9rem);
        margin: 0 -1rem -1.5rem;

        @media (min-width: $mq-xs) {
            width: calc(100% - 12rem);
        }

        @media (min-width: $mq-l) {
            margin: 0 auto;
            width: calc(100% - 19rem);
        }

        .mobile {

            @media (min-width: $mq-l) {
                display: none;
            }
        }

        .desktop {
            display: none;

            @media (min-width: $mq-l) {
                display: block;
            }
        }
    }

    &__svg {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 1rem;

        path {
            fill: $color-blue-light;

            &[data-active='true'] {
                fill: $color-blue-dark;
            }
        }

        @media (min-width: $mq-l) {
            margin-top: -1rem;
        }
    }

    &__labels-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__label {
        @include OpenSansRegular;
        font-size: 13px;
        border-radius: 100%;
        border: 2px dotted $color-blue-arrow;
        width: calc(6rem + 2px);
        height: calc(6rem + 2px);
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: .8rem;
        line-height: 1.4;
        transition:
            background-color $transition-time-regular .1s $default-transition-easing,
            transform $transition-time-regular .1s $default-transition-easing,
            color $transition-time-regular .1s $default-transition-easing,
            border $transition-time-regular .1s $default-transition-easing;

        @media (min-width: $mq-xs) {
            width: calc(7.5rem + 2px);
            height: calc(7.5rem + 2px);
        }

        @media (min-width: $mq-m) {
            font-size: 14px;
        }

        @media (min-width: $mq-l) {
            width: calc(8rem + 2px);
            height: calc(8rem + 2px);
            padding: 1rem;
        }

        span {
            display: block;
        }

        &[data-scenario='expected'] {
            margin: 0 auto;
        }

        &[data-scenario='optimistic'] {
            margin-top: -4rem;
            margin-right: -.5rem;
            @media (min-width: $mq-l) {
                margin-right: 1rem;
                margin-top: -4rem;
            }
        }

        &[data-scenario='pessimistic'] {
            margin-top: -4rem;
            margin-left: -.5rem;
            @media (min-width: $mq-l) {
                margin-left: 1rem;
                margin-top: -4rem;
            }
        }

        &[data-active='true'] {
            background-color: $color-blue-dark;
            border: 1px solid $color-blue-dark;
            color: $color-white;
        }
    }

    &__label-title {
        width: 100%;
    }
}
