.video {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    video {
        width: 100%;
        position: relative;
        z-index: 1;
        border-radius: $default-radius;
        overflow: hidden;

        .video--autoplayinview & {
            opacity: 0;
            transition: opacity .1s ease-in-out;
        }
        .video--autoplayinview[data-inview="true"] & {
            opacity: 1;
        }

        @media (min-width: $mq-s) {
            border-radius: $radius-7;
        }

        @media (min-width: $mq-l) {
            max-height: 433px;
        }

        // Fix: fullscreen video in Safari
        &:-webkit-full-screen {
            width: 100%;
            height: 100%;
            max-height: 100%;
        }
    }

    &__play {
        @include button;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $default-radius;
        overflow: hidden;
        cursor: pointer;

        @media (min-width: $mq-s) {
            border-radius: $radius-7;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $color-black;
            opacity: .2;
        }

        svg {
            width: 5rem;
            height: 5rem;
            position: relative;
            z-index: 2;

            @media (min-width: $mq-s) {
                width: 10rem;
                height: 10rem;
            }
        }

        path {
            fill: $color-white;
        }

        [data-playing='true'] & {
            display: none;
            pointer-events: none;
        }
    }
}
