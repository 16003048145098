.financial-overview {
    position: relative;
    z-index: 1;
    background-color: $color-white;

    .layout__outer--content-page & {
        background-color: $color-white;
    }

    &[data-showtabs='false'] {
        margin-top: 1rem;

        &[data-has-header='false'][data-section] {
            padding-top: 4rem;
            margin-top: 0;
        }

        @media (min-width: $mq-m) {
            padding: 1rem 0 4rem;
        }

        &[data-section-type='medium'] {
            padding: 1rem;
            margin-bottom: 1rem;

            .financial-overview__content-wrapper {
                padding: 1rem;
            }
        }
    }

    .notification {
        margin: 1rem 0 0 0;
        width: calc(100% - 2rem);

        @media (min-width: $mq-m) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }

    [data-type='gezamelijk'] {
        .notification {
            &:first-of-type {
                margin-top: 1rem;
            }
        }
    }

    &__inner.layout__inner {
        display: flex;
        flex-direction: column;
        [data-showtabs='false'] & {
            padding: 0;
        }
    }

    &--overlijden {
        .financial-overview__inner.layout__inner {
            padding: 0;

            @media (min-width: $mq-m) {
                padding: 0 2rem;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }

        [data-has-header='false'] & {
            padding: 0;
            margin: 0 auto 2rem;
        }
    }

    &__header-image {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0;

        @media (min-width: $mq-m) {
            width: 40%;
            padding: 0 0 0 5rem;
        }
    }

    &__header-content {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0;

        h2 {
            @include ms-respond(font-size, 8);
            line-height: 1.2;
            color: $color-blue-dark;
            margin: 0 0 2rem;
            text-align: center;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 10);
                text-align: left;
                margin: 2rem 0 .5rem;
            }
        }

        p {
            @include ms-respond(font-size, 2);
            @include OpenSansRegular;
            text-align: center;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 1);
                text-align: left;
            }
        }

        @media (min-width: $mq-m) {
            width: 60%;
            padding: 0 2rem 0 5rem;
        }

        [data-has-header='false'] & {
            h2 {
                @include ms-respond(font-size, 4);
                margin-bottom: 0;
            }

            @media (min-width: $mq-m) {
                width: 100%;
                padding: 0;

                h2 {
                    @include ms-respond(font-size, 7);
                    flex-grow: 1;
                    text-align: center;
                    margin: 0 auto;
                    padding: 0;
                    width: 100%;
                    min-width: 100%;
                }
            }
        }
    }

    &__header-svg {
        width: 60%;
        height: 60%;
        display: block;
        margin: 2rem auto;

        @media (min-width: $mq-m) {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }

    &__tabs {
        display: none;

        @media (min-width: $mq-m) {
            display: block;
        }
    }

    &__tabs-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        min-width: 100%;
    }

    &__tab {
        list-style-type: none;
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        margin-right: 1rem;

        [data-length='2'] & {
            @media (min-width: $mq-m) {
                flex-basis: 28%;
                max-width: 28%;
            }
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__tab-link {
        @include ms-respond(font-size, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: .5rem;
        color: $color-black;
        text-decoration: none;
        background-color: $color-white;

        &:hover {
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;
            background-color: $color-grey-light-section;
        }

        &[aria-selected='true'] {
            background-color: $color-blue-dark;
            color: $color-white;
            font-weight: bold;
            box-shadow: $box-shadow-regular;
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;

            &:hover {
                background-color: $color-blue-dark;
            }
        }

        @media (min-width: $mq-m) {
            min-height: 100%;
        }
    }

    &__content {
        background-color: $color-white;
        position: relative;

        [data-showtabs='false'] & {
            padding-top: 0;
            overflow: hidden;
            box-shadow: $box-shadow-regular;
            margin-bottom: 1rem;
            border-radius: $radius-5;

            &::before {
                content: '';
                height: .35rem;
                background-color: $color-blue-dark;
                display: block;
                width: calc(100% + 1rem);
                margin-left: -.5rem;

                @media (min-width: $mq-m) {
                    width: calc(100% + 2rem);
                    margin-left: -1rem;
                    top: -1rem;
                    position: relative;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            @media (min-width: $mq-m) {
                border-top: 0;
                margin-bottom: 0;
                padding-top: 1rem;
            }
        }

        @media (min-width: $mq-m) {
            box-shadow: $box-shadow-regular;
            margin-top: -1px;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            border-top: 4px solid $color-blue-dark;
            padding: .5rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            [data-has-header='false'][data-section] & {
                min-height: min-content;
                margin-bottom: 3rem;
            }

            .financial-overview__switch-wrapper {
                margin-bottom: auto;
            }

            .financial-overview__content-wrapper {
                margin: 0;

                [data-timeline='true'] & {
                    margin: 0 0 auto 0;
                }
            }

            [data-current='3'][data-timeline='false'] & {
                justify-content: flex-start;

                .financial-overview__content-wrapper,
                .financial-overview__section-full {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }

                .financial-overview__section {
                    flex-grow: 1;
                }
            }
        }
    }
    &__content-wrapper {
        position: relative;
        overflow: hidden;

        @media (min-width: $mq-m) {
            padding: 0 .5rem;
        }
    }

    &__section {
        @include sectionTransition;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;

        &-download-button {
            position: absolute;
            right: 0;
            padding: 1rem 2rem 0 0;
        }

        &[aria-hidden='true'] {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            z-index: 1;
            min-width: min-content;
            min-height: min-content;
            transform: translate3d(0, 1rem, 0);
            visibility: hidden;
        }

        h3 {
            @include ms-respond(font-size, 3);
            color: $color-blue-dark;
            margin-top: 0;
            margin-bottom: 0;
        }

        &[data-section-type='content'] {
            .button {
                margin-top: 1rem;
            }
        }

        &[data-type='vooruitblik'] {
            @media (min-width: $mq-m) {
                flex-flow: row wrap;
                align-items: center;
            }
        }

        @media (min-width: $mq-l) {
            flex-flow: row wrap;
        }
    }

    &__banner-img {
        max-width: 100%;
    }

    &__content-text {
        p {
            margin-top: 0;
        }
    }

    &__section-full {
        width: 100%;
        padding: 2rem 1rem;
    }

    .login-card {
        box-shadow: none;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        margin: 1rem 0 0;
    }

    &__section-header {
        display: flex;
        flex-direction: column-reverse;

        @media (min-width: $mq-m) {
            flex-direction: row;
            justify-content: space-between;
        }

        &-right {
            text-align: center;

            @media (max-width: $mq-m) {
                display: none;
            }
        }
    }

    &__section-footer {
        margin-top: auto;

        p {
            margin-bottom: 0;
        }
    }

    &__section-left {
        display: none;

        [data-section-type='content'] & {
            display: flex;
            flex-direction: column;
            padding: 2rem 1rem;
        }

        [data-type='vooruitblik'][data-section-type='content'] & {
            @media (min-width: $mq-m) {
                display: flex;
                flex-direction: column;
                padding-right: 1rem;
                width: 46%;
                padding-left: 1rem;
            }

            @media (min-width: $mq-l) {
                width: 48%;
            }
        }

        @media (min-width: $mq-m) {
            [data-section-type='timeline'][data-type='gezamenlijk'] & {
                margin: .8rem 0;
                min-height: 100%;
            }
        }

        @media (min-width: $mq-l) {
            display: flex;
            flex-direction: column;
            width: 38%;
            padding-right: 1rem;

            [data-section-type='content'] & {
                width: 48%;
                padding-left: 1rem;
            }
        }
    }

    &__timeline {
        &[data-collapsible]:last-child .timeline .timeline__header {
            border-bottom: none;
        }
    }

    &__partner-header {
        padding: 0 0 1rem 0;

        @media (min-width: $mq-m) {
            padding: 0 1.5rem 0 0;
        }

        h3 {
            @include ms-respond(font-size, 3);
            color: $color-blue-dark;
            margin-top: 0;
            margin-bottom: .8rem;
            line-height: 1.2;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 4);
                margin-bottom: .8rem;
                line-height: 1.5;
            }
        }
    }

    &__partner-questions {
        margin-top: auto;

        @media (min-width: $mq-m) {
            padding-right: 1.5rem;
        }

        h3 {
            margin-bottom: .5rem;
        }

        a {
            color: $color-black;
        }
    }

    &__banner {
        display: none;
        @media (min-width: $mq-l) {
            display: block;
        }
    }

    &__section-right {
        width: 100%;
        flex-direction: column;
        display: flex;

        [data-section-type='content'] {
            display: none;
        }

        [data-type='vooruitblik'][data-section-type='content'] & {
            @media (min-width: $mq-m) {
                // width: 54%;
                padding: .7rem 0 1rem;
            }

            @media (min-width: $mq-l) {
                // width: 52%;
                padding: .7rem 1rem 1rem;
            }
        }

        @media (min-width: $mq-l) {
            // width: 62%;
            padding: .7rem 2rem 1rem;
            margin: 1rem 0;

            [data-section-type='content'] & {
                width: 52%;
                padding: .7rem 1rem 1rem;
                display: flex;
            }
        }
    }

    &__switch-wrapper {
        display: none;
        margin-left: auto;

        [data-timeline='false'] & {
            display: none;
        }

        @media (min-width: $mq-m) {
            display: flex;
            justify-content: flex-end;

            [data-timeline='false'] [data-timeline='true'] & {
                display: flex;
            }
        }
    }

    .tabbed-section & {
        h2 {
            &.financial-overview__timelines-label {
                @include ms-respond(font-size,2);
                margin: 0 0 .5rem;
                padding: 0 2.5rem;

                @media (min-width: $mq-m) {
                    padding: 0;
                }
            }
        }
    }

    &__questions {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem 0;
        text-align: left;
    }

    &__questions-title {
        color: $color-blue-dark;
        margin: 0 0 .5rem;
    }

    &__questions-text {
        @include OpenSansRegular;
    }

    &__mobile-switch {
        display: flex;
        justify-content: center;
        padding: 1rem 1.5rem;
        margin-bottom: 1rem;

        @media (min-width: $mq-m) {
            display: none;
            visibility: hidden;
        }
    }

    &__mobiletab-link {
        @include button;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 1rem;
        width: 100%;
        background-color: $color-white;
        border-bottom: 1px solid $color-blue-dark;

        svg {
            margin-left: auto;
            path {
                fill: $color-blue-dark;
            }
        }

        span {
            margin: 0 auto;
            position: relative;
            color: $color-blue-dark;
            font-weight: bold;
            flex: 1;
            right: -.25rem;
        }

        &[aria-expanded='true'] {
            background-color: $color-blue-dark;

            span {
                color: $color-white;
            }

            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }

    &__switch {
        cursor: pointer;
        border: none;
        background-color: transparent;
        display: flex;

        span {
            color: $color-blue-dark;
            padding: .25rem .5rem;
            display: flex;
            min-width: 7rem;
            justify-content: center;
            align-items: center;

            &:first-child {
                border: $border-purple-light;
                border-top-left-radius: .8rem;
                border-bottom-left-radius: .8rem;
            }

            &:last-child {
                background-color: $color-purple-pale;
                border: $border-blue-dark;
                font-weight: bold;
                border-top-right-radius: .8rem;
                border-bottom-right-radius: .8rem;
                margin-left: -1px;
            }
        }

        &[aria-checked='true'] span {
            &:first-child {
                background-color: $color-purple-pale;
                border: $border-blue-dark;
                font-weight: bold;
            }

            &:last-child {
                background-color: transparent;
                border: $border-purple-light;
                font-weight: normal;

                &:hover {
                    border: $border-blue-dark;
                }
            }
        }
    }

    [data-type='gezamenlijk'] {
        width: 100%;
        min-width: 100%;

        .notification:first-of-type {
            margin-top: 1rem;
        }
    }

    [data-type='straks'],
    [data-type='nu'],
    [data-type='multiple'],
    [data-type='gezamenlijk'] {
        .timeline {
            padding: 0 1.5rem 0 0;

            @media (min-width: $mq-m) {
                padding: 0;
            }
        }
        .financial-overview__timeline {
            position: relative;
            padding-left: 2.5rem;
            padding-bottom: 1rem;

            &[data-length='1'] {
                padding-left: 1.5rem;

                &::before,
                &::after {
                    display: none;
                }

                @media (min-width: $mq-l) {
                    padding-left: 0;
                }
            }

            @media (min-width: $mq-l) {
                border-bottom: $border-coal-60;
                padding-left: 0;
                padding-bottom: 0;
            }

            &::before {
                content: '';
                width: 1rem;
                height: 1rem;
                border-radius: 1rem;
                border: 2px solid $color-blue-dark;
                background-color: $color-white;
                position: absolute;
                left: .5rem;
                top: 2rem;
                z-index: 2;

                @media (min-width: $mq-l) {
                    left: -2rem;
                }
            }

            &::after {
                content: '';
                width: 0;
                height: 100%;
                border: 1px solid $color-blue-dark;
                background-color: $color-white;
                position: absolute;
                left: .95rem;
                top: 2.5rem;
                z-index: 1;

                @media (min-width: $mq-l) {
                    left: calc(-2rem + .45rem);
                }
            }

            &:first-of-type {
                &::after {
                    top: 2rem;
                }
            }

            &[data-last='true'] {
                border-bottom: none;

                &::after {
                    height: 2.1rem;
                    top: -.1rem;
                }
            }

            &[data-is-open='true'] {
                &::before {
                    background-color: $color-blue-dark;
                }
            }
        }
    }

    .chart__wrapper {
        display: none;

        @media (min-width: $mq-m) {
            display: block;
        }
    }

    &__mobiletab {
        @media (min-width: $mq-m) {
            display: none;
        }
    }
}
