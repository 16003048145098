figure {
    margin: 0;
}

.hidden {
    display: none;
}

.visuallyhidden {
    @include visuallyhidden;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
    outline: none;
}

.pull {
    &::before,
    &::after {
        content: '';
        display: block;
        clear: both;
    }
    &--left {
        float: left;
        align-self: flex-start;
    }
    &--right {
        float: right;
        align-self: flex-end;

        @media (min-width: $mq-l) {
            margin: 0 1em 0 0;
        }

        @media (min-width: $mq-xxl) {
            margin: 0;
        }
    }
}

/**
 * Body copy
 */
main,
article {
    p,
    ol {
        max-width: $max-text-width;
    }
}
a {
    color: $color-secondary;
}

/**
 * Links & Buttons
 */
button {
    font: inherit;
}

a.cta,
.form input[type="submit"],
button.cta {
    position: relative;
    text-decoration: none;
    text-align: left;
    vertical-align: middle;
    user-select: none;

    @media (min-width: $mq-s) {
        white-space: nowrap;
    }
    &[disabled],
    &[aria-disabled="true"] {
        opacity: .3;
        cursor: not-allowed;
    }

}

button.cta {
    border-width: 0;
    cursor: pointer;
}

[data-hide][aria-hidden='true'] {
    display: none;
}

[data-js-focus-visible] [data-focus-visible-added]:not(input),
.js-focus-visible .focus-visible:focus:not(input),
input.keyboardFocused {
    outline: 2px solid $color-blue-light;
    border-color: $color-blue-dark-80;
}

[data-js-focus-visible] footer [data-focus-visible-added],
.js-focus-visible footer .focus-visible:focus {
    outline: 2px solid $color-white;
    border-color: $color-white;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.chevron {
    @include chevron;
}
