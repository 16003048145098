.provider-address {
    &__list {
        margin: 0;
        padding: 0;
    }

    &__list-item {
        list-style-type: none;
        display: flex;
        
        .icon-email,
        .icon-website,
        .icon-phone {
            position: relative;
            top: 4px;
            margin-right: 2px;
        }
    }

    &__website,
    &__email,
    &__phone {
        margin-left: 2px;
    }

    &__email,
    &__website,
    &__phone {
        overflow: hidden;
        flex-grow: 1;
        
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            width: 100%;

            @media (min-width: $mq-s) {
                width: auto;
            }
        }
    }
}
