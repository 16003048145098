.content-cards {
    position: relative;

    &__inner {
        background-color: $color-white;
    }

    &__list {
        margin: 0;
        padding: 0 0 3rem;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }
    }

    &__title {
        @include ms-respond(font-size,6);
        color: $color-blue-dark;
        text-align: center;
        display: block;
        margin: 1rem auto 3rem auto;

        [data-introduction='true'] & {
            margin: 1rem auto 0 auto;
        }
    }

    &__card {
        list-style-type: none;
        background-color: $color-blue-lightest;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            @include ms-respond(font-size,6);
            line-height: 1.4;
            color: $color-blue-dark;
            text-align: center;
            margin: 1rem 0 0;

            [data-length='3'] & {
                @include ms-respond(font-size,4);
            }

            @media (min-width: $mq-m) {
                max-width: 20rem;
                margin: 2rem auto 0 auto;
            }
        }

        h3 {
            @include ms-respond(font-size,3);
            line-height: 1.4;
            color: $color-blue-dark;
            text-align: center;
            margin: 1rem 0 1rem;

            @media (min-width: $mq-m) {
                max-width: 20rem;
                margin: 1.5rem auto 1rem auto;
            }
        }

        a {
            font-weight: 700;
            display: flex;
            margin-top: .25rem;
            justify-content: center;
        }

        img {
            &.content-cards__basic {
                visibility: hidden;
                height: 0;
                margin: 0 auto 1rem;
            }

            &.content-cards__illustration {
                width: 55%;
                height: auto;
                display: block;
                margin: 0 auto .5rem;

                [data-length='3'] & {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    width: 45%;
                }
            }

            &.content-cards__icons {
                width: 3.5rem;
                height: auto;
                display: block;
                margin: 1rem auto .5rem;

                @media (min-width: $mq-m) {
                    margin: 2rem auto .5rem;
                }
            }

            &.content-cards__icons-big {
                width: 4.5rem;
                height: auto;
                display: block;
                margin: 1rem auto .5rem;

                @media (min-width: $mq-m) {
                    margin: 2rem auto .5rem;
                    width: 5rem;
                }
            }
        }

        @media (min-width: $mq-m) {
            flex-basis: 50%;

            &.content-box--white {
                padding: 1rem;
                margin-left: .5rem;
                margin-right: .5rem;
            }

            &:first-of-type {
                margin-right: .5rem;
                margin-left: 0;
            }

            &:last-of-type {
                margin-left: .5rem;
                margin-right: 0;
            }
        }
    }

    &__card-text {
        text-align: center;
        margin-bottom: 1rem;

        p {
            margin: 0 auto;
        }

        &[data-length='3'] {
            margin-bottom: 1rem;
        }

        @media (min-width: $mq-m) {
            width: 80%;
            margin: 0 auto 1rem;
        }
    }

    .button--primary {
        justify-content: center;
        margin: .5rem;

        .title {
            display: none;
        }

        @media (min-width: $mq-m) {
            font-size: 14px;
            margin: auto auto 2rem;
        }

        @media (min-width: $mq-l) {
            font-size: 16px;
        }

        @media (min-width: $mq-xxl) {
            .title {
                display: flex;
            }

            .title--short {
                display: none;
            }
        }
    }

    &[data-length='3'] {
        .button--primary svg {
            @media (min-width: $mq-m) {
                display: none;
            }

            @media (min-width: $mq-l) {
                display: block;
            }
        }
    }

    &__introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        text-align: center;
    }

    &[data-type='illustrations'] {
        .button--primary {
            min-width: 100%;
            max-width: 100%;
            width: 100%;

            @media (min-width: $mq-m) {
                min-width: auto;
                max-width: auto;
                width: auto;
            }
        }
    }

    &:not([data-section='']) {
        .content-cards__inner {
            padding: 4rem 0 0 0;
        }
    }
    .single-card {
        .content-cards__card {
            flex-basis: 100%;

            h2 {
                max-width: unset;
            }
        }
    }

}
