.search-results {

    &__section-inner {
        &[aria-hidden='true'] {
            display: none;
        }
    }

    &--content-box {

        .search-results__section-inner {
            background-color: $color-white;
            box-shadow: $box-shadow-regular;
            border-radius: $radius-5;
            margin-bottom: 1rem;
            padding: 1rem;

            > .search-results__question {
                padding: 0;

                svg {
                    margin-left: -.5rem;

                    @media (min-width: $mq-m) {
                        margin-left: -.8rem;
                    }
                }

                .chevron {
                    margin-right: .25rem;
                }
            }

            @media (min-width: $mq-m) {
                padding: 1rem 2rem;
            }
        }

        h2 {
            margin-top: 0;
            font-size: 18px;
        }

        h4 {
            font-size: 18px;
            margin: 0;
            padding: 0;
            color: $color-black;

            &.sidebar-title {
                display: none;

                @media (min-width: $mq-m) {
                    display: block;
                }
            }
        }

        .button--secondary {
            @media (min-width: $mq-m) {
                display: none;
            }
        }

        .search-results__section-inner.search-results__default-results,
        .search-results__section-inner.search-results__search-results {
            @media (min-width: $mq-m) {
                padding: 1.4rem 2rem 1rem;
            }
        }

        .search-results__section-inner.search-results__sidebar-inner {
            @media (min-width: $mq-m) {
                padding: 1.4rem 2rem 1.5rem;
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    &__results-item,
    &__results-list-item {
        border-top: 1px solid $color-coal-60;
        display: flex;
        flex-direction: column;
        list-style-type: none;
    }

    &__question {
        @include OpenSansRegular;
        font-size: 14px;
        display: flex;
        flex-wrap: nowrap;
        padding: .8rem 0;
        color: $color-black;
        text-decoration: none;
        align-items: center;

        .chevron {
            transform-origin: center center;
        }

        span {
            pointer-events: none;
        }

        @media (min-width: $mq-m) {
            font-size: 16px;
        }

        &[aria-expanded='true'] {
            font-weight: 700;
            color: $color-blue-dark;

            path {
                fill: $color-blue-dark;
            }
        }

        &[aria-expanded='false'] {
            &:hover {
                .chevron {
                    transform: $chevron-transform-down;
                }
            }
        }
    }

    &__answer {
        padding-left: 1.7rem;

        p:first-of-type {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 1.5rem;
        }

        a {
            color: $color-blue-dark;

            &:hover {
                color: $color-black;
            }
        }

        li {
            @include ms-respond(font-size,1);
            @include OpenSansRegular;
            margin-bottom: .5rem;
        }

        ol {
            padding: 0 1.2rem;
            margin-bottom: 1rem;
        }

        ul {
            padding: 0 1.2rem;
            margin-bottom: 1rem;

            li {
                list-style-type: disc;
            }
        }

        &[aria-hidden='false'] {
            color: $color-blue-dark;
        }
    }

    &__results-list {
        margin: 0;
        padding: 0;

        .chevron {
            margin: 0 .5rem 0 -.5rem;
            transform-origin: 50%;
            flex-shrink: 0;
        }
    }

    &__search-results-header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .button--transparent {
            display: none;
            margin-left: auto;
            margin-bottom: 1rem;

            span {
                min-width: min-content;
                white-space: nowrap;
            }

            [data-filtered='true'] &,
            [aria-hidden='false'] & {
                display: flex;
                min-width: min-content;
            }
        }
    }
    &__footer {
        @include OpenSansRegular;
        font-size: 14px;

        [aria-hidden='true'] {
            display: none;
        }
    }
}
