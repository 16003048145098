.video-section {
    position: relative;
    background-color: $color-white;

    &__inner {
        @media (min-width: $mq-l) {
            display: flex;
            flex-wrap: nowrap;

            [data-has-text='false'] & {
                flex-direction: column;
                &.layout__inner {
                    padding: 3rem 0;
                }

            }
        }
    }

    &[data-background-color='dark-blue'] {
        background-color: $color-blue-dark;
    }

    &[data-color='white'] {
        color: $color-white;

        a {
            color: $color-white;
        }

        ul li::before {
            background-color: $color-white;
        }
    }

    &__header {
        padding: 0;

        @media (min-width: $mq-m) {
            padding: 0 1rem;
        }

        h2 {
            color: $color-red;
            text-align: center;
            line-height: 1.4;

            [data-has-text='false'] & {
                @include ms-respond(font-size,5);

                @media (min-width: $mq-m) {
                    @include ms-respond(font-size,6);
                }
            }

            [data-color='white'] & {
                color: $color-white;
            }
        }

        .button {
            display: none;
        }

        @media (min-width: $mq-m) {
            h2 {
                @include ms-respond(font-size,7);
            }
        }

        @media (min-width: $mq-l) {
            width: 33.3%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 2rem 0 0;
            margin-top: 0;
            align-self: center;

            h2 {
                text-align: left;
                margin-top: 0;
            }

            .button {
                display: flex;
                margin: 2rem 0;
            }

            [data-has-text='false'] & {
                width: 100%;
                flex-grow: 1;
                padding: 0;

                h2 {
                    text-align: center;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }

    ol,
    ul {
        padding: 0;
        margin: 1rem 0 0;

        h3 {
            display: inline-flex;
            margin: 0;
        }

    }

    li {
        @include ms-respond(font-size,1);
        text-align: center;
        list-style-position: inside;

        p {
            display: inline;
        }

        h3 ~ p {
            display: block;
            margin: 0 0 1rem;
        }

        @media (min-width: $mq-l) {
            text-align: left;
        }
    }

    ul {
        li {
            list-style-type: none;

            &::before {
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: .5rem;
                padding: 0;
                background-color: $color-black;
                position: relative;
                top: -.15rem;
                margin-right: .5rem;

            }
        }
    }

    &__text {
        text-align: center;
        line-height: 1.8;

        p {
            margin-bottom: 0;
        }

        @media (min-width: $mq-l) {
            text-align: left;
        }
    }

    .video {
        margin: 1.5rem 0 2.5rem;
    }

    &__video {
        display: flex;
        flex-direction: column;
        padding: 2rem 0 3rem;

        [data-has-text='false'] & {
            padding: 0;
        }

        .button {
            margin: 0 auto;
        }
        @media (min-width: $mq-l) {
            padding: 2rem 0 3rem  1rem;
            width: 66.6%;

            [data-has-text='false'] & {
                margin: 0 auto;
            }

            .button {
                display: none;
            }
        }
    }
}
