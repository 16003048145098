.service-page {
    article {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__header {
        display: none;

        @media (min-width: $mq-m) {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            padding: 0 0 2rem 0;
        }

        h1 {
            @include ms-respond(font-size, 4);
            color: $color-blue-dark;
            margin: 0;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 6);
            }

            @media (min-width: $mq-l) {
                @include ms-respond(font-size, 8);
            }
        }

        img {
            display: none;

            @media (min-width: $mq-m) {
                margin: 2rem auto 0;
                display: block;
                width: 68%;
                height: auto;
            }
        }
    }

    &__content {
        padding-top: 2rem;

        @media (min-width: $mq-m) {
            padding-top: 4rem;
        }
    }

    &__content-inner {
        position: relative;
        overflow: hidden;
        background-color: $color-white;
        margin: 1rem 1rem 3rem;
        border-radius: .5rem;
        padding: 2rem 1rem;
        box-shadow: $box-shadow-regular;

        @media (min-width: $mq-m) {
            padding: 2rem 1.5rem;
            margin: 1rem 1rem 5rem;
        }

        &::before {
            content: "";
            position: absolute;
            background-color: $color-blue-dark;
            left: 0;
            top: 0;
            width: 100%;
            height: .5rem;
            z-index: 1;
        }

        h1:first-of-type,
        h2:first-of-type {
            @include ms-respond(font-size, 3);
            color: $color-blue-dark;
            margin: 0;

            @media (min-width: $mq-m) {
                @include ms-respond(font-size, 5);
            }
        }

        h2,
        h3 {
            @include ms-respond(font-size, 1);
        }

        // @todo, if you find yourself re-using this anywhere, make a seperate list component
        // list, component, ol, ul
        ol,
        ul {
            @include ms-respond(font-size, 1);
            padding: 0;
            list-style: none;
        }

        ol {
            counter-reset: listCounter;

            > li {
                counter-increment: listCounter;

                &::before {
                    content: counter(listCounter) ". ";
                }
            }
        }

        ul {
            li {
                &::before {
                    content: "- ";
                }
            }
        }

        .disclaimer-list {
            list-style-type: disc;
            padding-left: 40px;

            li {
                @include OpenSansBody;

                &::before {
                    content: none;
                }
            }
        }

        #privacyverklaring {
            li {
                @include ms-respond(font-size, 1);
                margin: 1rem auto;
            }
        }
    }

    address {
        @include ms-respond(font-size, 1);
        font-style: inherit;

        a {
            color: $color-black;
            font-weight: bold;
        }
    }

    &[data-type="aside"] {
        @media (min-width: $mq-m) {
            article {
                flex-flow: row nowrap;
            }

            .service-page__content-inner {
                max-width: 633px;
            }
        }
    }

    &[data-type="centered"] {
        @media (min-width: $mq-m) {
            .service-page__content-inner {
                max-width: 633px;
                margin: 1rem auto 5rem;
            }
        }
    }
}
