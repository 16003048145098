.breadcrumb {
    position: relative;

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
        }
    }

    &__list-item {
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: .25rem;

        @media (min-width: $mq-m) {
            margin-bottom: 0;
        }

        a {
            font-size: 11px;
            color: $color-black;

            &:hover {
                color: $color-blue-dark;
            }

            @media (min-width: $mq-xs) {
                font-size: 13px;
            }

            @media (min-width: $mq-m) {
                font-size: 14px;
            }
        }

        &:last-child {
            margin-bottom: 0;

            a {
                text-decoration: none;
                cursor: default;
                pointer-events: none;
            }
        }

        svg {
            width: 1rem;
            height: 1rem;
            margin: 0 .5rem;
        }

        path {
            fill: $color-black;
        }
    }
}
