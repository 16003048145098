.overview-modal {

    &__content {
        @include OpenSansRegular;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;

        &-height {
            min-height: 65px;
        }
        &-border {
            border-bottom: 1px solid $color-coal-outline;
        }
        &-border--blue {
            border-bottom: 1px solid $color-blue-dark;
        }
    }

    &__title {
        color: $color-blue-dark;
        margin-top: 1rem;
    }

    &__amount {
        text-align: right;
    }

    &__amount-label {
        color: $color-black;
        @include ms-respond(font-size,-1);
    }

    h3 {
        @include ms-respond(font-size,2);
        margin: 0;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: .5px solid $color-coal-outline;
        }
    }

    &__totals {
        color: $color-blue-dark;
    }
}
