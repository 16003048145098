.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    align-items: center;
    z-index: 999;

    @media (min-width: $mq-l) {
        bottom: 3.5rem;
    }

    &__container {
        padding: 2rem;
        border-radius: $radius-5;
        box-shadow: $box-shadow-regular;
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        max-width: $max-width-medium;
        margin: 0 auto;

        @media (min-width: $mq-m) {
            flex-flow: row nowrap;
            padding: 1rem 1.5rem;
        }
    }

    &__text {
        @media (min-width: $mq-m) {
            padding-right: 1rem;
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        min-width: min-content;
        flex-shrink: 0;

        button {
            white-space: nowrap;
            flex-shrink: 0;
        }

        .chevron-right {
            width: 1.2rem;
            height: 1.2rem;
        }

        @media (min-width: $mq-m) {
            margin-top: auto;
            padding-left: 2rem;
            min-width: 17rem;
        }

        @media (min-width: $mq-l) {
            margin-top: 0;
            padding-left: 0;
        }
    }

    &__approve {
        margin-left: 1rem;
    }

    p {
        margin: 0 0 1rem;

        @media (min-width: $mq-m) {
            margin: 0;
        }
    }
}
