.search {
    position: relative;
    padding: 1rem;

    &__inner {
        max-width: $max-width-medium;
        margin: 0 auto;
    }

    h3 {
        font-size: 20px;
        text-align: center;
    }

    .bold {
        font-weight: 700;
        color: $color-blue-dark;
    }

    [data-content] {
        overflow: hidden;
    }

    [data-is-open='false'] [data-content][aria-hidden='true'] {
        max-height: 0;
        visibility: hidden;

    }
}
