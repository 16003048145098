/**
 * Header styles without JavaScript enabled
 */
.header {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 6;
    transition: transform $transition-time-long $default-transition-easing;
    background-color: $color-white;

    @media (min-width: $mq-m) {
        position: fixed;
    }

    [data-modal-type='centered'][data-modal-open="true"] & {
        pointer-events: none;

        @media (min-width: $mq-m) {
            z-index: 3;
        }
    }

    [data-hash-scroll] {
        &:focus {
            min-width: 12rem;
        }
    }

    &--logged-out {
        &::before,
        &::after {
            display: none;
        }

        .header__columns {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }

        .language-selector {
            &__short-text {
                display: block;

                @media (min-width: $mq-m) {
                    display: none;
                }
            }

            &__long-text {
                @media (min-width: $mq-m) {
                    display: block;
                }
            }

        }
    }

    &__logo {
        flex-shrink: 0;
        width: 2.6rem;
        position: relative;
        z-index: 3;
        transform: scale(1);
        transform-origin: top center;
        transition: transform $transition-time-long $default-transition-easing;

        @media (min-width: $mq-m) {
            width: 4rem;
        }

        .layout--home & {
            @media (min-width: $mq-m) {
                width: 6rem;
            }
        }

        a {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }

        @media (min-width: $mq-m) {
            width: 6rem;
        }

        @media (min-width: $mq-header) {
            transform-origin: left top;
        }
    }

    .layout--home & {
        position: absolute;
        z-index: 2;
        top: 0;
        box-shadow: none;

        &::before,
        &::after {
            display: none;
        }

        .language-selector {
            &__short-text {
                display: block;

                @media (min-width: $mq-m) {
                    display: none;
                }
            }

            &__long-text {
                @media (min-width: $mq-m) {
                    display: block;
                }
            }

        }
    }

    .menu__back {
        margin-top: 7rem;
        width: 100%;
        font-weight: bold;
        border-bottom: 1px solid $color-grey-border-light;

        .icon {
            width: 1.5rem;
            height: 1.5rem;
            transform: rotate(180deg);
            margin-right: 1rem;

            use {
                fill: $color-black;
            }
        }

        &__button {
            @include button;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 1rem;
        }

        @media (min-width: $mq-m) {
            display: none;
        }
    }

    &--back,
    &--partial {

        &[data-language] {
            .button {
                margin-left: 0;
            }
        }

        .button {
            position: relative;
            z-index: 2;
            transition: transform $transition-time-long $default-transition-easing;
            margin-left: auto;

            svg {
                margin-right: 0;

                @media (min-width: $mq-m) {
                    margin-right: .5rem;
                }
            }

            .arrow-left {
                transform: rotate(180deg);
            }

            .text {
                display: none;

                @media (min-width: $mq-m) {
                    display: inline-flex;
                }
            }
        }

        .language-selector {
            margin-left: auto;
            z-index: 2;

            .button svg {
                margin-right: -.5rem;
                transform: rotate(90deg);

                [data-active='true'] & {
                    transform: rotate(-90deg);
                }
            }

            &[data-active='true'] {
                .button svg {
                    transform: rotate(-90deg);
                }
            }

            &__short-text {
                display: block;

                @media (min-width: $mq-m) {
                    display: none;
                }
            }

            &__long-text {
                @media (min-width: $mq-m) {
                    display: block;
                }
            }
        }

        &[data-status='scrolled'] {
            .button {
                transform: translate3d(0, -1.2rem, 0);
            }
        }
    }

    &[data-level='1'] {
        .menu__back {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            height: 0;
        }
    }

    &__columns {
        display: flex;
        padding: 0 1rem;
        margin: 0 auto;

        @media (min-width: $mq-header) {
            max-width: $max-width-medium;
        }
    }

    &__navigation-wrapper {
        display: flex;
        width: 100%;
        left: 0;
        flex-direction: column;
        padding: 0;
        background-color: $color-white;
        opacity: 0;
        position: relative;
        z-index: 2;
        transition: opacity $transition-time-long $default-transition-easing;

        @media (min-width: $mq-header) {
            width: auto;
            flex-direction: row;
            transform: none;
            flex-grow: 1;
        }

        [data-modal-type='centered'][data-modal-open="true"] &,
        [data-modal-type='full'][data-modal-open="true"] & {
            display: none;
        }
    }

    &__dropdown-target {
        z-index: 1;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        width: 12rem;

        @media (min-width: $mq-header) {
            left: 0;
            right: unset;
            width: 100%;
        }
    }

    &__loggedin {
        padding-top: 0;
        min-height: 2.6rem;
        overflow: hidden;

        @media (min-width: $mq-header) {
            border: 1px solid $color-black;
            border-radius: 1.2rem;
        }
    }

    &__dropdown-trigger {
        position: relative;
        z-index: 2;
        background-color: transparent;
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        align-items: center;
        padding: .5rem;
        border: 1px solid transparent;
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;

        cursor: pointer;

        span {
            white-space: nowrap;
            flex-grow: 1;
        }

        .profile {
            width: 1.5rem;
            height: 1.5rem;
            flex-grow: 0;
            use {
                fill: $color-black;
            }
        }

        .chevron {
            width: .7rem;
            height: .7rem;
            transform: rotate(90deg);
            flex-grow: 0;
            margin-left: .7rem;
            position: relative;
            top: .1rem;
            display: none;

            use {
                fill: $color-black;
            }
        }

        &:hover {
            color: $color-blue-dark;

            ~ .header__dropdown-target .header__loggedin {
                border-color: $color-blue-dark;
            }

            .profile use,
            .chevron use {
                fill: $color-blue-dark;
            }
        }

        @media (min-width: $mq-header) {
            padding: .5rem 1rem;
            margin: auto 0  auto;
            border: none;
            border-bottom: 1px solid transparent;

            .profile {
                margin-right: .7rem;
            }

            .chevron {
                display: block;
            }
        }
    }

    &__dropdown-name {
        display: none;

        @media (min-width: $mq-header) {
            display: block;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__menus-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        z-index: 1;
        flex-direction: row;

        position: absolute;
        top: 2.6rem;
        left: 0;
        width: 100vw;
        background-color: $color-white;
        align-items: center;

        @media (min-width: $mq-m) {
            position: relative;
            width: auto;
            top: 0;
            left: 0;
        }

        // background
        &::before {
            content: '';
            width: 200vw;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            background-color: $color-blue-lightest;
            position: absolute;
            z-index: -1;
            pointer-events: none;
        }
    }

    &__actions-wrapper {
        display: flex;
        flex-wrap: nowrap;
        margin-left: auto;
        padding: 0;
        align-items: center;
        position: relative;
        justify-content: flex-end;
        z-index: 2;
        transition:
            transform $transition-time-long $default-transition-easing,
            opacity $transition-time-long $default-transition-easing;

        [data-modal-type='centered'] &,
        [data-modal-type='full'] & {
            opacity: 0;
        }

        a,
        button {
            border: none;
            font-weight: normal;
        }
    }

    &--logged-in {
        .header__logout {
            margin-left: .5rem;
            display: none;

            @media (min-width: $mq-header) {
                display: flex;
                padding: 0 .95rem;
                height: 2.65rem;
                margin-right: .1rem;
            }
        }

        .language-selector {
            &__short-text {
                display: block;

                @media (min-width: $mq-m) {
                    display: none;
                }
            }

            &__long-text {
                @media (min-width: $mq-m) {
                    display: block;
                }
            }

        }
    }

    &__download {
        margin-right: .5rem;
        display: none;

        &__short-text {
            display: block;

            @media (min-width: $mq-header-long) {
                display: none;
            }
        }

        &__long-text {
            display: none;
            @media (min-width: $mq-header-long) {
                display: block;
            }
        }

        @media (min-width: $mq-m) {
            display: flex;
            min-width: 10rem;
            flex-grow: 1;
            flex-shrink: 0;
        }

        @media (min-width: $mq-header) {
            margin-right: 0;
            padding: 0 .95rem;
            height: 2.65rem;
        }
    }

    &__download,
    &__logout {
        &.button--primary,
        .button--secondary {
            padding: .4rem .95rem;
        }

        &.button--icon svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &__tablet-title {
        @include ms-respond(font-size,4);
        display: none;
        color: $color-blue-dark;
        padding: 0 1rem;
        height: 7.5rem;
        align-items: center;
        margin: 0;
        border-bottom: 1px solid $color-grey-border-light;

        @media (min-width: $mq-m) {
            display: flex;
        }

        @media (min-width: $mq-header) {
            display: none;
        }
    }

    &__observer {
        position: absolute;
        top: 0;
        height: 5rem;
        left: 0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
    }

    &[data-status='bottom'] {
        &::after {
            opacity: 1;
        }
    }

    &[data-status='scrolled'] {

        &::after {
            opacity: 1;
        }

        // @media (max-width: $mq-m) {
        //     &[data-direction='down'] {
        //         transform: translate3d(0, -5.5rem, 0);
        //     }
        // }

        @media (min-width: $mq-m) {
            .header__logo {
                transform: scale(.95);
            }

            .header__navigation-wrapper {
                transform: translate3d(0, -1rem, 0);
            }

            &::before {
                transform: translate3d(0, -1.7rem, 0);
            }

            &::after {
                opacity: 1;
                transform: translate3d(0, -1.7rem, 0);
            }
        }
    }

    // &__wrapper {
    //     display: none;

    //     @media (min-width: $mq-m) {
    //         display: block;
    //     }
    // }
}

/**
 * Header styles with JavaScript enabled
 */
body[data-initialized="true"] .header {
    position: fixed;

    &__navigation-wrapper {
        position: absolute;
        width: 100vw;
        height: $height-100vh;
        top: 0;
        left: 100vw;
        transition:
            transform $transition-time-long $default-transition-easing,
            opacity $transition-time-long $default-transition-easing;
        visibility: hidden;

        @media (min-width: $mq-m) {
            width: 30rem;
            left: 100vw;
            top: 0;

            .menu__back {
                display: none;
            }
        }

        @media (min-width: $mq-header) {
            position: relative;
            width: auto;
            height: auto;
            left: auto;
            display: flex;
            transform: none;
            flex-grow: 0;
            opacity: 1;
            visibility: visible;
            top: 0;
            background-color: transparent;
        }
    }

    &__dropdown-target {
        display: flex;
        flex-direction: column;
        transition:
            opacity $transition-time-regular $default-transition-easing,
            max-height $transition-time-regular $default-transition-easing;
        visibility: hidden;
    }

    &__rows {
        padding: 0;
        // Add 1px to compensate for the border on the button
        margin: calc(2.5rem + 1px) 0 0;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        border-top-left-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
        transition:
            opacity $transition-time-regular $default-transition-easing,
            max-height $transition-time-regular $default-transition-easing;

        @media (min-width: $mq-header) {
            // Add 1px to compensate for the border on the button
            padding: calc(2.2rem + 1px) 0 0;
            margin: 0;
            border-radius: unset;
            border: none;
        }
    }

    &__row-link {
        display: block;
        padding: .5rem .8rem;
        text-decoration: none;
        color: $color-black;

        &:hover,
        &--active {
            font-weight: bold;
            color: $color-blue-dark;
            background-color: $color-grey-light-row;
        }
    }

    &__profile-wrapper {
        position: relative;
        z-index: 1;
        margin-right: .5rem;
        @media (min-width: $mq-header) {
            margin-right: 0;
        }
    }

    &__controls {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        left: auto;
        z-index: 5;
        @media (min-width: $mq-header) {
            display: none;
        }
    }

    &__hamburger {
        @include button;
        display: flex;
        flex-direction: column;
        padding: .5rem;
        margin-left: auto;
        position: relative;
        z-index: 6;

        &__inner {
            position: relative;
            display: block;
            width: 1.8rem;
            height: calc(1.2rem + 1px);
            transition: transform $transition-time-regular $default-transition-easing;
        }

        &__inner::before,
        &__inner::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: $color-black;
            display: block;
            transform-origin: center;
        }

        &__inner::before {
            transform-origin: top center;
        }

        &__inner::after {
            bottom: 0;
            transform-origin: bottom center;
        }

        &__line {
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: $color-black;
            display: block;
            transition: opacity $transition-time-regular $default-transition-easing;

            @media (min-width: $mq-m) {
                top: calc(50% - 2px);
            }

        }

        @media (min-width: $mq-m) {
            margin-left: 0;
            display: none;
        }
    }

    &[data-open='true'] {
        .header__download {
            display: none;
        }

        .header__navigation-wrapper {
            transform: translate3d(-100vw, 0, 0);
            opacity: 1;
            visibility: visible;

            @media (min-width: $mq-m) {
                transform: translate3d(-30rem, 0, 0);
                box-shadow: $box-shadow-8;
            }

            @media (min-width: $mq-header) {
                transform: none;
            }
        }

        .header__actions-wrapper .search-button,
        .header__profile-wrapper {
            opacity: 0;
            pointer-events: none;
        }

        .header__hamburger__inner {
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: rotate(225deg);
        }

        .header__hamburger__inner::before {
            top: 0;
            transition:
                top .1s ease-out,
                opacity .1s ease-out .12s;
            opacity: 0;
        }

        .header__hamburger__inner::after {
            bottom: calc(.5rem + 1px);
            left: 2px;
            transition:
                left .1s ease-out,
                bottom .1s ease-out,
                transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
            transform: rotate(-90deg);

            @media (min-width: $mq-m) {
                bottom: calc(.5rem + 2px);
                left: 1px;
            }
        }

    }
}

body[data-initialized="true"][data-toggle-active='true'] .header {

    &__rows {
        opacity: 1;
        max-height: 10rem;
        border: 1px solid $color-blue-dark;
        background-color: $color-white;

        @media (min-width: $mq-header) {
            border: none;
        }
    }

    &__dropdown-trigger {
        color: $color-blue-dark;
        border-color: $color-blue-dark;
        border-bottom: 1px solid $color-grey-light;
        background-color: $color-grey-light-row;

        ~ .header__dropdown-target {
            visibility: visible;
        }

        ~ .header__dropdown-target .header__loggedin {
            border-color: $color-blue-dark;
        }

        .profile use,
        .chevron use {
            fill: $color-blue-dark;
        }

        @media (min-width: $mq-header) {
            background-color: transparent;
        }
    }
}

body[data-initialized="true"] {
    .layout--home .header {
        z-index: 6;
    }

    // Body Overlay
    &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        top: 0;
        display: block;
        opacity: 0;
        background-color: $color-black;
        position: fixed;
        z-index: 7;
        pointer-events: none;
        transition:
            opacity $transition-time-long $default-transition-easing,
            transform $transition-time-long $default-transition-easing;

    }

    &[data-menu-open='true'] {
        @media (min-width: $mq-m) and (max-width: $mq-header) {

            &::before {
                opacity: .7;
                pointer-events: all;
                transform: translate3d(-30rem, 0, 0);
            }
        }
    }
}

#skip-nav {
    margin-left: 2px;
    position: absolute;
    width: 95%;
    text-align: center;
    background-color: $color-white;
}
